import { Box, Divider, Skeleton, Stack } from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { ITenantBalance } from '../../../../../core/types/Tenant'
import { BalanceDarkCard } from '../../../../settings/TenantBalanceWithdraw/BalanceDarkCard'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BalanceLightCard } from '../../../../settings/TenantBalanceWithdraw/BalanceLightCard'
import { TenantController } from '../../../../../core/controllers/TenantController'
import { IErrorResponse } from '../../../../../core/types/ErrorResponse'
import InfoTooltip from '../../../../../components/Tooltip/InfoTooltip'
import UserSettingExtractDownloadButton from '../components/UserSettingExtractDownloadButton'
import { useAdminUserContext } from '../../../../../core/hooks/useAdminUserContext'

const UserSettingExtract = () => {
  const { tenantSettings } = useAdminUserContext()

  const [data, setData] = useState<ITenantBalance | undefined>(undefined)
  const [previewWithdraw, setPreviewWithdraw] = useState(false)
  const [loading, setLoading] = useState(false)

  const oldId = useRef('')

  const handleChangePreviewWithdraw = useCallback(async () => {
    if (!previewWithdraw) {
      setLoading(true)
      try {
        if (!tenantSettings) return

        const response = await TenantController.getBalance({
          tenantId: tenantSettings.tenantId,
        })
        const responseError = response as IErrorResponse
        const responseData = response as ITenantBalance

        if (responseError.code) {
          //setError(responseError.error)
        } else {
          setData(responseData)
        }
      } finally {
        setLoading(false)
      }
    }
    setPreviewWithdraw(!previewWithdraw)
  }, [previewWithdraw, tenantSettings, setLoading, setData])

  useEffect(() => {
    if (oldId.current !== tenantSettings?.tenantId) {
      oldId.current = tenantSettings?.tenantId ?? ''
      handleChangePreviewWithdraw()
    }
  }, [])

  return (
    <Stack direction="column" spacing={0} sx={{ py: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 1 }}
      >
        <Box />
        <UserSettingExtractDownloadButton
          tenantId={tenantSettings?.tenantId ?? ''}
        />
      </Stack>
      {/*<ListItemDefault
        title=""
        widthTitlePercentage={0.01}
        variant="compact"
        direction="column"
        value={
          <>
            {!loading && (
              <Button
                fullWidth
                onClick={handleChangePreviewWithdraw}
                sx={{ textTransform: 'none' }}
              >
                {previewWithdraw ? 'Ocultar' : 'Visualizar'} saldo
              </Button>
            )}
            {previewWithdraw && (
              <Stack direction="row" spacing={1} alignItems="center">
                <BalanceDarkCard balance={data?.availableAmount ?? 0} />
                <Divider orientation="vertical" flexItem />
                <BalanceLightCard balance={data?.totalAmount ?? 0} />
              </Stack>
            )}
            {loading && (
              <Stack spacing={1} sx={{ p: 1 }}>
                <Skeleton variant="rounded" width="100%" height="85px" />
              </Stack>
            )}
          </>
        }
      />*/}
      <ListItemDefault
        title=""
        widthTitlePercentage={0.01}
        variant="compact"
        direction="column"
        value={
          <>
            {previewWithdraw && (
              <Box width="100%">
                <Stack direction="row" spacing={1} alignItems="center">
                  <BalanceDarkCard
                    balance={data?.commissionsReceived ?? 0}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box>Recebidas</Box>
                        <InfoTooltip title="Total de comissões recebidas (pagas)" />
                      </Stack>
                    }
                  />
                  <Divider orientation="vertical" flexItem />
                  <BalanceLightCard
                    balance={data?.indicatedAmount ?? 0}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box>Indicação</Box>
                        <InfoTooltip title="Total de comissões de indicação recebidas" />
                      </Stack>
                    }
                  />
                </Stack>
                <Divider />
                <Stack direction="row" spacing={1} alignItems="center">
                  <BalanceDarkCard
                    balance={data?.transferredAmount ?? 0}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box>Total sacado</Box>
                        <InfoTooltip title="Total sacado" />
                      </Stack>
                    }
                  />
                  <Divider orientation="vertical" flexItem />
                  <BalanceLightCard
                    balance={data?.availableAmount ?? 0}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box>Saldo disponível</Box>
                        <InfoTooltip title="Saldo disponível pra saque" />
                      </Stack>
                    }
                  />
                </Stack>
              </Box>
            )}
            {loading && (
              <Stack spacing={1} sx={{ p: 1 }}>
                <Skeleton variant="rounded" width="100%" height="95px" />
                <Skeleton variant="rounded" width="100%" height="95px" />
              </Stack>
            )}
          </>
        }
      />
    </Stack>
  )
}

export default UserSettingExtract

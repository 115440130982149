export enum TMemberServiceType {
  Cademi = 1,
  TheMembers = 2,
  AstronMembers = 3,
  CeletusClub = 4,
  MemberKit = 5,
}

export type TMemberService = {
  id: string
  identifier: string
  domain: string
  token: string
  apiKey: string
  webhookCode: string
  type: TMemberServiceType
  platformDefaultPassword?: string | null
}

export type TMemberServicePagination = {
  items: TMemberService[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}

export type TMemberKitClassrom = {
  id: number
  name?: string
  courseName?: string
  averageProgress: number
  commentsCount: number
  usersCount: number
  master: boolean
}

import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import Hosts, { goUrl } from '../../../../../core/functions/hosts'
import { TAffiliatedStatus } from '../../../../../core/types/Affiliated'
import {
  TPaymentMethodEnum,
  TProductLinkCategory,
  TProductPrice,
  TProductPriceInstallmentFees,
  TProductPricePayment,
} from '../../../../../core/types/Product'
import { useEffect, useState } from 'react'
import Convertions from '../../../../../core/functions/convertions'
import { IconCopy, IconLink } from '../../../../../core/constants/IconConsts'
import { productPriceInstallmentFeeDescription } from '../MyAffiliationsDetailsConsts'
import { IMyAffiliationsLinksProps } from '../MyAffiliationsDetailsTypes'
import ProductPriceCheckoutLink from './MyAffiliationsLinks/MyAffiliationsLinksCheckout'
import MyAffiliatesLinksHeader from './MyAffiliationsLinks/MyAffiliationsLinksHeader'

const MyAffiliatesLinks = ({
  affiliated,
  prices,
  links,
  setError,
  setCopied,
}: IMyAffiliationsLinksProps) => {
  const [groups, setGroups] = useState<TProductLinkCategory[]>([])
  const [installmentFees, setInstallmentFees] = useState<
    TProductPriceInstallmentFees[]
  >([])

  const activePrices = prices?.filter((price) => !price.isDisabled)

  function getPaymentCreditCard(
    price: TProductPrice
  ): TProductPricePayment | undefined {
    return price.paymentMethods.find(
      (x) => x.methodType === TPaymentMethodEnum.CreditCard
    )
  }

  function getPaymentCreditCardDiff(
    price: TProductPrice
  ): TProductPricePayment | undefined {
    const payCreditCard = getPaymentCreditCard(price)
    if (payCreditCard) return undefined
    return price.paymentMethods.find(
      (x) => x.methodType !== TPaymentMethodEnum.CreditCard
    )
  }

  const isCreditCardExists =
    activePrices?.find((p) => getPaymentCreditCard(p) !== undefined) !==
    undefined
  const isCreditCardDiffExists =
    activePrices?.find((p) => getPaymentCreditCardDiff(p) !== undefined) !==
    undefined

  function getInstallmentFeesType(
    price: TProductPrice
  ): TProductPriceInstallmentFees {
    return price.installmentFeesType
  }

  function isPriceByInstallmentFee(
    price: TProductPrice,
    installmentFee: TProductPriceInstallmentFees
  ): boolean {
    const payCreditCard = getPaymentCreditCard(price)
    const isPayCreditCard = payCreditCard !== undefined
    const isDisabled = price.isDisabled
    const isInstallmentFee =
      isPayCreditCard && price.installmentFeesType === installmentFee
    return !isDisabled && isInstallmentFee
  }

  function getPricesByInstallmentFee(
    installmentFee: TProductPriceInstallmentFees
  ) {
    const list =
      prices?.filter((p) => isPriceByInstallmentFee(p, installmentFee)) ?? []
    return list.sort((a, b) => (a.value ?? 0) - (b.value ?? 0))
  }

  function isPriceByDontCreditCard(price: TProductPrice): boolean {
    const payCreditCard = getPaymentCreditCard(price)
    const isPayCreditCard = payCreditCard !== undefined
    const isDisabled = price.isDisabled
    return !isDisabled && !isPayCreditCard
  }

  function getPricesByDontCreditCard() {
    const list = prices?.filter((p) => isPriceByDontCreditCard(p)) ?? []
    return list.sort((a, b) => (a.value ?? 0) - (b.value ?? 0))
  }

  useEffect(() => {
    const list = Array.from(new Set(links.map((link) => link.category)))
    setGroups(list)
  }, [links])

  useEffect(() => {
    if (prices) {
      const installmentFeeTypes = activePrices?.map((price) =>
        getInstallmentFeesType(price)
      )
      const list = Array.from(new Set(installmentFeeTypes))
      setInstallmentFees(list)
    }
  }, [prices])

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container columns={18}>
        {isCreditCardExists && (
          <>
            <MyAffiliatesLinksHeader
              title="Páginas do Checkout - Com cartão"
              affiliated={affiliated}
            />
            {installmentFees
              .sort((a, b) => a - b)
              .map((installmentFee) => (
                <>
                  <Grid item xs={18}>
                    <Typography
                      sx={{
                        mt: 2,
                        color: '#1C52BD',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '24px',
                      }}
                    >
                      {productPriceInstallmentFeeDescription[installmentFee]}
                    </Typography>
                  </Grid>
                  {getPricesByInstallmentFee(installmentFee).map(
                    (price, index) => (
                      <ProductPriceCheckoutLink
                        key={`p${index}c`}
                        price={price}
                        affiliated={affiliated}
                        setCopied={setCopied}
                        setError={setError}
                      />
                    )
                  )}
                </>
              ))}
          </>
        )}

        {isCreditCardDiffExists && (
          <>
            <MyAffiliatesLinksHeader
              title="Páginas do Checkout - Sem cartão"
              affiliated={affiliated}
            />
            {getPricesByDontCreditCard().map((price, index) => (
              <ProductPriceCheckoutLink
                key={`p${index}s`}
                price={price}
                affiliated={affiliated}
                setCopied={setCopied}
                setError={setError}
              />
            ))}
          </>
        )}

        {groups.map((group) => (
          <>
            <Grid
              item
              xs={18}
              sx={{ borderRadius: '4px', backgroundColor: 'divider', mt: 1 }}
            >
              <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
                {Convertions.productLinkCategoryToString(group)}
              </Typography>
            </Grid>
            {links
              ?.filter((link) => link.category === group)
              .map((link) => (
                <Stack
                  direction="row"
                  alignContent="center"
                  alignItems="center"
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid #DCDDDE',
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <Grid item xs={1}>
                    <Stack
                      height="100%"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={IconLink}
                        alt="copy"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={17}>
                    {affiliated?.status === TAffiliatedStatus.Accepted && (
                      <Stack
                        direction="row"
                        spacing={1}
                        alignContent="center"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ px: 1, py: 0.5 }}
                      >
                        <Stack direction="column">
                          <Typography variant="caption">
                            {link.identifier}
                          </Typography>
                          <Typography variant="caption" fontWeight={500} noWrap>
                            {`${goUrl}/${affiliated?.codeId}${link.codeId !== '' ? `/?ap=${link.codeId}` : ''}`}
                          </Typography>
                        </Stack>
                        <IconButton
                          size="small"
                          onClick={async () => {
                            const url = `${goUrl}/${affiliated?.codeId}${link.codeId !== '' ? `/?ap=${link.codeId}` : ''}`
                            Hosts.copyTextToClipboard(url)
                              .then(() => setCopied(true))
                              .catch(() => setError('Erro ao copiar'))
                          }}
                        >
                          <img src={IconCopy} alt="copy" />
                        </IconButton>
                      </Stack>
                    )}
                  </Grid>
                </Stack>
              ))}
          </>
        ))}
      </Grid>
      <Box sx={{ height: 80 }} />
    </Box>
  )
}

export default MyAffiliatesLinks

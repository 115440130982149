import { Alert, Box, Skeleton, Stack, Tab, Tabs } from '@mui/material'
import {
  TAffiliated,
  TAffiliatedStatus,
} from '../../../../core/types/Affiliated'
import { useCallback, useEffect, useState } from 'react'
import { TProductAffiliates } from '../../../../core/types/ProductAffiliates'
import AffiliationController from '../../../../core/controllers/AffiliationController'
import ProductController from '../../../../core/controllers/ProductController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import MyAffiliationsGeneral from './Tabs/MyAffiliationsGeneral'
import MyAffiliatesLinks from './Tabs/MyAffiliationsLinks'
import { TProductLink, TProductPrice } from '../../../../core/types/Product'
import MyAffiliationsCommissions from './Tabs/MyAffiliationsCommissions'
import MyAffiliatesPixels from './Tabs/MyAffiliationsPixels'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { a11yProps, TabPanel } from '../../../../components/Tabs'
import { IMyAffiliationsDetailsProps } from './MyAffiliationsDetailsTypes'

const MyAffiliationsDetails = ({
  affiliated,
  open,
  sending,
  handleClose,
  setOpen,
  setAffiliated,
  saveAffiliated,
  setCopied,
  setErrorSending,
  setSending,
  setSuccess,
  reload,
}: IMyAffiliationsDetailsProps) => {
  const [data, setData] = useState<TProductAffiliates | undefined>(undefined)
  const [prices, setPrices] = useState<TProductPrice[]>([])
  const [links, setLinks] = useState<TProductLink[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const setRecoverySale = async (
    affiliated: TAffiliated | undefined,
    isActivated: boolean
  ) => {
    if (affiliated) {
      setLoading(true)
      try {
        await AffiliationController.updateRecoverySale({
          affiliatedId: affiliated.id ?? '',
          isActivated,
        })
        setAffiliated({ ...affiliated, activedRecoverySale: isActivated })
        reload()
      } finally {
        setLoading(false)
      }
    }
  }

  const getData = useCallback(async (id: string) => {
    setLoading(true)
    try {
      const response = await AffiliationController.getProduct({ id })
      const respError = response as IErrorResponse
      if (respError.error) {
        setError(respError.error)
        return
      }

      const resp = response as TProductAffiliates
      setData(resp)

      if (resp.productId) {
        const resPrice = await ProductController.getPricesByProduct({
          productId: resp.productId,
        })
        const respPriceError = resPrice as IErrorResponse
        if (!respPriceError.error) {
          const dataPrice = resPrice as TProductPrice[]
          setPrices(dataPrice)
        }

        const respLink = await ProductController.getLinksByProduct({
          productId: resp.productId,
        })
        const respLinkError = respLink as IErrorResponse
        if (!respLinkError.error) {
          const dataLink = respLink as TProductLink[]
          setLinks(dataLink)
        }
      }
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (affiliated) {
      getData(affiliated.productAffiliationId)
    }
  }, [open])

  const handleSave = () => {
    saveAffiliated()
  }

  useEffect(() => {
    setValue(0)
  }, [affiliated])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleClose}
      setOpen={setOpen}
      title="Editar afiliação"
      subtitle="Edite as configurações da afiliação"
      buttons={[
        {
          title: 'Cancelar',
          onClick: handleClose,
          type: 'negative',
        },
        { title: 'Salvar', onClick: handleSave, type: 'positive' },
      ]}
      buttonsDisabled={sending}
    >
      <Stack direction="column" sx={{ width: '100%' }}>
        {loading && (
          <Stack spacing={2} sx={{ p: 2 }}>
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
            <Skeleton variant="rounded" width="100%" height="45px" />
          </Stack>
        )}

        {!loading && (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Configurações" {...a11yProps(0)} />
                <Tab label="Comissões" {...a11yProps(1)} />
                {affiliated?.status === TAffiliatedStatus.Accepted && (
                  <Tab label="Links" {...a11yProps(2)} />
                )}
                {affiliated?.status === TAffiliatedStatus.Accepted && (
                  <Tab label="Pixels" {...a11yProps(3)} />
                )}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <MyAffiliationsGeneral
                affiliated={affiliated}
                productAffiliates={data}
                setAffiliated={setAffiliated}
                setRecoverySale={setRecoverySale}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MyAffiliationsCommissions affiliated={affiliated} />
            </TabPanel>
            {affiliated?.status === TAffiliatedStatus.Accepted && (
              <TabPanel value={value} index={2}>
                <MyAffiliatesLinks
                  affiliated={affiliated}
                  prices={prices}
                  links={links}
                  setError={setError}
                  setCopied={setCopied}
                />
              </TabPanel>
            )}
            {affiliated?.status === TAffiliatedStatus.Accepted && (
              <TabPanel value={value} index={3}>
                <MyAffiliatesPixels
                  affiliated={affiliated}
                  sending={sending}
                  setErrorSending={setErrorSending}
                  setSending={setSending}
                  setSuccess={setSuccess}
                />
              </TabPanel>
            )}
          </>
        )}
        {!loading && error !== '' && (
          <Box sx={{ m: 2 }}>
            <Alert
              onClose={() => {
                setError('')
                handleClose()
              }}
              severity="error"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Box>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default MyAffiliationsDetails

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  Chip,
  Box,
  Stack,
} from '@mui/material'
import React from 'react'
import { TMemberKitClassrom } from '../../../../../core/types/MemberService'
import MemberServiceFunctions from '../../../../../core/functions/MemberServiceFunctions'

export interface ProductGeneralClassroomsProps {
  data: TMemberKitClassrom[]
  selecteds?: string
  disabled?: boolean
  onChange: (values: string) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const ProductGeneralClassrooms = ({
  data,
  selecteds,
  disabled,
  onChange,
}: ProductGeneralClassroomsProps) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event

    const values = (value as number[]).join(',')
    onChange(values)
  }

  const selectedIds = (selecteds?.split(',') ?? [])
    .map((selected) => Number(selected))
    .filter((selected) => selected !== 0)

  const selectedData = data.filter((item) => selectedIds.includes(item.id))

  const isSelected = (id: number) => selectedIds.includes(id)

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="classroom-multiple-checkbox-label">Turmas</InputLabel>
      <Select
        labelId="classroom-multiple-checkbox-label"
        id="classroom-multiple-checkbox"
        disabled={disabled}
        multiple
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedIds}
        onChange={handleChange}
        input={<OutlinedInput label="Turmas" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {MemberServiceFunctions.sortMemberKitClassrooms(selectedData).map(
              (s) => (
                <Chip
                  size="small"
                  color="primary"
                  label={`${s.name} - ${s.courseName}`}
                />
              )
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {data
          .sort((a, b) => ((a.name ?? '') > (b.name ?? '') ? 1 : -1))
          .map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={isSelected(item.id)} />
              <ListItemText primary={`${item.name} - ${item.courseName}`} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { IconCopy, IconLink } from '../../../../../../core/constants/IconConsts'
import { TAffiliatedStatus } from '../../../../../../core/types/Affiliated'
import Hosts, { payUrl } from '../../../../../../core/functions/hosts'
import NumberFunctions from '../../../../../../core/functions/NumberFunctions'
import { IMyAffiliationsLinksCheckoutProps } from '../../MyAffiliationsDetailsTypes'

const ProductPriceCheckoutLink = ({
  price,
  affiliated,
  setCopied,
  setError,
}: IMyAffiliationsLinksCheckoutProps) => {
  return (
    <Stack
      direction="row"
      alignContent="center"
      alignItems="center"
      sx={{
        borderRadius: '4px',
        border: '1px solid #DCDDDE',
        width: '100%',
        mt: 1,
      }}
    >
      <Grid item xs={1}>
        <Stack
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={IconLink}
            alt="copy"
            style={{ width: '20px', height: '20px' }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" sx={{ px: 1, py: 0.5 }}>
          <Typography
            fontWeight={400}
            fontSize="12px"
            lineHeight="16px"
            color="#1C52BD"
          >
            {price.offerName}
          </Typography>
          {affiliated?.status === TAffiliatedStatus.Accepted && (
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <Typography
                variant="caption"
                fontWeight={500}
                noWrap
              >{`${payUrl}/${price.codeId}?afid=${affiliated?.codeId}`}</Typography>
              <IconButton
                size="small"
                onClick={async () => {
                  const url: string = `${payUrl}/${price.codeId}?afid=${affiliated?.codeId}`
                  Hosts.copyTextToClipboard(url)
                    .then(() => setCopied(true))
                    .catch(() => setError('Erro ao copiar'))
                }}
              >
                <img src={IconCopy} alt="copy" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" textAlign="end" sx={{ px: 2, py: 1 }}>
          {NumberFunctions.formatMoneyDefault(price.value)}
        </Typography>
      </Grid>
    </Stack>
  )
}

export default ProductPriceCheckoutLink

import {
  Box,
  FormControlLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { TAffiliated } from '../../../../../core/types/Affiliated'
import { TProductAffiliates } from '../../../../../core/types/ProductAffiliates'
import { maskPhoneNumber } from '../../../../../masks/masks'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import InfoIcon from '@mui/icons-material/InfoRounded'
import SwitchRound from '../../../../../components/Switch/SwitchRound'

export interface IMyAffiliationsGeneralProp {
  affiliated?: TAffiliated
  productAffiliates?: TProductAffiliates
  setAffiliated: (affiliated: TAffiliated) => void
  setRecoverySale: (
    affiliated: TAffiliated | undefined,
    isActivated: boolean
  ) => Promise<void>
}

const MyAffiliationsGeneral = ({
  affiliated,
  productAffiliates,
  setAffiliated,
  setRecoverySale,
}: IMyAffiliationsGeneralProp) => {
  return (
    <Stack direction="column" spacing={1} sx={{ p: 2 }}>
      <Typography variant="subtitle2">Dados do produtor</Typography>
      <Paper
        elevation={0}
        sx={{
          borderRadius: '4px',
          border: '1px solid #DCDDDE',
          width: '100%',
        }}
      >
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Stack direction="column">
            <Typography variant="subtitle2">Produto</Typography>
            <Typography variant="body2">{affiliated?.productName}</Typography>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle2">Comissão</Typography>
            <Typography variant="body2">
              {affiliated?.commissionPercentage}%
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle2">Nome</Typography>
            <Typography variant="body2">
              {productAffiliates?.tenantName}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle2">CNPJ</Typography>
            <Typography variant="body2">
              {productAffiliates?.tenantDocument}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle2">
              Telefone de suporte para afiliados
            </Typography>
            <Typography variant="body2">
              {maskPhoneNumber(productAffiliates?.supportPhone)}
            </Typography>
          </Stack>

          <Stack direction="column">
            <Typography variant="subtitle2">
              Descrição do programa de afiliados
            </Typography>
            {productAffiliates?.description
              ?.split('\n')
              .map((paragraphy) => (
                <Typography variant="body2">{paragraphy}</Typography>
              ))}
          </Stack>
        </Stack>
      </Paper>
      {productAffiliates?.enableRecoverySale &&
        productAffiliates.enableIndividualRecoverySale && (
          <FormControlLabel
            control={
              <SwitchRound
                sx={{ m: 1 }}
                checked={affiliated?.activedRecoverySale ?? false}
                onChange={(e) => setRecoverySale(affiliated, e.target.checked)}
              />
            }
            label="Recuperar vendas"
          />
        )}
      {affiliated?.productAllowRegistratioinThanksPages && (
        <Box sx={{ pt: 2 }}>
          <Typography variant="subtitle2">Páginas de obrigado</Typography>
        </Box>
      )}
      {affiliated?.productAllowRegistratioinThanksPages && (
        <Paper
          elevation={0}
          sx={{
            borderRadius: '4px',
            border: '1px solid #DCDDDE',
            width: '100%',
          }}
        >
          <Stack direction="column" spacing={2} sx={{ p: 2 }}>
            <Box>
              <Stack
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
              >
                <Typography variant="caption" fontWeight={500}>
                  URL para Compras Aprovadas
                </Typography>
                <HtmlTooltip
                  title={
                    <Typography>
                      Se a compra for feita com cartão de crédito, por exemplo,
                      e ela já estiver aprovada, seu comprador é direcionado
                      para esta URL.
                    </Typography>
                  }
                >
                  <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                </HtmlTooltip>
              </Stack>
              <TextField
                fullWidth
                size="small"
                type="text"
                InputLabelProps={{ shrink: true }}
                value={affiliated?.approvedPageLink || ''}
                onChange={(e) =>
                  setAffiliated({
                    ...affiliated!,
                    approvedPageLink: e.target.value,
                  })
                }
              />
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
              >
                <Typography variant="caption" fontWeight={500}>
                  URL para Compras Aguardando Pagamento
                </Typography>
                <HtmlTooltip
                  title={
                    <Typography>
                      Se a compra for feita por boleto bancário, por exemplo,
                      como ela precisa da confirmação do pagamento, seu
                      comprador é direcionado para esta URL.
                    </Typography>
                  }
                >
                  <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                </HtmlTooltip>
              </Stack>
              <TextField
                fullWidth
                size="small"
                type="text"
                InputLabelProps={{ shrink: true }}
                value={affiliated?.awaitingPaymentPageLink || ''}
                onChange={(e) =>
                  setAffiliated({
                    ...affiliated!,
                    awaitingPaymentPageLink: e.target.value,
                  })
                }
              />
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
              >
                <Typography variant="caption" fontWeight={500}>
                  URL para Compras Aguardando Análise de Crédito
                </Typography>
                <HtmlTooltip
                  title={
                    <Typography>
                      Se a compra for feita por PayPal, por exemplo, como ela
                      leva até 24 horas para ser processada, seu comprador é
                      direcionado para esta URL.
                    </Typography>
                  }
                >
                  <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                </HtmlTooltip>
              </Stack>
              <TextField
                fullWidth
                size="small"
                type="text"
                InputLabelProps={{ shrink: true }}
                value={affiliated?.awaitingCreditAnalysisPageLink || ''}
                onChange={(e) =>
                  setAffiliated({
                    ...affiliated!,
                    awaitingCreditAnalysisPageLink: e.target.value,
                  })
                }
              />
            </Box>
          </Stack>
        </Paper>
      )}
      <Box sx={{ height: 70 }} />
    </Stack>
  )
}

export default MyAffiliationsGeneral

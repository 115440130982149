import {
  Box,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  TProduct,
  TProductAssurance,
  TProductDelivery,
  TProductStatus,
  TProductTypePayment,
} from '../../../core/types/Product'
import { useMemberServiceList } from '../../../core/hooks/useMemberServiceList'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import ProductController from '../../../core/controllers/ProductController'
import Validations from '../../../core/functions/validations'
import { useNavigate } from 'react-router-dom'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import Convertions from '../../../core/functions/convertions'
import { red } from '@mui/material/colors'
import InfoInputAdornment from '../../../components/Inputs/InfoInputAdornment'
import MemberClassModal from '../../Apps/MemberClass'
import { ITenant } from '../../../core/types/Tenant'
import { TenantController } from '../../../core/controllers/TenantController'
import { TMemberServiceType } from '../../../core/types/MemberService'
import { ProductGeneralClassrooms } from '../Product/Tabs/ProductGeneral/ProductGeneralClassrooms'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import { useMemberServiceClassroms } from '../../../core/hooks/useMemberServiceClassroms'

interface INewProductProps {
  open: boolean
  typePayment: TProductTypePayment
  delivery: TProductDelivery
  setOpen: (value: boolean) => void
}

const NewProduct = ({
  open,
  typePayment,
  delivery,
  setOpen,
}: INewProductProps) => {
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState('')
  const [selectedOption, setSelectedOption] =
    React.useState<string>('integration')
  const [currentUser, setCurrentUser] = useState<ITenant | null>(null)
  const [openMemberClass, setOpenMemberClass] = useState(false)

  const navigate = useNavigate()

  const saveProductId = React.useRef('')

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setFocus,
    setError: setErrorForm,
  } = useForm<TProduct>({
    defaultValues: {
      id: undefined,
      name: '',
      description: '',
      memberServiceId: '',
      approvedPageLink: '',
      awaitingPaymentPageLink: '',
      awaitingCreditAnalysisPageLink: '',
      imageCover: undefined,
      imageProduct: undefined,
      enableAffiliateProgram: false,
      prices: [],
      coproducers: [],
      affiliate: null,
      typePayment: typePayment,
      status: TProductStatus.Active,
      memberServiceSettingId: '',
      assurance: TProductAssurance.Seven,
      delivery: delivery,
      productSaleLink: '',
    },
  })

  const descriptionValue = watch('description')
  const accessPassword = watch('accessPassword')
  const deliveryValue = watch('delivery')
  const typePaymentValue = watch('typePayment')
  const memberServiceSettingId = watch('memberServiceSettingId')

  const { data: memberServices, execute: exceuteMemberService } =
    useMemberServiceList()
  const { data: classrooms } = useMemberServiceClassroms({
    id: memberServiceSettingId,
  })

  const currentMemberService = memberServices.find(
    (ms) => ms.id === memberServiceSettingId
  )
  const memberServiceItems = memberServices.filter(
    (ms) => ms.type !== TMemberServiceType.CeletusClub
  )

  const getCurrentUser = async () => {
    try {
      const response = await TenantController.getByCurrentUser()
      setCurrentUser(response as ITenant)
    } catch (error) {
      console.error('Error fetching currentUSer data:', error)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  const handleChangeSelectedOption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    if (value === 'club' && currentUser?.enableMemberClass === false) {
      setOpenMemberClass(true)
    } else if (
      value === 'external' ||
      value === 'integration' ||
      value === 'club'
    ) {
      setSelectedOption(value)
    }
  }

  const validateGeneral = (data: TProduct) => {
    let errorsValidation = false

    if (!data.productSaleLink || data.productSaleLink === '') {
      setErrorForm('productSaleLink', {
        message: 'Campo obrigatório',
        type: 'required',
      })
      setFocus('productSaleLink')
      errorsValidation = true
    } else if (!Validations.URL(data.productSaleLink)) {
      setErrorForm('productSaleLink', {
        message: 'URL inválida',
        type: 'invalidURL',
      })
      setFocus('productSaleLink')
      errorsValidation = true
    }

    if (delivery === TProductDelivery.Course) {
      if (selectedOption === 'external') {
        if (!data.externalLink || data.externalLink === '') {
          setErrorForm('externalLink', {
            message: 'Campo obrigatório',
            type: 'required',
          })
          setFocus('externalLink')
          errorsValidation = true
        } else if (!Validations.URL(data.externalLink)) {
          setErrorForm('externalLink', {
            message: 'URL inválida',
            type: 'invalidURL',
          })
          setFocus('externalLink')
          errorsValidation = true
        }
        if (!data.accessUser || data.accessUser === '') {
          setErrorForm('accessUser', {
            message: 'Campo obrigatório',
            type: 'required',
          })
          setFocus('accessUser')
          errorsValidation = true
        } else if (!Validations.email(data.accessUser)) {
          setErrorForm('accessUser', {
            message: 'E-mail inválido',
            type: 'invalidEmail',
          })
          setFocus('accessUser')
          errorsValidation = true
        }
        if (!data.accessPassword || data.accessPassword === '') {
          setErrorForm('accessPassword', {
            message: 'Campo obrigatório',
            type: 'required',
          })
          setFocus('accessPassword')
          errorsValidation = true
        }
      }
      if (selectedOption === 'integration') {
        if (
          !data.memberServiceSettingId ||
          data.memberServiceSettingId === ''
        ) {
          setErrorForm('memberServiceSettingId', {
            message: 'Campo obrigatório',
            type: 'required',
          })
          setFocus('memberServiceSettingId')
          errorsValidation = true
        }

        const memberService = memberServices.find(
          (ms) => ms.id === data.memberServiceSettingId
        )
        if (memberService) {
          if (memberService.type === TMemberServiceType.MemberKit) {
            if ((data.classroom ?? '') === '') {
              setError('É necessário informar a turma do MemberKit')
              errorsValidation = true
            }
          }
        }
      }
    }

    if (delivery === TProductDelivery.eBook) {
      if (!data.deliveryUrl || data.deliveryUrl === '') {
        setErrorForm('deliveryUrl', {
          message: 'Campo obrigatório',
          type: 'required',
        })
        setFocus('deliveryUrl')
        errorsValidation = true
      } else if (!Validations.URL(data.deliveryUrl)) {
        setErrorForm('deliveryUrl', {
          message: 'URL inválida',
          type: 'invalidURL',
        })
        setFocus('deliveryUrl')
        errorsValidation = true
      }
    }

    if (!data.assurance) {
      setErrorForm('assurance', {
        message: 'Campo obrigatório',
        type: 'required',
      })
      setFocus('assurance')
      errorsValidation = true
    }

    if (data.description === '') {
      setErrorForm('description', {
        message: 'Campo obrigatório',
        type: 'required',
      })
      setFocus('description')
      errorsValidation = true
    }

    if (data.name === '') {
      setErrorForm('name', { message: 'Campo obrigatório', type: 'required' })
      setFocus('name')
      errorsValidation = true
    }

    if (errorsValidation) {
      setError('Existem erros que precisam ser corrigidos para continuar')
    }

    return !errorsValidation
  }

  const Submit = async (data: TProduct) => {
    if (!validateGeneral(data)) {
      return
    }

    if (selectedOption === 'club') {
      const MemberClassId = memberServices.find(
        (element) => element.identifier === 'Celetus Club'
      )

      data.memberServiceSettingId = MemberClassId?.id
    }

    setSending(true)
    setSuccess(false)
    setError('')
    try {
      const response = await ProductController.insertOrUpdate({
        data,
        deletedIds: [],
      })
      if (!response.success) {
        setError(response.error)
      } else {
        saveProductId.current = response.data
        setSuccess(true)
      }
    } finally {
      setSending(false)
    }
  }

  const getBreadcrumbs = () => {
    const deliveryText = {
      [TProductDelivery.Course]: 'Curso online',
      [TProductDelivery.eBook]: 'Ebook',
    }
    const typePaymentText = {
      [TProductTypePayment.OneTimePayment]: 'único',
      [TProductTypePayment.RecurringSubscription]: 'recorrente',
    }
    return `${deliveryText[deliveryValue]} / Pagamento ${typePaymentText[typePaymentValue ?? TProductTypePayment.OneTimePayment]}`
  }

  useEffect(() => {
    setValue('typePayment', typePayment)
  }, [typePayment])

  useEffect(() => {
    setValue('delivery', delivery)
  }, [delivery])

  const handleAcceptMemberClass = async () => {
    await exceuteMemberService()
    await getCurrentUser()
  }

  return (
    <>
      <SwipeableDrawerRightDialog
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        title="Novo produto"
        subtitle="Defina as principais características do produto"
        buttons={[
          {
            title: 'Cancelar',
            onClick: () => setOpen(false),
            type: 'negative',
          },
          { title: 'Salvar', event: 'submit', type: 'positive' },
        ]}
        buttonsDisabled={sending}
        component="form"
        noValidate
        onSubmit={handleSubmit(Submit)}
      >
        <Stack direction={'column'} spacing={2} sx={{ width: '100%' }}>
          <Typography
            fontSize="14px"
            lineHeight="18px"
            fontWeight={400}
            color="#707275"
          >
            {getBreadcrumbs()}
          </Typography>
          <Stack direction="column">
            <TextField
              fullWidth
              size="small"
              type="text"
              label="Nome do produto"
              {...register('name', { required: true })}
              InputLabelProps={{
                shrink: (watch('name') || '') !== '',
              }}
              error={errors.name?.type === 'required'}
            />
            <Typography
              variant="caption"
              color={errors.name?.type === 'required' ? red[700] : undefined}
            >
              Esse nome irá aparecer em todos os locais da plataforma
            </Typography>
            {errors.name?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Nome é um campo obrigatório
              </Typography>
            )}
          </Stack>

          <Stack direction="column">
            <TextField
              fullWidth
              multiline
              minRows={4}
              maxRows={8}
              size="small"
              type="text"
              label="Descrição"
              {...register('description', { required: true })}
              InputLabelProps={{
                shrink: (watch('description') || '') !== '',
              }}
              inputProps={{
                maxLenght: 500,
              }}
              error={errors.description?.type === 'required'}
            />
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography
                variant="caption"
                color={
                  errors.description?.type === 'required' ? red[700] : undefined
                }
              >
                Descrição que aparece para seus compradores
              </Typography>
              <Typography
                variant="caption"
                color={
                  errors.description?.type === 'required' ? red[700] : undefined
                }
              >
                {descriptionValue.length}/500
              </Typography>
            </Stack>
            {errors.description?.type === 'required' && (
              <Typography variant="caption" color={red[700]}>
                Descrição é um campo obrigatório
              </Typography>
            )}
          </Stack>

          <FormControl
            fullWidth
            size="small"
            error={errors.assurance?.type === 'required'}
          >
            <InputLabel id="assurance-select-label">Garantia</InputLabel>
            <Select
              variant="outlined"
              labelId="assurance-select-label"
              label="Garantia"
              value={
                watch('assurance')
                  ? Number(watch('assurance'))
                  : TProductAssurance.Seven
              }
              {...register('assurance')}
              renderValue={(value?: number) =>
                Convertions.productAssuranceToString(value)
              }
            >
              <MenuItem value={Number(TProductAssurance.Seven)}>
                {Convertions.productAssuranceToString(TProductAssurance.Seven)}
              </MenuItem>
              <MenuItem value={Number(TProductAssurance.Fifteen)}>
                {Convertions.productAssuranceToString(
                  TProductAssurance.Fifteen
                )}
              </MenuItem>
              <MenuItem value={Number(TProductAssurance.TwentyOne)}>
                {Convertions.productAssuranceToString(
                  TProductAssurance.TwentyOne
                )}
              </MenuItem>
              <MenuItem value={Number(TProductAssurance.Thirty)}>
                {Convertions.productAssuranceToString(TProductAssurance.Thirty)}
              </MenuItem>
            </Select>
          </FormControl>

          {delivery === TProductDelivery.Course && (
            <Stack
              sx={{
                backgroundColor: '#FFFF',
                gap: '32px',
                borderRadius: '8px',
              }}
            >
              <Stack spacing={0.2} sx={{ gap: '4px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#38393B',
                  }}
                >
                  Área de Membros
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '18px',
                    color: '#707275',
                  }}
                >
                  Informe uma área de membros válida onde seus alunos receberão
                  o acesso e o nosso time possa validar a entrega.
                </Typography>

                <Stack spacing={2}>
                  <Stack width="100%" spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column' }}
                      alignItems={'left'}
                      justifyContent={'left'}
                    >
                      <RadioGroup
                        row
                        sx={{ m: 0 }}
                        value={selectedOption}
                        onChange={handleChangeSelectedOption}
                      >
                        <FormControlLabel
                          value="integration"
                          control={<Radio />}
                          label="API pré-cadastrada"
                        />
                        <FormControlLabel
                          value="external"
                          control={<Radio />}
                          label="Validação Manual"
                        />
                        <FormControlLabel
                          value="club"
                          control={<Radio />}
                          label="Celetus Club"
                        />
                      </RadioGroup>
                    </Box>

                    <Collapse in={selectedOption === 'integration'}>
                      <Stack direction={'column'} spacing={2}>
                        <Typography
                          fontFamily="Montserrat"
                          fontSize="14px"
                          fontWeight={400}
                          lineHeight="18px"
                          color="#707275"
                        >
                          Selecione uma área de membros que já foi
                          pré-cadastrada via API na área de aplicativos da
                          Celetus.
                        </Typography>
                        <FormControl
                          fullWidth
                          size="small"
                          error={
                            errors.memberServiceSettingId?.type === 'required'
                          }
                        >
                          <InputLabel id="member-select-label">
                            Área de Membros
                          </InputLabel>
                          <Select
                            required
                            variant="outlined"
                            labelId="member-select-label"
                            label="Área de Membros"
                            value={watch('memberServiceSettingId') ?? ''}
                            {...register('memberServiceSettingId')}
                            renderValue={(value?: string) => {
                              const ms = currentMemberService
                              const identifier = ms?.identifier
                              const type =
                                Convertions.memberServiceTypeToString(ms?.type)
                              return `${identifier} - ${type}`
                            }}
                          >
                            {memberServiceItems.map((ms) => (
                              <MenuItem key={ms.id} value={ms.id}>
                                {ms.identifier} -{' '}
                                {Convertions.memberServiceTypeToString(ms.type)}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.memberServiceSettingId?.type ===
                            'required' && (
                            <Typography variant="overline" color={'red'}>
                              Campo obrigatório
                            </Typography>
                          )}
                        </FormControl>
                        <Fade
                          in={
                            currentMemberService?.type ===
                            TMemberServiceType.MemberKit
                          }
                          mountOnEnter
                          unmountOnExit
                        >
                          <Stack
                            minHeight={40}
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <ProductGeneralClassrooms
                              data={classrooms}
                              disabled={false}
                              selecteds={watch('classroom') || ''}
                              onChange={(values) =>
                                setValue('classroom', values)
                              }
                            />
                            <HtmlTooltip
                              title={
                                <Box>
                                  <Box>
                                    Selecione as turmas já pré-cadastradas na
                                    plataforma MemberKit para garantir a correta
                                    entrega do curso ao aluno.
                                  </Box>
                                  <Box>
                                    Se alguma turma não estiver aparecendo, o
                                    cadastro pode estar incorreto. Nesse caso,
                                    verifique as informações e tente novamente.
                                  </Box>
                                </Box>
                              }
                            >
                              <img
                                src="/assets/icons/infos/info.svg"
                                alt="info-classroom"
                                style={{
                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            </HtmlTooltip>
                          </Stack>
                        </Fade>
                      </Stack>
                    </Collapse>

                    <Collapse in={selectedOption === 'external'}>
                      <Stack direction={'column'} spacing={2}>
                        <Typography
                          fontFamily="Montserrat"
                          fontSize="14px"
                          fontWeight={400}
                          lineHeight="18px"
                          color="#707275"
                        >
                          Forneça ao nosso time de validação, credenciais, para
                          avaliar a entrega do produto.
                        </Typography>
                        <Box>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            label="Link da área de membros"
                            placeholder="Digite a URL"
                            {...register('externalLink', {
                              required: selectedOption === 'external',
                            })}
                            InputProps={{
                              endAdornment: (
                                <InfoInputAdornment title="Digite o link para a área de membros" />
                              ),
                            }}
                            InputLabelProps={{
                              shrink:
                                (watch('externalLink') || '') !== ''
                                  ? true
                                  : undefined,
                            }}
                            error={
                              errors.externalLink?.type === 'required' ||
                              errors.externalLink?.type === 'invalidURL'
                            }
                          />
                          {errors.externalLink && (
                            <Typography variant="overline" color={'red'}>
                              {errors.externalLink.type === 'required'
                                ? 'Campo obrigatório'
                                : ''}
                              {errors.externalLink.type === 'invalidURL'
                                ? 'URL inválida'
                                : ''}
                            </Typography>
                          )}
                        </Box>

                        <Box>
                          <TextField
                            fullWidth
                            size="small"
                            type="email"
                            label=" E-mail "
                            placeholder="Digite o e-mail"
                            {...register('accessUser', {
                              required: selectedOption === 'external',
                            })}
                            InputProps={{
                              endAdornment: (
                                <InfoInputAdornment title="Digite o email para acessar o link da área de membros" />
                              ),
                            }}
                            InputLabelProps={{
                              shrink:
                                (watch('accessUser') || '') !== ''
                                  ? true
                                  : undefined,
                            }}
                            error={
                              errors.accessUser?.type === 'required' ||
                              errors.accessUser?.type === 'invalidEmail'
                            }
                          />
                          {errors.accessUser && (
                            <Typography variant="overline" color={'red'}>
                              {errors.accessUser.type === 'required'
                                ? 'Campo obrigatório'
                                : ''}
                              {errors.accessUser.type === 'invalidEmail'
                                ? 'E-mail inválido'
                                : ''}
                            </Typography>
                          )}
                        </Box>

                        <Box>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            label="Senha de acesso"
                            placeholder="Digite a senha"
                            {...register('accessPassword', {
                              required: selectedOption === 'external',
                            })}
                            InputProps={{
                              endAdornment: (
                                <InfoInputAdornment title="Digite a senha para acessar o link da área de membros" />
                              ),
                            }}
                            InputLabelProps={{
                              shrink:
                                (accessPassword || '') !== ''
                                  ? true
                                  : undefined,
                            }}
                            error={errors.accessPassword?.type === 'required'}
                          />
                          {errors.accessPassword && (
                            <Typography variant="overline" color={'red'}>
                              {errors.accessPassword.type === 'required'
                                ? 'Campo obrigatório'
                                : ''}
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    </Collapse>

                    <Collapse in={selectedOption === 'club'}>
                      <MemberClassModal
                        open={openMemberClass}
                        setOpen={setOpenMemberClass}
                        setSelectedOption={setSelectedOption}
                        onAcceptMemberClass={handleAcceptMemberClass}
                      />
                    </Collapse>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}

          {delivery === TProductDelivery.eBook && (
            <Stack direction="column">
              <TextField
                fullWidth
                size="small"
                type="text"
                label="URL do Ebook"
                {...register('deliveryUrl', { required: true })}
                InputLabelProps={{
                  shrink: (watch('deliveryUrl') || '') !== '',
                }}
                error={
                  errors.deliveryUrl?.type === 'required' ||
                  errors.deliveryUrl?.type === 'invalidURL'
                }
                InputProps={{
                  endAdornment: (
                    <InfoInputAdornment title="Link enviado por e-mail de compra aprovada para o aluno poder realizar o download do PDF." />
                  ),
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '400px',
                  color: '#707070',
                  mt: '5px',
                }}
              >
                O PDF não pode possuir senha
              </Typography>
              {errors.deliveryUrl && (
                <Typography variant="caption" color={red[700]}>
                  {errors.deliveryUrl.type === 'required'
                    ? 'URL do Ebook é um campo obrigatório'
                    : ''}
                  {errors.deliveryUrl.type === 'invalidURL'
                    ? 'URL inválida'
                    : ''}
                </Typography>
              )}
            </Stack>
          )}

          <Stack direction="column">
            <TextField
              fullWidth
              size="small"
              type="text"
              label="Página de venda"
              {...register('productSaleLink', { required: true })}
              InputLabelProps={{
                shrink: (watch('productSaleLink') || '') !== '',
              }}
              error={
                errors.productSaleLink?.type === 'required' ||
                errors.productSaleLink?.type === 'invalidURL'
              }
            />
            <Typography
              variant="caption"
              color={
                errors.productSaleLink?.type === 'required'
                  ? red[700]
                  : undefined
              }
            >
              Informe a URL de vendas do seu produto.
            </Typography>
            {errors.productSaleLink && (
              <Typography variant="overline" color={'red'}>
                {errors.productSaleLink.type === 'required'
                  ? 'URL de Página de venda é um campo obrigatório'
                  : ''}
                {errors.productSaleLink.type === 'invalidURL'
                  ? 'URL inválida'
                  : ''}
              </Typography>
            )}
          </Stack>
        </Stack>
      </SwipeableDrawerRightDialog>

      <LoadingBackdrop open={sending} />

      <SuccessSnackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => {
          setSuccess(false)
          navigate(`/product/${saveProductId.current}`)
        }}
      >
        Inserido com sucesso
      </SuccessSnackbar>

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>
    </>
  )
}

export default NewProduct

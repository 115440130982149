import {
  Box,
  Collapse,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SwitchRound from '../../../../../components/Switch/SwitchRound'
import InfoInputAdornment from '../../../../../components/Inputs/InfoInputAdornment'
import { ProductGeneralPageLinksProps } from '../../../ProductTypes'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'

const ProductGeneralPageLinks = ({
  control,
  thankyouPages,
  watch,
  setValue,
  setThankyouPages,
}: ProductGeneralPageLinksProps) => {
  const { isUserSysAdmin } = useContext(AuthContext)
  const { register } = control

  const approvedPageLink = watch('approvedPageLink')
  const awaitingPaymentPageLink = watch('awaitingPaymentPageLink')
  const awaitingCreditAnalysisPageLink = watch('awaitingCreditAnalysisPageLink')

  const isAdmin = isUserSysAdmin()

  useEffect(() => {
    const approved = approvedPageLink?.trim() ?? ''
    const awaiting = awaitingPaymentPageLink?.trim() ?? ''
    const credit = awaitingCreditAnalysisPageLink?.trim() ?? ''
    const test = `${approved}${awaiting}${credit}`

    if (test !== '') {
      setThankyouPages(true)
    }
  }, [
    approvedPageLink,
    awaitingPaymentPageLink,
    awaitingCreditAnalysisPageLink,
  ])

  return (
    <Stack
      sx={{
        backgroundColor: '#FFFF',
        flexDirection: 'column',
        alignContent: 'center',
        gap: '32px',
        padding: '32px',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          gap: '4px',
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              color: '#343948',
            }}
          >
            Página de obrigado
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '18px',
              color: '#707275',
            }}
          >
            Explicação o que é um página de obrigado
          </Typography>
        </Stack>
        <Stack>
          <SwitchRound
            sx={{ m: 0 }}
            checked={thankyouPages}
            disabled={isAdmin}
            onChange={(e) => {
              setThankyouPages(e.target.checked)
              if (!e.target.checked) {
                setValue('approvedPageLink', '')
                setValue('awaitingPaymentPageLink', '')
                setValue('awaitingCreditAnalysisPageLink', '')
              }
            }}
          />
        </Stack>
      </Box>

      <Stack>
        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}
          alignItems={'left'}
          justifyContent={'left'}
        >
          {thankyouPages && <Divider />}
        </Box>
        <Collapse in={thankyouPages}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ pt: 2 }}
          >
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              type="text"
              label="URL Compras Aprovadas"
              placeholder="Digite a URL"
              {...register('approvedPageLink')}
              InputProps={{
                endAdornment: (
                  <InfoInputAdornment title="Se a compra for feita com cartão de crédito, por exemplo, e ela já estiver aprovada, seu comprador é direcionado para esta URL." />
                ),
              }}
              InputLabelProps={{
                shrink: (approvedPageLink || '') !== '' ? true : undefined,
              }}
            />
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              type="text"
              label="URL Pagamento"
              placeholder="Digite a URL"
              {...register('awaitingPaymentPageLink')}
              InputProps={{
                endAdornment: (
                  <InfoInputAdornment title="Se a compra for feita por boleto bancário, por exemplo, como ela precisa da confirmação do pagamento, seu comprador é direcionado para esta URL." />
                ),
              }}
              InputLabelProps={{
                shrink:
                  (awaitingPaymentPageLink || '') !== '' ? true : undefined,
              }}
            />
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              type="text"
              label="URL Análise de Crédito"
              placeholder="Digite a URL"
              {...register('awaitingCreditAnalysisPageLink')}
              InputProps={{
                endAdornment: (
                  <InfoInputAdornment title="Se a compra for feita por PayPal, por exemplo, como ela leva até 24 horas para ser processada, seu comprador é direcionado para esta URL." />
                ),
              }}
              InputLabelProps={{
                shrink:
                  (awaitingCreditAnalysisPageLink || '') !== ''
                    ? true
                    : undefined,
              }}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  )
}

export default ProductGeneralPageLinks

import React, { useRef } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import { TMemberKitClassrom } from '../types/MemberService'
import MemberServiceController from '../controllers/MemberServiceController'

export const useMemberServiceClassroms = ({ id }: { id?: string | null }) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TMemberKitClassrom[]>([])
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = async () => {
    if (!loading && !executing.current) {
      executing.current = true
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        if (!id) {
          setData([])
          return
        }

        const response = await MemberServiceController.getClassrooms({ id })
        const responseError = response as IErrorResponse
        const responseData = response as TMemberKitClassrom[]

        if (responseError.code) {
          setError(responseError.error)
          setData([])
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
        executing.current = false
      }
    }
  }

  React.useEffect(() => {
    execute()
  }, [id])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}

import { Box, Button, Stack, Typography } from '@mui/material'
import { TMemberService } from '../../../../core/types/MemberService'
import AccordionDefault from '../../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../../components/Accordions/AccordionSummaryDefault'
import AccordionDetailsDefault from '../../../../components/Accordions/AccordionDetailsDefault'

interface CademiMobileItemProps {
  item: TMemberService
  handleChangeItem: (value: TMemberService) => void
}

const CademiMobileItem = ({
  item,
  handleChangeItem,
}: CademiMobileItemProps) => {
  return (
    <>
      <Box width="100%" sx={{ mb: 1, px: 2 }}>
        <AccordionDefault>
          <AccordionSummaryDefault>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      fontFamily="Montserrat"
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="rgba(56, 57, 59, 1)"
                    >
                      {item.identifier}
                    </Typography>
                    <Typography
                      fontFamily="Montserrat"
                      fontWeight={500}
                      fontSize="12px"
                      lineHeight="16px"
                      color="rgba(99, 126, 239, 1)"
                    >
                      {item.domain}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </AccordionSummaryDefault>

          <AccordionDetailsDefault>
            <Box>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ mt: 1 }}
              >
                <Button
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleChangeItem(item)
                  }}
                  startIcon={
                    <img
                      src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                      alt="icon-eyes"
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  sx={{
                    backgroundColor: 'rgba(56, 57, 59, 1)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(56, 57, 59, 0.9)',
                    },
                    width: '120px',
                    height: '24px',

                    borderRadius: '4px',
                    padding: '2px 8px 2px 4px',
                    gap: '8px',
                  }}
                >
                  <Typography
                    sx={{
                      width: '61px',
                      height: '16px',
                      fontFamily: 'Montserrat',
                      fontWeight: 700,
                      fontSize: '12px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Visualizar
                  </Typography>
                </Button>
              </Stack>
            </Box>
          </AccordionDetailsDefault>
        </AccordionDefault>
      </Box>
    </>
  )
}

export default CademiMobileItem

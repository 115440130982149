import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  TProductDelivery,
  TProductStatus,
} from '../../../../core/types/Product'
import { useContext, useState } from 'react'
import Convertions from '../../../../core/functions/convertions'
import CopiedSnackbar from '../../../../components/Snackbar/CopiedSnackbar'
import { useResponsive } from '../../../../core/hooks/useResponsive'
import SimpleCard from '../../../../components/Cards/SimpleCard'
import { red } from '@mui/material/colors'
import InfoInputAdornment from '../../../../components/Inputs/InfoInputAdornment'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import { AuthContext } from '../../../../core/context/AuthContext'
import ProductGeneralMember from './ProductGeneral/ProductGeneralMember'
import { ProductGeneralProps } from '../../ProductTypes'
import ProductGeneralPageLinks from './ProductGeneral/ProductGeneralPageLinks'
import ProductGeneralImage from './ProductGeneral/ProductGeneralImage'
import ProductGeneralAssurance from './ProductGeneral/ProductGeneralAssurance'

const ProductGeneral = ({
  control,
  errors,
  memberServices,
  buttonSave,
  watch,
  setValue,
  setError,
  selectedOption,
  onChangeSelectedOption,
  urlClub,
}: ProductGeneralProps) => {
  const [copied, setCopied] = useState(false)
  const [thankyouPages, setThankyouPages] = useState(false)
  const { isUserSysAdmin } = useContext(AuthContext)
  const { register } = control

  const isSmall = useResponsive('down', 'sm')

  const accessPassword = watch('accessPassword')
  const deliveryValue = watch('delivery')

  const isAdmin = isUserSysAdmin()

  return (
    <Stack direction={'column'} spacing={3} sx={{ gap: '24px' }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          backgroundColor: '#FFFF',
          flexDirection: 'row',
          gap: '32px',
          padding: '32px',
          borderRadius: '8px',
        }}
      >
        <Stack spacing={2}>
          <ProductGeneralImage
            imageProductUrl={watch('imageProductUrl')}
            isSmall={isSmall}
            setValue={setValue}
            setError={setError}
          />
        </Stack>

        <Stack gap="32px" spacing={2}>
          <Stack spacing={0.2} sx={{ gap: '4px' }}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '20px',
                color: '#343948',
              }}
            >
              Informações básicas do produto
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '18px',
                color: '#707275',
              }}
            >
              Você pode cadastrar o produto e já começar a vender. A imagem do
              produto será exibida na área de membros e no seu programa de
              afiliados.
            </Typography>
          </Stack>
          <Stack spacing={3}>
            <Box>
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Nome"
                {...register('name', { required: true })}
                InputLabelProps={{
                  shrink: (watch('name') || '') !== '',
                }}
                error={errors.name?.type === 'required'}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '400px',
                  color: '#707070',
                  mt: '5px',
                }}
              >
                Esse nome irá aparecer em todos os locais da plataforma
              </Typography>
              {errors.name?.type === 'required' && (
                <Typography variant="overline" color={'red'}>
                  Nome é um campo obrigatório
                </Typography>
              )}
            </Box>

            <Box>
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                multiline
                rows={2}
                type="text"
                label="Descrição"
                {...register('description', { required: true })}
                InputLabelProps={{
                  shrink: (watch('description') || '') !== '',
                }}
                error={errors.description?.type === 'required'}
              />
              {errors.description?.type === 'required' && (
                <Typography variant="overline" color={'red'}>
                  Descrição é um campo obrigatório
                </Typography>
              )}
            </Box>

            <Box width="100%">
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Identificação na fatura"
                {...register('statementDescription')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      sx={{
                        backgroundColor: (theme) => theme.palette.divider,
                        padding: '20px 14px',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      }}
                      position="start"
                    >
                      <Typography
                        fontSize="14px"
                        lineHeight="18px"
                        fontWeight={500}
                        sx={{ color: '#343948' }}
                      >
                        CLTUS
                      </Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InfoInputAdornment title="Identificador do produto que aparecerá na fatura do cartão de crédito." />
                  ),
                }}
                inputProps={{ maxLength: 8 }}
                InputLabelProps={{
                  shrink: (watch('statementDescription') || '') !== '',
                  sx: { ml: 9 },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    paddingLeft: 0,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    px: 10,
                  },
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ px: 1 }}
              >
                <Typography variant="caption" sx={{ color: '#707275' }}>
                  {(watch('statementDescription') ?? '').length}/8
                </Typography>
              </Stack>
            </Box>

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <ProductGeneralAssurance
                control={control}
                errors={errors}
                watch={watch}
              />

              <Stack width="100%" direction="row" sx={{ pr: 1.5 }}>
                <FormControl
                  fullWidth
                  size="small"
                  error={errors.status?.type === 'required'}
                >
                  <InputLabel id="status-select-label">
                    Status da venda
                  </InputLabel>

                  <Select
                    disabled={isAdmin}
                    variant="outlined"
                    labelId="status-select-label"
                    label="Status da venda"
                    value={Number(watch('status'))}
                    {...register('status', { required: true })}
                  >
                    <MenuItem value={Number(TProductStatus.Active)}>
                      {Convertions.productStatusToString(TProductStatus.Active)}
                    </MenuItem>
                    <MenuItem value={Number(TProductStatus.Inactive)}>
                      {Convertions.productStatusToString(
                        TProductStatus.Inactive
                      )}
                    </MenuItem>
                  </Select>
                  {errors.status?.type === 'required' && (
                    <Typography variant="overline" color={'red'}>
                      Campo obrigatório
                    </Typography>
                  )}
                </FormControl>
                <Box sx={{ alignContent: 'center' }}>
                  <HtmlTooltip title="Caso o status esteja como inativo todas os checkouts serão desativados e as vendas interrompidas">
                    <img
                      src="/assets/icons/infos/info.svg"
                      alt="tooltip-info-pixel"
                      style={{ marginLeft: 5, width: '25px', height: '25px' }}
                    />
                  </HtmlTooltip>
                </Box>
              </Stack>
            </Stack>

            <Box>
              <TextField
                fullWidth
                disabled={isAdmin}
                size="small"
                type="text"
                label="Página de Venda"
                placeholder="Digite a URL da Página de venda"
                {...register('productSaleLink', {
                  required: true,
                })}
                InputProps={{
                  endAdornment: (
                    <InfoInputAdornment title="Digite a URL da página de venda" />
                  ),
                }}
                InputLabelProps={{
                  shrink:
                    (watch('productSaleLink') || '') !== '' ? true : undefined,
                }}
                error={
                  errors.productSaleLink?.type === 'required' ||
                  errors.productSaleLink?.type === 'invalidURL'
                }
              />
              {errors.productSaleLink && (
                <Typography variant="overline" color={'red'}>
                  {errors.productSaleLink.type === 'required'
                    ? 'Campo obrigatório'
                    : ''}
                  {errors.productSaleLink.type === 'invalidURL'
                    ? 'URL inválida'
                    : ''}
                </Typography>
              )}
            </Box>

            {deliveryValue === TProductDelivery.eBook && (
              <Stack direction="column">
                <TextField
                  disabled={isAdmin}
                  fullWidth
                  size="small"
                  type="text"
                  label="URL do Ebook"
                  {...register('deliveryUrl', { required: true })}
                  InputLabelProps={{
                    shrink: (watch('deliveryUrl') || '') !== '',
                  }}
                  error={
                    errors.deliveryUrl?.type === 'required' ||
                    errors.deliveryUrl?.type === 'invalidURL'
                  }
                  InputProps={{
                    endAdornment: (
                      <InfoInputAdornment title="Link enviado por e-mail de compra aprovada para o aluno poder realizar o download do PDF." />
                    ),
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: '400px',
                    color: '#707070',
                    mt: '5px',
                  }}
                >
                  O PDF não pode possuir senha
                </Typography>
                {errors.deliveryUrl && (
                  <Typography variant="caption" color={red[700]}>
                    {errors.deliveryUrl.type === 'required'
                      ? 'URL do Ebook é um campo obrigatório'
                      : ''}
                    {errors.deliveryUrl.type === 'invalidURL'
                      ? 'URL inválida'
                      : ''}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <ProductGeneralMember
        accessPassword={accessPassword}
        control={control}
        errors={errors}
        memberServices={memberServices}
        selectedOption={selectedOption}
        urlClub={urlClub}
        watch={watch}
        setValue={setValue}
        onChangeSelectedOption={onChangeSelectedOption}
      />

      <ProductGeneralPageLinks
        control={control}
        thankyouPages={thankyouPages}
        watch={watch}
        setValue={setValue}
        setThankyouPages={setThankyouPages}
      />

      <SimpleCard sx={{ p: '32px' }}>
        <Stack sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              color: '#343948',
            }}
          >
            Suporte
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '18px',
              color: '#707275',
            }}
          >
            Informe as configurações para suporte do produto
          </Typography>
        </Stack>

        <TextField
          fullWidth
          disabled={isAdmin}
          size="small"
          type="text"
          label="E-mail para suporte"
          placeholder="Digite o e-mail"
          {...register('supportEmail')}
          InputProps={{
            endAdornment: (
              <InfoInputAdornment title="Esse e-mail será apresentado na página de obrigado padrão e enviado por e-mail de compra aprovada. Caso não informe esse campo será apresentado o e-mail padrão da conta." />
            ),
          }}
          InputLabelProps={{
            shrink: (watch('supportEmail') || '') !== '' ? true : undefined,
          }}
        />
      </SimpleCard>

      {!isAdmin && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="end"
        >
          {buttonSave && (
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size="large"
              sx={{ textTransform: 'none' }}
            >
              Salvar
            </Button>
          )}
        </Stack>
      )}

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Stack>
  )
}

export default ProductGeneral

import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import { themeWithBackgroundWhite } from '../../../layouts/Theme'
import { TopLogo } from '../../../components/Logo/TopLogo'
import {
  Alert,
  AlertTitle,
  AppBar,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import NextIcon from '@mui/icons-material/ArrowRightAlt'
import Hosts from '../../../core/functions/hosts'
import { TInputFieldValidated } from '../../../components/Inputs/InputField'
import Validations from '../../../core/functions/validations'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import { useForm } from 'react-hook-form'
import { TSignUpTenant } from '../../../core/types/Tenant'
import { TenantController } from '../../../core/controllers/TenantController'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import { useNavigate } from 'react-router-dom'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ContentDialog from '../../../components/Dialogs/ContentDialog'
import BpCheckbox from '../../../components/Checkout/BpCheckbox'
import { useQuery } from '../../../components/Query/useQuery'
import { addDays } from '../../../core/functions/dateTime'
import Cookies from 'js-cookie'
import Terms from '../../../components/Terms/Terms'

export default function SignUp() {
  const navigate = useNavigate()
  const query = useQuery()

  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const [option, setOption] = React.useState<'signup' | 'help'>('signup')

  const [choose, setChoose] = React.useState(true)
  const [terms, setTerms] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [openTermsUse, setOpenTermsUse] = React.useState(false)
  const [openTermsSoftware, setOpenTermsSoftware] = React.useState(false)
  const [openContentPolicy, setOpenContentPolicy] = React.useState(false)
  const [errorTerms, setErrorTerms] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [error, setError] = React.useState('')
  const [minError, setMinError] = React.useState<'error' | 'green'>('error')
  const [maxError, setMaxError] = React.useState<'error' | 'green'>('error')
  const [lowError, setLowError] = React.useState<'error' | 'green'>('error')
  const [uppError, setUppError] = React.useState<'error' | 'green'>('error')
  const [speError, setSpeError] = React.useState<'error' | 'green'>('error')
  const [hasPasswordError, setHasPasswordError] = React.useState(false)

  const [shrinkName, setShrinkName] = React.useState(false)
  const [shrinkEmail, setShrinkEmail] = React.useState(false)
  const [shrinkPassword, setShrinkPassword] = React.useState(false)

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setFocus,
    setError: setErrorForm,
    setValue,
    reset,
  } = useForm<TSignUpTenant>({
    defaultValues: {
      name: '',
      email: '',
      emailConfirm: '',
      password: '',
    },
  })

  const emailValue = watch('email')

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const Submit = async (data: any) => {
    var isError = false

    var validate = handleChangeEmailValidate(data.email)
    if (validate.isError) {
      setErrorForm('email', {
        message: validate.error,
        type: 'required',
      })
      if (!isError) {
        setError('Por favor, informe um e-mail válido')
        setFocus('email')
      }
      isError = true
    }

    validate = handleChangeNameValidate(data.email)
    if (validate.isError) {
      setErrorForm('name', {
        message: validate.error,
        type: 'required',
      })
      if (!isError) {
        setError('Por favor, informe um nome válido')
        setFocus('name')
      }
      isError = true
    }

    validate = handleChangePasswordValidate(data.password)
    if (validate.isError) {
      setErrorForm('password', {
        message: validate.error,
        type: 'required',
      })
      if (!isError) {
        setError('Por favor, informe uma senha válida')
        setFocus('password')
      }
      isError = true
    }

    if (!terms) {
      if (!isError) {
        setError('É necessário aceitar os termos')
      }
      setErrorTerms(true)
      isError = true
    } else {
      setErrorTerms(false)
    }

    if (isError) {
      return
    }

    setSending(true)
    setSuccess(false)
    setError('')
    try {
      const indicatedOf = Cookies.get('__ceinid')
      var response = await TenantController.insertUser({
        data: { ...data, indicatedOf },
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        Cookies.remove('__ceinid')
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeNext = () => {
    if (option === 'signup') {
      setChoose(true)
      return
    }

    Hosts.openNewBlank(
      'https://wa.me/55999999999/?text=Quero+ajuda+para+acessar+o+curso+que+eu+comprei+meu+nome+é:'
    )
  }

  const handleChangeEmailValidate = (value: string): TInputFieldValidated => {
    if (value.trim() === '') {
      return {
        isError: true,
        error: 'Esse campo é obrigatório',
      }
    }

    if (!Validations.email(value)) {
      return {
        isError: true,
        error: 'E-mail inválido',
      }
    }

    return {
      isError: false,
      error: '',
    }
  }

  const handleChangeEmailConfirmValidate = (
    value: string
  ): TInputFieldValidated => {
    if (value.trim() === '') {
      return {
        isError: true,
        error: 'Esse campo é obrigatório',
      }
    }

    if (!Validations.email(value)) {
      return {
        isError: true,
        error: 'E-mail inválido',
      }
    }

    if (value !== emailValue) {
      return {
        isError: true,
        error: 'O e-mail digitado não confere com esse campo',
      }
    }

    return {
      isError: false,
      error: '',
    }
  }

  const handleChangePasswordValidate = (
    value: string
  ): TInputFieldValidated => {
    if (value.trim() === '') {
      return {
        isError: true,
        error: 'Esse campo é obrigatório',
      }
    }

    const result = Validations.password(value)
    if (result.includes('min'))
      return {
        isError: true,
        error: 'Precisa ser igual ou maior que 6 caracteres',
      }
    if (result.includes('max'))
      return {
        isError: true,
        error: 'Precisa ser igual ou menor que 20 caracteres',
      }
    if (result.includes('lower'))
      return {
        isError: true,
        error: 'Precisa ter no mínimo uma letra minúscula',
      }
    if (result.includes('upper'))
      return {
        isError: true,
        error: 'Precisa ter no mínimo uma letra maiúscula',
      }
    if (result.includes('special'))
      return {
        isError: true,
        error: 'Precisa ter no mínimo um caracter especial',
      }

    return { isError: false, error: '' }
  }

  const handleChangePassword = (value: string) => {
    if (value.trim().length < 1) return

    const result = Validations.password(value)
    setMinError(result.includes('min') ? 'error' : 'green')
    setMaxError(result.includes('max') ? 'error' : 'green')
    setLowError(result.includes('lower') ? 'error' : 'green')
    setUppError(result.includes('upper') ? 'error' : 'green')
    setSpeError(result.includes('special') ? 'error' : 'green')

    if (!result.includes('success')) {
      setHasPasswordError(true)
      setErrorForm('password', {
        message: 'Por favor, informe uma senha válida',
        type: 'required',
      })
    } else {
      setHasPasswordError(false)
    }
  }

  const handleChangeNameValidate = (value: string): TInputFieldValidated => {
    if (value.trim() === '') {
      return {
        isError: true,
        error: 'Esse campo é obrigatório',
      }
    }
    return {
      isError: false,
      error: '',
    }
  }

  React.useEffect(() => {
    setShrinkName(name.length > 0)
    setShrinkEmail(email.length > 0)
    setShrinkPassword(password.length > 0)
  }, [name, email, password])

  React.useEffect(() => {
    setLoading(true)
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.innerWidth, window.innerHeight])
    })

    const indicatedOf = query.get('inid')
    if (indicatedOf) {
      const expires = addDays(1)
      Cookies.set('__ceinid', indicatedOf, { expires })
      navigate('/signup')
    }

    // auto preenchimento quando salva a senha no browser
    new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
      reset({
        name: '',
        email: '',
        emailConfirm: '',
        password: '',
      })
      setLoading(false)
    })
  }, [])

  return (
    <ThemeProvider theme={themeWithBackgroundWhite}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ px: 0 }}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            backgroundColor: 'white',
            height: { xs: 'auto', md: '100vh' },
            width: '100%',
            m: 0,
            margin: 0,
          }}
        >
          <Grid
            container
            columns={16}
            sx={{
              height: { xs: 'auto', md: '100vh' },
              maxWidth: '100%',
            }}
          >
            <Grid
              xs={16}
              md={8}
              justifyContent="start"
              textAlign="start"
              sx={{ height: { xs: 'auto', md: '100vh' } }}
            >
              {!choose && (
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <Typography variant="h5" fontWeight={600}>
                    Entrar na sua conta
                  </Typography>
                  <Card sx={{ width: '100%', mt: 3, mb: 2 }}>
                    <Box sx={{ p: 3 }}>
                      <RadioGroup
                        aria-labelledby="option-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={option}
                        onChange={(e) =>
                          setOption(
                            e.target.value === 'signup' ? 'signup' : 'help'
                          )
                        }
                      >
                        <FormControlLabel
                          value="signup"
                          control={<Radio />}
                          label={
                            <Typography variant="subtitle2">
                              Quero vender produtos digitais. Sou um
                              infoprodutor, co-produtor ou afiliado.
                            </Typography>
                          }
                          sx={{ marginBottom: 2 }}
                        />
                        <FormControlLabel
                          value="help"
                          control={<Radio />}
                          label={
                            <Typography variant="subtitle2">
                              {`Preciso de ajuda com um produto que comprei pelo ${process.env.REACT_APP_COPYRIGHT}`}
                              .
                            </Typography>
                          }
                          sx={{ margin: 'normal' }}
                        />
                      </RadioGroup>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        py: 2,
                        px: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                      }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<NextIcon />}
                        onClick={handleChangeNext}
                      >
                        Continuar
                      </Button>
                    </Box>
                  </Card>
                </Box>
              )}
              <AppBar
                position="static"
                elevation={0}
                sx={{
                  backgroundColor: 'white',
                  borderBottom: { xs: '1px solid #DBDDDF', sm: '0px' },
                }}
              >
                <Box
                  width={{ xs: '100%', sm: '160px' }}
                  height={{ xs: '46px', sm: '64px' }}
                  sx={{ mt: '10px' }}
                >
                  <TopLogo margimBottom="0px" />
                </Box>
              </AppBar>
              {choose && option === 'signup' && (
                <Container
                  maxWidth="md"
                  sx={{
                    height: { xs: 'auto', md: '90%' },
                    px: 3,
                  }}
                >
                  <Stack
                    direction="column"
                    alignContent="space-evenly"
                    justifyContent="space-evenly"
                    sx={{ pr: { xs: 0, md: 8 } }}
                  >
                    <Box
                      component="form"
                      onSubmit={handleSubmit(Submit)}
                      noValidate
                      sx={{
                        p: 0,
                      }}
                    >
                      <Box
                        sx={{
                          mt: windowSize[1] < 1000 ? 7 : 14,
                          maxWidth: windowSize[0] < 600 ? '300px' : '500px',
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h5"
                          fontWeight={700}
                          fontSize={{ xs: '20px', sm: '32px' }}
                          lineHeight={{ xs: '24px', sm: '40px' }}
                          sx={{ mb: 1, color: '#3B3838' }}
                        >
                          Obrigado pelo interesse em se cadastrar na Celetus
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontSize={{ xs: '14px', sm: '16px' }}
                          lineHeight={{ xs: '18px', sm: '20px' }}
                          sx={{ mb: 3, color: '#767171' }}
                        >
                          Nós iremos te ajudar a dar os primeiros passos.
                          Cadastre-se é totalmente gratuito
                        </Typography>
                      </Box>

                      <Box>
                        <TextField
                          InputLabelProps={{
                            sx: {
                              ml: 4.5,
                              color: 'black',
                            },
                            shrink: shrinkName,
                            required: false,
                          }}
                          sx={(theme) => ({
                            '& .Mui-focused .MuiInputAdornment-root': {
                              color: theme.palette.primary.main,
                            },
                            '& .Mui-error .MuiInputAdornment-root': {
                              color: theme.palette.error.main,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              px: 5.5,
                            },
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/assets/icons/name/name.svg"
                                  alt=""
                                />
                              </InputAdornment>
                            ),
                          }}
                          required
                          fullWidth
                          id="name"
                          label="Nome Completo"
                          autoComplete="name"
                          autoFocus
                          {...register('name')}
                          error={errors.name?.type === 'required'}
                          onChange={(event) => setName(event.target.value)}
                        />
                        {errors.name?.type === 'required' && (
                          <Typography variant="caption" color="error">
                            {errors.name?.message}
                          </Typography>
                        )}
                      </Box>

                      <Box sx={{ mt: 1 }}>
                        <TextField
                          InputLabelProps={{
                            sx: {
                              ml: 4.5,
                              color: 'black',
                            },
                            shrink: shrinkEmail,
                            required: false,
                          }}
                          sx={(theme) => ({
                            '& .Mui-focused .MuiInputAdornment-root': {
                              color: theme.palette.primary.main,
                            },
                            '& .Mui-error .MuiInputAdornment-root': {
                              color: theme.palette.error.main,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              px: 5.5,
                            },
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/assets/icons/mail/mail.svg"
                                  alt=""
                                />
                              </InputAdornment>
                            ),
                          }}
                          required
                          fullWidth
                          id="email"
                          label="E-mail"
                          //autoComplete="email"
                          {...register('email')}
                          onChange={(event) => setEmail(event.target.value)}
                          inputProps={{
                            style: {
                              textTransform: 'lowercase',
                            },
                          }}
                          error={errors.email?.type === 'required'}
                          disabled={loading}
                        />
                        {errors.email?.type === 'required' && (
                          <Typography variant="caption" color="error">
                            {errors.email?.message}
                          </Typography>
                        )}
                      </Box>

                      <Box sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          InputLabelProps={{
                            sx: {
                              ml: 4.5,
                              color: 'black',
                            },
                            shrink: shrinkPassword,
                            required: false,
                          }}
                          sx={(theme) => ({
                            '& .Mui-focused .MuiInputAdornment-root': {
                              color: theme.palette.primary.main,
                            },
                            '& .Mui-error .MuiInputAdornment-root': {
                              color: theme.palette.error.main,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              px: 5.5,
                            },
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  loading="lazy"
                                  src="/assets/icons/password/password.svg"
                                  alt=""
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle search"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <img
                                      src="/assets/icons/eyes/eyeInvisible.svg"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="/assets/icons/eyes/eyeVisible.svg"
                                      alt=""
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                          id="password-adornment"
                          label="Digite sua senha"
                          //autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          onInput={(e) =>
                            handleChangePassword((e.target as any).value)
                          }
                          {...register('password')}
                          error={hasPasswordError}
                          onChange={(event) => setPassword(event.target.value)}
                          disabled={loading}
                        />

                        {(errors.password?.type === 'required' ||
                          hasPasswordError) && (
                          <Alert
                            severity={hasPasswordError ? 'error' : 'success'}
                            sx={{ width: '100%', mt: 1 }}
                          >
                            <AlertTitle>
                              A senha precisa respeitar as seguintes condições
                            </AlertTitle>
                            <Typography variant="caption" color={minError}>
                              Precisa ser igual ou maior que 6 caracteres
                            </Typography>
                            <br />
                            <Typography variant="caption" color={maxError}>
                              Precisa ser igual ou menor que 20 caracteres
                            </Typography>
                            <br />
                            <Typography variant="caption" color={lowError}>
                              Precisa ter no mínimo uma letra minúscula
                            </Typography>
                            <br />
                            <Typography variant="caption" color={uppError}>
                              Precisa ter no mínimo uma letra maiúscula
                            </Typography>
                            <br />
                            <Typography variant="caption" color={speError}>
                              Precisa ter no mínimo um caracter especial
                            </Typography>
                          </Alert>
                        )}
                      </Box>

                      <FormControlLabel
                        control={
                          <BpCheckbox
                            value="allowExtraEmails"
                            color="primary"
                            checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}
                          />
                        }
                        label={
                          <Typography
                            lineHeight="18px"
                            fontSize={{ xs: '13px', sm: '14px' }}
                            variant="body2"
                            sx={{
                              color: '#5D5E61',
                            }}
                          >
                            Eu li e aceito os{' '}
                            <Link
                              onClick={(e) => {
                                e.preventDefault()
                                setOpenTermsUse(true)
                              }}
                              sx={{
                                color: '#5D5E61',
                              }}
                            >
                              termos de uso
                            </Link>
                            ,{' '}
                            <Link
                              onClick={(e) => {
                                e.preventDefault()
                                setOpenTermsSoftware(true)
                              }}
                              sx={{
                                textDecoration: 'none',
                                color: '#1C52BD',
                                fontWeight: 'bold',
                              }}
                            >
                              termos de licença de uso de software
                            </Link>
                            ,{' '}
                            <Link
                              onClick={(e) => {
                                e.preventDefault()
                                setOpenContentPolicy(true)
                              }}
                              sx={{
                                textDecoration: 'none',
                                color: '#1C52BD',
                                fontWeight: 'bold',
                              }}
                            >
                              política de conteúdo
                            </Link>
                            {` do ${process.env.REACT_APP_COPYRIGHT}.`}
                          </Typography>
                        }
                      />
                      {errorTerms && (
                        <Typography variant="caption" color="error">
                          É necessário aceitar os termos
                        </Typography>
                      )}

                      <Box
                        sx={{
                          position: 'relative',
                          mt: 3,
                          mb: 1,
                        }}
                      >
                        <Button
                          type="submit"
                          fullWidth
                          variant="main"
                          style={{ height: 50 }}
                          sx={{
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '24px',
                          }}
                        >
                          Criar conta
                        </Button>
                        <Typography
                          variant="body2"
                          textAlign="center"
                          fontSize="14px"
                          lineHeight="18px"
                          sx={{
                            mt: 3,
                            mb: 3,
                            color: '#767171',
                          }}
                        >
                          Já possui uma conta?{' '}
                          <Link
                            component={Link}
                            href="/"
                            fontWeight={500}
                            sx={{
                              textDecoration: 'none',
                              color: '#1C52BD',
                              fontWeight: 'bold',
                            }}
                          >
                            Faça seu login
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Container>
              )}
            </Grid>
            {/*Right */}
            <Grid
              xs={8}
              justifyContent="start"
              width="100%"
              sx={{ display: { xs: 'none', md: 'block' }, py: 2 }}
            >
              <Paper
                elevation={0}
                square={false}
                sx={{
                  m: 0,
                  p: 0,
                  height: '100%',
                  width: '100%',
                  borderRadius: '20px',
                  overflow: 'hidden',
                  maxHeight: windowSize[1] < 800 ? '100%' : '1024px',
                }}
              >
                <img
                  id="middle"
                  src="/assets/signup/loginImagem.png"
                  alt=""
                  loading="lazy"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </Paper>
            </Grid>
            <Box
              sx={{
                position: 'absolute',
                top: '57%',
                left: 'calc(50% - 80px)',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <img
                id="frames"
                src="/assets/signup/FrameApproved.png"
                alt=""
                loading="lazy"
                style={{ objectFit: 'contain', height: '80%', width: '80%' }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '-14px',
                left: '-15px',
                display: { xs: 'block', md: 'none' },
              }}
            >
              <img
                id="top"
                src="/assets/signup/loginGraphicElementMobileTop.png"
                alt="Elemento do topo"
                loading="lazy"
                style={{ objectFit: 'contain', height: '121px', width: '79px' }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 'calc(50% - 130px)',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <img
                id="top"
                src="/assets/signup/loginGraphicElementTop.png"
                alt="Elemento do topo"
                loading="lazy"
                style={{ objectFit: 'contain', height: '80%', width: '80%' }}
              />
            </Box>
          </Grid>
        </Box>
      </Container>

      <ContentDialog
        open={openTermsUse}
        title="Termos de uso"
        onClose={() => setOpenTermsUse(false)}
      >
        <Box width="100%">
          <Terms />
        </Box>
      </ContentDialog>

      <ContentDialog
        open={openTermsSoftware}
        title="Termos de licença de uso de software"
        onClose={() => setOpenTermsSoftware(false)}
      >
        <Box width="100%" height="600px" />
      </ContentDialog>

      <ContentDialog
        open={openContentPolicy}
        title="Política de conteúdo"
        onClose={() => setOpenContentPolicy(false)}
      >
        <Box width="100%" height="600px" />
      </ContentDialog>

      <LoadingBackdrop open={sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={success}
        autoHideDuration={1000}
        onClose={() => {
          setSuccess(false)
          navigate(`/${window.location.search}`)
        }}
      >
        Cadastrado com sucesso
      </SuccessSnackbar>
    </ThemeProvider>
  )
}

import axios from 'axios'
import Cookies from 'js-cookie'
import { USERNAME_COOKIE } from '../core/constants'

const api = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  timeout: 500000,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      Cookies.remove(USERNAME_COOKIE)
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

export default api

export const apiReport = axios.create({
  baseURL: process.env.REACT_APP_REPORT_HOST,
  timeout: 500000,
})

export const isErrorApi = (error: any) => {
  return axios.isAxiosError(error)
}

export const setAuthorization = (token: string) => {
  if (token !== null && token !== '') {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    apiReport.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    api.defaults.headers.common['Authorization'] = ''
    apiReport.defaults.headers.common['Authorization'] = ''
  }
}

export const getResponseError = (error: unknown) => {
  var message = 'Erro não identificado'

  try {
    const err: any = error
    if (err && err !== null) {
      if (err.response) {
        if (err.response.data) {
          if (err.response.data.errors) {
            try {
              const errors: any[] = err.response.data.errors
              const list = errors.map((item: any) => item.message).join('\n')
              return list
            } catch (e) {
              console.error(e)
              try {
                const errors: any = err.response.data.title
                return errors.toString()
              } catch (er) {}
            }
          }
          if (err.response.data.detail) {
            return err.response.data.detail
          }
        }
      }
    }
  } catch (e) {
    console.error(e)
  }

  if (typeof error === 'string') {
    message = error.toUpperCase()
  } else if (error instanceof Error) {
    const err = error as Error
    message = err.message
  }

  return message
}

import {
  Box,
  Button,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { TProductDelivery } from '../../../../../core/types/Product'
import InfoInputAdornment from '../../../../../components/Inputs/InfoInputAdornment'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import Convertions from '../../../../../core/functions/convertions'
import Hosts from '../../../../../core/functions/hosts'
import { ProductGeneralMemberProps } from '../../../ProductTypes'
import { TMemberServiceType } from '../../../../../core/types/MemberService'
import { useMemberServiceClassroms } from '../../../../../core/hooks/useMemberServiceClassroms'
import { ProductGeneralClassrooms } from './ProductGeneralClassrooms'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'

const ProductGeneralMember = ({
  control,
  errors,
  memberServices,
  selectedOption,
  urlClub,
  watch,
  setValue,
  onChangeSelectedOption,
}: ProductGeneralMemberProps) => {
  const { isUserSysAdmin } = useContext(AuthContext)
  const { register } = control

  const memberServiceSettingId = watch('memberServiceSettingId')
  const accessPassword = watch('accessPassword')
  const deliveryValue = watch('delivery')

  const { data: classrooms } = useMemberServiceClassroms({
    id: memberServiceSettingId,
  })

  const isAdmin = isUserSysAdmin()
  const currentMemberService = memberServices.find(
    (ms) => ms.id === memberServiceSettingId
  )
  const memberServiceItems = memberServices.filter(
    (ms) => ms.type !== TMemberServiceType.CeletusClub
  )

  return (
    <Stack
      sx={{
        backgroundColor: '#FFFF',
        gap: '32px',
        padding: '32px',
        borderRadius: '8px',
      }}
    >
      <Stack spacing={0.2} sx={{ gap: '4px' }}>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '20px',
            color: '#343948',
          }}
        >
          Área de Membros
        </Typography>
        <Typography
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight={400}
          lineHeight="18px"
          color="#707275"
        >
          Informe uma área de membros válida onde seus alunos receberão o acesso
          e o nosso time possa validar a entrega.
        </Typography>

        <Stack spacing={2}>
          {deliveryValue === TProductDelivery.Course && (
            <Stack width="100%" spacing={2}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column' }}
                alignItems={'left'}
                justifyContent={'left'}
              >
                <RadioGroup
                  row
                  sx={{ m: 0 }}
                  value={selectedOption}
                  onChange={onChangeSelectedOption}
                >
                  <FormControlLabel
                    value="club"
                    control={<Radio />}
                    label="Celetus Club"
                  />

                  <FormControlLabel
                    value="integration"
                    control={<Radio />}
                    label="API pré-cadastrada"
                  />
                  <FormControlLabel
                    value="external"
                    control={<Radio />}
                    label="Validação Manual"
                  />
                </RadioGroup>
              </Box>

              {selectedOption === 'integration' && (
                <Collapse in={selectedOption === 'integration'}>
                  <Stack direction={'column'} spacing={2}>
                    <Typography
                      fontFamily="Montserrat"
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight="18px"
                      color="#707275"
                    >
                      Selecione uma área de membros que já foi pré-cadastrada
                      via API na área de aplicativos da Celetus.
                    </Typography>
                    <FormControl
                      fullWidth
                      size="small"
                      error={errors.memberServiceSettingId?.type === 'required'}
                    >
                      <InputLabel id="member-select-label">
                        Área de Membros
                      </InputLabel>
                      <Select
                        disabled={isAdmin}
                        required
                        variant="outlined"
                        labelId="member-select-label"
                        label="Área de Membros"
                        value={watch('memberServiceSettingId') ?? ''}
                        {...register('memberServiceSettingId')}
                        renderValue={(value?: string) => {
                          const ms = currentMemberService
                          const identifier = ms?.identifier
                          const type = Convertions.memberServiceTypeToString(
                            ms?.type
                          )
                          return `${identifier} - ${type}`
                        }}
                      >
                        {memberServiceItems.map((ms) => (
                          <MenuItem key={ms.id} value={ms.id}>
                            {ms.identifier} -{' '}
                            {Convertions.memberServiceTypeToString(ms.type)}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.memberServiceSettingId?.type === 'required' && (
                        <Typography variant="overline" color={'red'}>
                          Campo obrigatório
                        </Typography>
                      )}
                    </FormControl>
                    <Fade
                      in={
                        currentMemberService?.type ===
                        TMemberServiceType.MemberKit
                      }
                      mountOnEnter
                      unmountOnExit
                    >
                      <Stack
                        minHeight={40}
                        spacing={1}
                        direction="row"
                        alignItems="center"
                      >
                        <ProductGeneralClassrooms
                          data={classrooms}
                          disabled={isAdmin}
                          selecteds={watch('classroom') || ''}
                          onChange={(values) => setValue('classroom', values)}
                        />
                        <HtmlTooltip
                          title={
                            <Box>
                              <Box>
                                Selecione as turmas já pré-cadastradas na
                                plataforma MemberKit para garantir a correta
                                entrega do curso ao aluno.
                              </Box>
                              <Box>
                                Se alguma turma não estiver aparecendo, o
                                cadastro pode estar incorreto. Nesse caso,
                                verifique as informações e tente novamente.
                              </Box>
                            </Box>
                          }
                        >
                          <img
                            src="/assets/icons/infos/info.svg"
                            alt="info-classroom"
                            style={{
                              width: '25px',
                              height: '25px',
                            }}
                          />
                        </HtmlTooltip>
                      </Stack>
                    </Fade>
                  </Stack>
                </Collapse>
              )}

              {selectedOption === 'external' && (
                <Collapse in={selectedOption === 'external'}>
                  <Stack direction={'column'} spacing={2}>
                    <Typography
                      fontFamily="Montserrat"
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight="18px"
                      color="#707275"
                    >
                      Forneça ao nosso time de validação, credenciais, para
                      avaliar a entrega do produto.
                    </Typography>
                    <Box>
                      <TextField
                        fullWidth
                        disabled={isAdmin}
                        size="small"
                        type="text"
                        label="Link da área de membros"
                        placeholder="Digite a URL"
                        {...register('externalLink', {
                          required: true,
                        })}
                        InputProps={{
                          endAdornment: (
                            <InfoInputAdornment title="Digite o link para a área de membros" />
                          ),
                        }}
                        InputLabelProps={{
                          shrink:
                            (watch('externalLink') || '') !== ''
                              ? true
                              : undefined,
                        }}
                        error={
                          errors.externalLink?.type === 'required' ||
                          errors.externalLink?.type === 'invalidURL'
                        }
                      />
                      {errors.externalLink && (
                        <Typography variant="overline" color={'red'}>
                          {errors.externalLink.type === 'required'
                            ? 'Campo obrigatório'
                            : ''}
                          {errors.externalLink.type === 'invalidURL'
                            ? 'URL inválida'
                            : ''}
                        </Typography>
                      )}
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        disabled={isAdmin}
                        size="small"
                        type="email"
                        label=" E-mail "
                        placeholder="Digite o e-mail"
                        {...register('accessUser', {
                          required: 'true',
                        })}
                        InputProps={{
                          endAdornment: (
                            <InfoInputAdornment title="Digite o email para acessar o link da área de membros" />
                          ),
                        }}
                        InputLabelProps={{
                          shrink:
                            (watch('accessUser') || '') !== ''
                              ? true
                              : undefined,
                        }}
                        error={
                          errors.accessUser?.type === 'required' ||
                          errors.accessUser?.type === 'invalidEmail'
                        }
                      />
                      {errors.accessUser && (
                        <Typography variant="overline" color={'red'}>
                          {errors.accessUser.type === 'required'
                            ? 'Campo obrigatório'
                            : ''}
                          {errors.accessUser.type === 'invalidEmail'
                            ? 'E-mail inválido'
                            : ''}
                        </Typography>
                      )}
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        disabled={isAdmin}
                        size="small"
                        type="text"
                        label="Senha de acesso"
                        placeholder="Digite a senha"
                        {...register('accessPassword', {
                          required: true,
                        })}
                        InputProps={{
                          endAdornment: (
                            <InfoInputAdornment title="Digite a senha para acessar o link da área de membros" />
                          ),
                        }}
                        InputLabelProps={{
                          shrink:
                            (accessPassword || '') !== '' ? true : undefined,
                        }}
                        error={errors.accessPassword?.type === 'required'}
                      />
                      {errors.accessPassword && (
                        <Typography variant="overline" color={'red'}>
                          {errors.accessPassword.type === 'required'
                            ? 'Campo obrigatório'
                            : ''}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Collapse>
              )}

              {selectedOption === 'club' && urlClub && (
                <Collapse in={selectedOption === 'club'}>
                  <Button
                    variant="contained"
                    sx={{ width: 250 }}
                    onClick={() => Hosts.openNewBlank(urlClub)}
                  >
                    Acessar Celetus Club
                  </Button>
                </Collapse>
              )}
            </Stack>
          )}

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ pt: 1 }}
          >
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              type="text"
              label="Voxuy - Id do Plano"
              {...register('voxuyPlanId')}
              InputLabelProps={{
                shrink: (watch('voxuyPlanId') || '') !== '',
              }}
            />
            <TextField
              fullWidth
              disabled={isAdmin}
              size="small"
              type="text"
              label="Voxuy - Id do Evento"
              {...register('voxuyEventId')}
              InputLabelProps={{
                shrink: (watch('voxuyEventId') || '') !== '',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ProductGeneralMember

import {
  TProductPixel,
  TProductPixelType,
  TProductPriceInstallmentFees,
} from '../../../../core/types/Product'

export const productPriceInstallmentFeeDescription = {
  [TProductPriceInstallmentFees.None]: '',
  [TProductPriceInstallmentFees.Customer]: 'Com juros para o cliente',
  [TProductPriceInstallmentFees.Producer]: 'Sem juros para o cliente',
  [TProductPriceInstallmentFees.Hybrid]: 'Híbrido',
}

export const productPixelDefault: TProductPixel = {
  id: '',
  productId: '',
  pixel: '',
  pixelToken: '',
  pixelDomain: '',
  type: TProductPixelType.Facebook,
  enabledDomain: false,
  cnameVerified: false,
  selectivePurchase: true,
  selectiveImmediatePurchase: true,
  selectiveNonImmediatePurchase: true,
  visitSalesPage: true,
}

import { useContext } from 'react'
import { TAdminUserContext } from '../types/User'
import { AdminUserContext } from '../context/AdminUserContext'

export const useAdminUserContext = (): TAdminUserContext => {
  const context = useContext(AdminUserContext)
  if (context === undefined) {
    throw new Error(
      'useAdminUserContext deve ser usado dentro de um StorageProvider'
    )
  }
  return context
}

import { createContext, ReactNode, useState } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { TAuthContext, TAuthUser, TUserType } from '../types/Auth'
import Cookies from 'js-cookie'
import { addDays } from '../functions/dateTime'
import { STAY_COOKIE, USERNAME_COOKIE } from '../constants'

type Props = {
  children?: ReactNode
}

const initialValue = {
  user: null,
  stayConnected: true,
  setUser: (newUser: TAuthUser | null) => {},
  setStayConnected: (newStay: boolean) => {},
  isUserSysAdmin: () => false,
}

const AuthContext = createContext<TAuthContext>(initialValue)

const AuthProvider = ({ children }: Props) => {
  const userCookie = Cookies.get(USERNAME_COOKIE)
  const stayCookie = Cookies.get(STAY_COOKIE)
  const [user, setAuthUser] = useState<TAuthUser | null>(
    userCookie && userCookie !== '' ? JSON.parse(userCookie) : null
  )
  const [stayConnected, setAuthStayConnected] = useState<boolean>(
    stayCookie ? JSON.parse(stayCookie) : true
  )
  const [userLocal, setUserLocal] = useLocalStorage('user', null)

  const setUser = (newUser: TAuthUser | null) => {
    if (newUser) {
      const expires = !stayConnected
        ? undefined
        : newUser.exp
          ? newUser.exp
          : addDays(1)
      const newUserJson = JSON.stringify(newUser)
      Cookies.set(USERNAME_COOKIE, newUserJson, { expires })
      Cookies.set(USERNAME_COOKIE, newUserJson, {
        expires,
        domain: process.env.REACT_APP_COOKIE_HOST,
      })
    } else {
      Cookies.remove(USERNAME_COOKIE)
      Cookies.remove(USERNAME_COOKIE, {
        domain: process.env.REACT_APP_COOKIE_HOST,
      })
    }

    setAuthUser(newUser)
  }

  const setStayConnected = (newStay: boolean) => {
    setAuthStayConnected(newStay)
    const expires = addDays(30)
    Cookies.set(STAY_COOKIE, JSON.stringify(newStay), { expires })
  }

  const isUserSysAdmin = () => {
    return user?.UserType === TUserType.SystemAdmin
  }

  if (userLocal) {
    setUser(userLocal as TAuthUser)
    setUserLocal(null)
  }

  return (
    <AuthContext.Provider
      value={{ user, stayConnected, setUser, setStayConnected, isUserSysAdmin }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }

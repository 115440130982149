import { Download, Downloading } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useCallback } from 'react'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../../../core/hooks/useDownloadFile'

const UserSettingExtractDownloadButton = ({
  tenantId,
}: {
  tenantId: string
}) => {
  const apiDefinition = useCallback(
    async (fileType: ExportFileTypeEnum) =>
      ReportDownloadController.getTenantExtractFile({
        fileType,
        tenantId,
      }),
    []
  )

  const getFileName = (fileType: ExportFileTypeEnum) =>
    `extract_${new Date().toISOString().replace(':', '_').replace('.', '_')}.xlsx`

  const onError = (value: string) => console.error(value)

  const { downloading, download } = useDownloadFile({
    apiDefinition,
    getFileName,
    onError,
  })

  return (
    <Button
      variant="outlined"
      startIcon={downloading ? <Downloading /> : <Download />}
      disabled={downloading}
      onClick={() => download(ExportFileTypeEnum.XLS)}
      sx={{ textTransform: 'none' }}
    >
      {downloading ? 'Exportando extrato...' : 'Exportar extrato'}
    </Button>
  )
}

export default UserSettingExtractDownloadButton

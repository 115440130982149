import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Box, Button, Stack } from '@mui/material'
import {
  TTenantAnticipationType,
  TTenantSettings,
} from '../../core/types/Tenant'
import NumberFunctions from '../../core/functions/NumberFunctions'

export interface ITenantBalanceWihdrawCardProp {
  current: TTenantAnticipationType | null
  tenantSettings?: TTenantSettings | null
  platformFee?: number
  onClick: () => void
}

export default function TenantBalanceWihdrawCard({
  current,
  tenantSettings,
  platformFee,
  onClick,
}: ITenantBalanceWihdrawCardProp) {
  const currentAnticipationNumber = Number(
    current ?? TTenantAnticipationType.D30
  )
  const anticipationNumber =
    tenantSettings?.enableAnticipationD2 ?? false
      ? 2
      : tenantSettings?.enableAnticipationD15 ?? false
        ? 15
        : 0

  const anticipationFee =
    tenantSettings?.enableAnticipationD2 ?? false
      ? tenantSettings?.anticipationD2Fee
      : tenantSettings?.enableAnticipationD15 ?? false
        ? tenantSettings?.anticipationD15Fee
        : 0

  const platformFees = Number(platformFee ?? 0) + Number(anticipationFee ?? 0)

  if (anticipationNumber === 0) {
    return <></>
  }

  return (
    <Card sx={{ width: '100%', m: 0, p: 0 }} elevation={0}>
      <CardContent sx={{ p: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              display="flex"
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#F2F2FF',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="assets/icons/commissions/commissionsIcon.svg"
                alt="commissions"
              />
            </Box>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="600"
              lineHeight="18px"
              sx={{ m: 0, p: 0, color: '#38393B' }}
            >
              Comissões
            </Typography>
          </Stack>

          <Box display="flex" flexDirection="row" gap="16px">
            <Stack alignItems="flex-end">
              {currentAnticipationNumber !== anticipationNumber && (
                <Typography variant="h6" textAlign="end">
                  {anticipationNumber} dias!
                </Typography>
              )}
              {currentAnticipationNumber === anticipationNumber && (
                <Typography variant="h6" textAlign="end">
                  Parabéns, antecipação liberada!
                </Typography>
              )}
              <Typography variant="body2" gutterBottom textAlign="end">
                {NumberFunctions.toPercentage(platformFees)} + R$1,00 /
                transação
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Button
              variant={
                currentAnticipationNumber !== anticipationNumber
                  ? 'outlined'
                  : 'outlined'
              }
              size="small"
              onClick={onClick}
              sx={{ alignItems: 'center', fontColor: '#1C52BD' }}
            >
              {currentAnticipationNumber !== anticipationNumber
                ? 'Alterar'
                : 'Alterar'}
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

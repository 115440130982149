import { getResponseError, apiReport } from '../../services/api'
import { IErrorResponse } from '../types/ErrorResponse'
import {
  SubscriptionDateFilterType,
  SubscriptionStatusEnum,
  TCheckoutOrderReport,
} from '../types/Order'

interface IReportDownloadProp {
  fileType: ExportFileTypeEnum
  search?: string
  type: string
  report: TCheckoutOrderReport
  lastDays?: number
  startDate?: Date | null
  endDate?: Date | null
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
  hasAddress?: boolean
}

interface IReportSubscriptionDownloadProp {
  fileType: ExportFileTypeEnum
  search?: string
  type: SubscriptionStatusEnum
  dateType: SubscriptionDateFilterType
  startDate?: Date | null | undefined
  endDate?: Date | null | undefined
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
}

interface IDownloadFileNotazz {
  fileType: ExportFileTypeEnum
  email?: string
}

interface ISalesAnalysisDownloadProp {
  fileType: ExportFileTypeEnum
  search?: string
  type: string
  lastDays?: number
  startDate?: Date | null
  endDate?: Date | null
  name?: string
}

export enum ExportFileTypeEnum {
  XLS = 1,
  CSV,
}

const ordersGetAll = async ({
  fileType,
  search,
  type,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
  hasAddress,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/checkout/file/${hasAddress ? 'customer' : 'all'}?ExportFileType=${fileType}&Type=${type}`

    if (report !== 'all') {
      path += `&Report=${report}`
    }

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    if (startDate != null) {
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate === null || endDate === undefined ? new Date().toISOString() : endDate.toISOString()}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getSubscriptions = async ({
  fileType,
  search,
  type,
  dateType,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IReportSubscriptionDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Subscription/checkout/file/all?ExportFileType=${fileType}&Status=${type}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    if (dateType !== SubscriptionDateFilterType.None && startDate) {
      endDate ??= new Date()
      path += `&TypeDateFilter=${dateType}&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrdersOpen = async ({
  fileType,
  search,
  lastDays,
  report,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/checkout/file/open?ExportFileType=${fileType}&LastDays=${lastDays}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getSalesAnalysis = async ({
  fileType,
  search,
  lastDays,
}: ISalesAnalysisDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/SalesAnalysis/file?ExportFileType=${fileType}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrdersApproved = async ({
  fileType,
  search,
  type,
  report,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/checkout/file/approved?ExportFileType=${fileType}&Type=${type}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getLostSales = async ({
  fileType,
  search,
  type,
  report,
  lastDays,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/checkout/file/lost/sales?ExportFileType=${fileType}&Type=${type}&LastDays=${lastDays}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrdersAccesses = async ({
  fileType,
  search,
  type,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/page/accesses/file?ExportFileType=${fileType}&Type=${type}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    if (startDate != null) {
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate === null || endDate === undefined ? new Date().toISOString() : endDate.toISOString()}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrdersAnalitycs = async ({
  fileType,
  search,
  type,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/order/admin/accesses/file?ExportFileType=${fileType}&Type=${type}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    if (startDate != null) {
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate === null || endDate === undefined ? new Date().toISOString() : endDate.toISOString()}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const notazzFile = async ({
  fileType,
  email,
}: IDownloadFileNotazz): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/notazz/file?ExportFileType=${fileType}`

    if (email && email !== '') {
      path += `&email=${email}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const invoicesFile = async ({
  year,
  month,
}: {
  year: number
  month: number
}): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/sales/invoices/file?year=${year}&month=${month + 1}`

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getAnticipationsFile = async ({
  fileType,
  search,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IReportDownloadProp): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/checkout/file/anticipation?ExportFileType=${fileType}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    if (startDate != null) {
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate === null || endDate === undefined ? new Date().toISOString() : endDate.toISOString()}`
    }

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrderRefundPdf = async ({
  id,
}: {
  id: string
}): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/checkout/${id}/pdf/refund`

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getTenantExtractFile = async ({
  fileType,
  tenantId,
}: {
  fileType: ExportFileTypeEnum
  tenantId: string
}): Promise<Blob | IErrorResponse> => {
  try {
    var path = `/Report/tenant/file/extract?ExportFileType=${fileType}&TenantId=${tenantId}`

    const { data } = await apiReport.get<Blob>(path, {
      responseType: 'blob',
      headers: {
        Accept:
          fileType === ExportFileTypeEnum.XLS
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv',
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

export const ReportDownloadController = {
  ordersGetAll,
  getOrdersApproved,
  getOrdersOpen,
  getLostSales,
  getOrdersAccesses,
  getOrdersAnalitycs,
  notazzFile,
  invoicesFile,
  getAnticipationsFile,
  getSalesAnalysis,
  getSubscriptions,
  getOrderRefundPdf,
  getTenantExtractFile,
}

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useProductPixels } from '../../../../../core/hooks/useProductPixels'
import {
  TProductPixel,
  TProductPixelRegisterPage,
  TProductPixelType,
} from '../../../../../core/types/Product'
import ProductController from '../../../../../core/controllers/ProductController'
import ConfirmDialog from '../../../../../components/Dialogs/ConfirmDialog'
import ProductPixelRegister from '../../../../../components/Product/Pixel/ProductPixelRegister'
import { productPixelDefault } from '../MyAffiliationsDetailsConsts'
import { IMyAffiliationsPixelsProps } from '../MyAffiliationsDetailsTypes'

const MyAffiliationsPixels = ({
  affiliated,
  sending,
  setErrorSending,
  setSending,
  setSuccess,
}: IMyAffiliationsPixelsProps) => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<TProductPixel>({
    ...productPixelDefault,
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [errorPixel, setErrorPixel] = useState('')
  const [errorDomain, setErrorDomain] = useState('')
  const [errorToken, setErrorToken] = useState('')
  const [registerPageIndex, setRegisterPageIndex] =
    useState<TProductPixelRegisterPage>(TProductPixelRegisterPage.Pixel)

  const { loading, data, error, execute, clear } = useProductPixels({
    productId: affiliated?.productId || '',
    affiliatedCode: affiliated?.codeId,
  })

  const handleChangeEditPixel = (productPixel: TProductPixel) => {
    setCurrent(productPixel)
    setRegisterPageIndex(TProductPixelRegisterPage.Pixel)
    setOpen(true)
  }

  const handleChangePixel = (type: TProductPixelType) => {
    const productPixel = { ...productPixelDefault, type }
    setCurrent(productPixel)
    setRegisterPageIndex(TProductPixelRegisterPage.Pixel)
    setOpen(true)
  }

  const handleChangeClose = (productPixel?: TProductPixel) => {
    productPixel ??= { ...productPixelDefault }
    setCurrent(productPixel)
    setOpen(false)
  }

  const validationPixelErrors = () => {
    let isError = false

    if ((current.pixel ?? '') === '') {
      setErrorPixel('Campo obrigatório')
      isError = true
    }

    if (current.type === TProductPixelType.GoogleAds) {
      if ((current.pixelToken ?? '') === '') {
        setErrorToken('Campo obrigatório')
        isError = true
      }
    }

    return isError
  }

  const validationDomainErrors = () => {
    let isError = false

    if (current.type === TProductPixelType.Facebook) {
      if (current.enabledDomain) {
        if ((current.pixelDomain ?? '') === '') {
          setErrorDomain('Campo obrigatório')
          isError = true
        }
      }
    }

    return isError
  }

  const validationWithErrors = () => {
    let isError = false

    if (validationPixelErrors()) {
      isError = true
    }

    if (validationDomainErrors()) {
      isError = true
    }

    return isError
  }

  const clearErrors = () => {
    if (errorPixel !== '') {
      setErrorPixel('')
    }
    if (errorDomain !== '') {
      setErrorDomain('')
    }
    if (errorToken !== '') {
      setErrorToken('')
    }
  }

  const handleChangeSave = async () => {
    if (validationWithErrors()) {
      return
    }

    clearErrors()

    current.productId = affiliated?.productId || ''
    current.affiliatedId = affiliated?.id

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await ProductController.insertPixel({ data: current })
      } else {
        response = await ProductController.updatePixel({ data: current })
      }

      if (!response.success) {
        setErrorSending(response.error)
        return
      }

      if (current.enabledDomain) {
        if (!(response.data?.cnameVerified ?? false)) {
          setErrorSending('CNAME não verificado, tente novamente mais tarde.')
        }
      }

      setSuccess(true)
      handleChangeClose()
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleChangeVerifyDomain = async () => {
    if (validationWithErrors()) {
      return
    }

    clearErrors()

    current.productId = affiliated?.productId || ''
    current.affiliatedId = affiliated?.id

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await ProductController.insertPixel({ data: current })
        if (response.data.cnameVerified) {
          setCurrent({
            ...current,
            id: response.data.id,
            cnameVerified: true,
          })
        } else {
          setCurrent({
            ...current,
            id: response.data.id,
            cnameVerified: false,
            pixelDomain: (current.pixelDomain ?? '').startsWith('pixel.')
              ? (current.pixelDomain ?? '').replace('pixel.', '')
              : current.pixelDomain,
          })
        }
      } else {
        response = await ProductController.updatePixel({ data: current })
        setCurrent({
          ...current,
          cnameVerified: response.data?.cnameVerified ?? false,
          pixelDomain: (current.pixelDomain ?? '').startsWith('pixel.')
            ? (current.pixelDomain ?? '').replace('pixel.', '')
            : current.pixelDomain,
        })
      }

      if (!(response.data?.cnameVerified ?? false)) {
        setErrorSending('CNAME não verificado, tente novamente mais tarde.')
        return
      }
    } finally {
      setSending(false)
    }
  }

  const handleDelete = (productPixel?: TProductPixel) => {
    if (!productPixel) {
      return
    }
    setCurrent({ ...productPixel })
    setOpenDelete(true)
  }

  const handleDeleteClose = () => {
    setOpenDelete(false)
    setCurrent({ ...productPixelDefault })
  }

  const deletePixel = async () => {
    if (!current || current.id === '') {
      return
    }

    setSending(true)
    setSuccess(false)
    setErrorSending('')

    try {
      const response = await ProductController.removePixel({
        pixelId: current.id,
      })

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setSuccess(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Stack direction="row" spacing={1} justifyContent="end" sx={{ p: 2 }}>
        {/*<Button
          color="error"
          variant="outlined"
          startIcon={
            <img src="/assets/icons/social/logoGoogle.svg" alt="google" />
          }
          onClick={() => handleChangePixel(TProductPixelType.GoogleAds)}
          sx={{ textTransform: 'none' }}
        >
          Google Ads
        </Button>
        <Button
          color="info"
          variant="outlined"
          startIcon={
            <img src="/assets/icons/social/logoFace.svg" alt="facebook" />
          }
          onClick={() => handleChangePixel(TProductPixelType.Facebook)}
          sx={{ textTransform: 'none' }}
        >
          Facebook
        </Button>*/}

        <Button
          variant="outlined"
          sx={{
            color: '#1C52BD',
            borderColor: '#1C52BD',
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '24px',
          }}
          onClick={() => handleChangePixel(TProductPixelType.Facebook)}
        >
          + Novo Pixel
        </Button>
      </Stack>

      {loading && (
        <Stack
          direction="row"
          alignContent="center"
          alignItems="center"
          sx={{ p: 5 }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && (
        <Grid container columns={16}>
          <Grid
            item
            xs={4}
            sx={{
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              backgroundColor: 'divider',
            }}
          >
            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
              Pixel
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ backgroundColor: 'divider' }}>
            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
              Subdomínio
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: 'divider' }}>
            {/* <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}></Typography> */}
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
              backgroundColor: 'divider',
            }}
          >
            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
              Ações
            </Typography>
          </Grid>
          {data?.map((pixel) => (
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              sx={{
                borderRadius: '4px',
                border: '1px solid #DCDDDE',
                width: '100%',
                mt: 1,
              }}
            >
              <Grid item xs={4}>
                <Typography noWrap variant="body2" sx={{ pl: 2, py: 1 }}>
                  {pixel.pixel}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography noWrap variant="body2" sx={{ pl: 2, py: 1 }}>
                  {pixel.pixelDomain}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {pixel.type === TProductPixelType.Facebook && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color={pixel.cnameVerified ? 'success' : 'warning'}
                    label={
                      pixel.cnameVerified
                        ? 'CNAME verificado'
                        : 'CNAME não validado'
                    }
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={() => handleChangeEditPixel(pixel)}>
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleDelete(pixel)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            </Stack>
          ))}
        </Grid>
      )}

      <ProductPixelRegister
        open={open}
        sending={sending}
        isEditing={current.id !== ''}
        productPixel={current}
        errorPixel={errorPixel}
        errorDomain={errorDomain}
        errorToken={errorToken}
        pageIndex={registerPageIndex}
        setOpen={setOpen}
        setProductPixel={setCurrent}
        onSave={handleChangeSave}
        onVerifyDomain={handleChangeVerifyDomain}
        onValidationPixelErrors={validationPixelErrors}
        onValidationDomainErrors={validationDomainErrors}
        setPageIndex={setRegisterPageIndex}
      />

      <ConfirmDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="Deseja realmente deletar a configuração do pixel ?"
        onYes={deletePixel}
      />
    </Box>
  )
}

export default MyAffiliationsPixels

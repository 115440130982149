import { Box, Typography } from '@mui/material'
import LinkCelestusTerm from './LinkCeletusTerm'

const Terms = () => {
  return (
    <Box>
      <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
        TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA CELETUS
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Versão de 06/04/2025
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        Ao abrigo destes Termos e Condições Gerais de Uso, a Plataforma Celetus
        permite o acesso a diversas funcionalidades de seu software que
        habilitam os Usuários a transacionarem entre si, com especial destaque
        para Compradores e Criadores de Conteúdo realizarem transações sobre
        Infoprodutos diretamente entre eles, de modo que, ao adquirir o acesso a
        Infoprodutos pela Plataforma Celetus, a Celetus não é e não se torna
        parte, interveniente ou garantidora de qualquer relação entre os
        Usuários, não atuando como agente ou distribuidor de Infoprodutos,
        podendo cobrar taxas pelo licenciamento de uso de software não
        customizável sobre o valor de Transação conforme Taxas definidas e/ou
        pela disponibilização e mediação entre usuários em virtude de
        funcionalidades financeiras e/ou de armazenamento, conforme definidos na
        seção de Taxas, Gestão de Pagamento e Cancelamentos destes Termos de
        Uso.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        As novas ferramentas ou os novos recursos adicionados aos Serviços
        atuais também estarão sujeitos a estes Termos e Condições Gerais de Uso
        da Plataforma Celetus (doravante “Termos” ou “Termos de Uso”). Você pode
        consultar a versão atualizada dos Termos de Uso a qualquer momento em: (
        <LinkCelestusTerm />)
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        Você precisa ler, concordar e aceitar todos os termos e condições
        contidos ou expressamente mencionados nestes Termos de Uso, inclusive a
        Política de Conteúdo (“Política de Conteúdo”) (<LinkCelestusTerm />
        ), a Política de Privacidade (<LinkCelestusTerm />) da Celetus
        (“Política de Privacidade”) e, se Criador de Conteúdo, o Contrato de
        Licença de Uso do Software e Celetus (“Licença de Uso “)(
        <LinkCelestusTerm />
        ), bem como a Política de Proteção de Dados, quando aplicável, e os
        Termos de Uso, todos em conjunto denominados “Documentos Conexos”, antes
        de criar uma conta da Celetus ou usar a Plataforma Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        Após aceitar estes Termos e os Documentos Conexos, a Celetus concederá
        automaticamente uma licença de uso não exclusiva e não customizável de
        software, todos disponibilizados em nuvem pela Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        O software é licenciado no estado em que se encontra e pode ser
        modificado, substituído ou removido pela Celetus a qualquer momento, sem
        aviso prévio. É o software que desempenha todos os atos e
        funcionalidades de nossa plataforma e o compromisso da Celetus é
        mantê-lo sempre atualizado, ainda que interrupções ocasionais possam
        ocorrer.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        A empresa responsável pela disponibilização da Plataforma e pela
        operacionalização das transações pode variar de acordo com o local em
        que a venda for realizada. As vendas realizadas no Brasil são
        operacionalizadas pela Celetus Pagamentos, Tecnologia e Serviços LTDA,
        CNPJ/ME nº __, com sede na __, CEP__ (“Celetus”).
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        O modelo de negócio firmado entre o Usuário e a Celetus dependerá da
        empresa com a qual o Usuário contrata.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        A celebração destes Termos de Uso e dos Documentos Conexos é comprovada
        pela concordância do Usuário por via eletrônica e pela confirmação de
        leitura no ato da contratação.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        O Usuário declara ter lido integralmente estes Termos de Uso e
        Documentos Conexos, concordando com todas as suas cláusulas, além de
        declarar ter preenchido a ficha cadastral com suas informações. resumos
        em linguagem informal e/ou orientações por meios eletrônicos (como
        e-mail e WhatsApp) sobre estes Termos e os Documentos Conexos poderão
        ser fornecidos apenas por conveniência, mas não são juridicamente
        vinculantes. Sempre leia os Termos de Uso, incluindo qualquer documento
        mencionado aqui, para ter as informações completas sobre seus direitos e
        obrigações. Ao usar a Celetus ou qualquer funcionalidade da Celetus,
        você concorda com estes Termos. Verifique periodicamente se há
        atualizações.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        gutterBottom
      >
        1- DEFINIÇÕES
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        Os seguintes termos, palavras, expressões e abreviações grafados com
        letras iniciais maiúsculas e não definidos em outras partes deste
        Contrato, independentemente de serem utilizados no singular ou no
        plural, terão o significado a eles atribuído nesta cláusula, exceto se
        expressamente indicado de outra forma:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Afiliado
        </Typography>
        : Usuário que se cadastra na Plataforma cujas ações de utilização para
        divulgação de espaço virtual e de serviços associados serão direcionadas
        para transações com Infoprodutos, mediante o uso das funcionalidades
        tecnológicas contidas na Plataforma Celetus; divulgando Infoprodutos e
        Materiais disponibilizados por determinado Criador de Conteúdo, em
        sites, blogs, páginas e comunidades em redes sociais etc., e recebendo
        honorários ou contraprestação pela cessão de espaço virtual se alguma
        negociação for concretizada em razão de suas ações promocionais conforme
        registro identificador desse Afiliado dentro da Plataforma Celetus, sem
        prejuízo de Programa de Afiliados a ser realizado para esse fim.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Comprador
        </Typography>
        : qualquer pessoa, física ou jurídica, pagadora, que adquira
        Infoprodutos na Plataforma Celetus, ofertados por Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Criador de Conteúdo
        </Typography>
        : titular e/ou co-titular de direitos de propriedade intelectual de
        Infoprodutos, ambos responsáveis solidários por estes Termos, que
        utilizam ferramentas tecnológicas para viabilizar a comercialização de
        Infoprodutos e/ou gestão e comercialização de reservas para eventos
        presenciais.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Infoproduto
        </Typography>
        : qualquer conteúdo, nos mais variados formatos digitais produzidos (nos
        formatos de vídeo, áudio, texto, software, serviços equiparados, livro,
        curso e eventos etc.), que sejam disponibilizados para a
        comercialização, abrangendo também serviços acessórios e/ou
        independentes do conteúdo criado.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Material
        </Typography>
        : qualquer conteúdo perceptível aos sentidos em diversos formatos
        digitais (vídeo, áudio, texto, software, serviço e eventos),
        disponibilizado pelo Criador de Conteúdo para comercialização mediante o
        uso das ferramentas tecnológicas contidas na Plataforma Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Meios de Pagamento
        </Typography>
        : Instrumentos de Pagamento, Boleto de Pagamento ou outros instrumentos
        ou formas de pagamento que venham a ser aceitos para a realização de
        Transações.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Plataforma Celetus ou Plataforma
        </Typography>
        : plataforma de propriedade da Celetus para a reunião de Compradores de
        Infoprodutos e Criadores de Conteúdo, com o fornecimento de
        funcionalidades diversas (financeira, gestão, publicitária e
        audiovisual, entre outras), disponibilizando todas as condições para que
        os Criadores de Conteúdo possam oferecer seus Infoprodutos e/ou serviços
        aos consumidores de Infoprodutos. Propriedade Intelectual: todos e
        quaisquer direitos da Celetus relacionados a patentes, modelo de
        utilidade, desenho industrial, direitos autorais, marca, trade dress,
        direitos sobre novas criações, segredos industriais, software, circuitos
        integrados, nomes de domínio e todos os outros direitos relacionados a
        direitos autorais, propriedade industrial e direitos de exclusividade
        sobre intangíveis de qualquer tipo, em qualquer jurisdição (incluindo
        qualquer pedido de registro destes), abrangendo inclusive invenções,
        ideia exteriorizada, conceito exteriorizado, descoberta,
        desenvolvimento, pesquisa, tecnologia, obra intelectual, segredo
        industrial, software e/ou seu código-fonte e seu código objeto,
        firmware, conteúdo, material audiovisual, ferramentas, processos,
        técnicas, know-how ou conhecimento técnico, dados, planos, dispositivos,
        aparelhos, especificações, desenhos, protótipos, circuitos, layout,
        novas invenções, algoritmos, programas, códigos, documentação ou
        qualquer outro material ou informação tangível ou intangível, tenha essa
        sido protegida ou não como patente, direitos autorais, marcas ou de
        qualquer outra forma protegida (incluindo todas as suas versões,
        modificações, melhorias e obras derivadas toda invenção, descoberta,
        aprimoramento, invento, processo, software, modelo de utilidade, obra de
        arte e outras obras protegidas pelo direito de autor, desenho industrial
        e/ou marca, em especial, mas não se limitando, aos intangíveis objetos
        de proteção da Lei n. 9.279, de 14 de maio de 1996 (Lei de Propriedade
        Industrial), das Leis n. 9.609 (Lei de Propriedade Intelectual do
        Programa de Computador) e da Lei n 9.610, de 19 de fevereiro de 1998
        (Lei de Direitos Autorais).
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Perda
        </Typography>
        : todas e quaisquer perdas, obrigações, demandas, passivos, exigências,
        constrições, danos, multas, penalidades, prejuízos, ônus, desembolsos,
        custos ou despesas, incluindo danos diretos, danos indiretos, danos
        emergentes, danos morais e/ou lucros cessantes, honorários advocatícios
        e de outros especialistas (inclusive de peritos e assistentes técnicos),
        verbas de sucumbência, bem como custas judiciais ou quaisquer juros,
        sejam eles já materializados ou futuros, incorridos pela Celetus e/ou
        terceiros, decorrentes, direta ou indiretamente, do objeto deste Termos
        de Uso, bem como do (<LinkCelestusTerm />) descumprimento de obrigações
        assumidas pelo Criador de Conteúdo perante terceiros ou no âmbito de sua
        atividade, independente do período em que ocorra (antes ou após o
        término da contratação), incluindo, sem limitação, aquelas decorrentes
        de multas, penalidades, reclamações, processos administrativos,
        extrajudiciais e judiciais ajuizados por terceiros e/ou ocasionadas por
        ato oriundo do Criador de Conteúdo, independente de culpa ou dolo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Recebíveis
        </Typography>
        : os direitos creditórios presentes ou futuros relativos a obrigações de
        pagamento de instituições de pagamento, como a Contratada, ao Cliente,
        constituídos ou não no âmbito de arranjo de pagamento.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Taxas
        </Typography>
        : preço pela disponibilização de funcionalidades da Plataforma da
        Celetus, assumindo a modalidade de royalties por licenciamento de uso,
        de preço ou comissão, a depender das funcionalidades utilizadas pelo
        Usuário, conforme página em link constante na seção “Taxas, Gestão de
        Pagamentos e Cancelamentos”.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Transação
        </Typography>
        : operação em que o Criador de Conteúdo aceita o Meio de Pagamento para
        pagamento pelo Infoproduto.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Usuário
        </Typography>
        : qualquer pessoa, física ou jurídica, que se cadastre na Plataforma
        Celetus, independentemente do fato de ter feito ou não o seu cadastro
        como Criador de Conteúdo, Afiliado ou Comprador.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        <Typography variant="body1" fontWeight={600} display="inline">
          Valor Bruto
        </Typography>
        : o valor total das transações realizadas pelo Criador de Conteúdo antes
        da dedução da Taxa e quaisquer outros descontos devidos à Contratada ou
        terceiros no âmbito ou em decorrência destes Termos de Uso.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        2- REGRAS DE ACESSO À PLATAFORMA
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.1. Requisitos para o cadastro de Usuários. As pessoas físicas que
        desejem se cadastrar como Usuários devem possuir a idade mínima de 18
        anos de idade ou ser emancipado. Isto é, consoante o ordenamento
        jurídico pátrio, ser capaz de direitos e deveres na ordem civil.
        Portanto, quem quer que aceite termos e condições gerais de uso desta
        Plataforma declara-se plenamente capaz. Ademais, na hipótese de pessoa
        jurídica, o respectivo representante declara poder vinculá-la
        regularmente à Plataforma, tendo obtido todas as autorizações
        corporativas para tanto, sob de responsabilidade pessoal com indenização
        por perdas e danos a serem apuradas judicialmente, bem como, Termos de
        uso – Celetus sendo Criador de Conteúdo, obriga-se a conceder à Celetus
        as permissões e licenças necessárias ao devido uso, venda e
        disponibilização de seus Infoprodutos.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.2. Requisitos para o cadastro de Compradores. Para o cadastro de
        Compradores na plataforma, a idade mínima necessária é de 15 anos. A
        efetuação do cadastro e a aceitação dos Termos de Uso por adolescente
        entre 15 e 18 anos implica que o assentimento dos pais ou tutores foi
        concedido de modo que os atos posteriormente praticados nas referidas
        contas também se presumem por eles assentidos. Tendo isso em vista, a
        Celetus poderá cancelar as contas de menores de 18 anos não emancipados
        a pedido de pais ou tutores, porém os produtos adquiridos no ínterim da
        vigência do cadastro não estão sujeitos ao reembolso ou a indenização de
        qualquer natureza.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.3. Possibilidade de Cancelamento ou Recusa do Cadastro. A Celetus se
        reserva o direito de cancelar, suspender ou recusar o cadastro de
        qualquer Usuário na hipótese da existência de condenação, já transitada
        em julgado, por crimes hediondos ou hediondos por equiparação, por
        participação em associação ou em organização criminosa, por qualquer
        envolvimento com tráfico de drogas ou de pessoas, ou ainda em crimes
        cibernéticos ou outros quaisquer cometidos no ambiente virtual, por má
        reputação que manche a honra da Celetus perante o público geral,
        incluindo potenciais Compradores, Criador de Conteúdo,
        empregados/colaboradores e/ou Afiliados, por violação aos Termos de Uso
        da Plataforma, bem como por desinteresse comercial, nos termos das
        cláusulas 10.1.11 e 10.1.19 destes Termos.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.4. Cancelamento, Suspensão ou Encerramento do Cadastro. Na ocorrência
        de cancelamento, suspensão ou encerramento do cadastro de Usuário na
        plataforma, o registro de nova conta ou o uso de conta de terceiro lhe é
        vedado, salvo se autorizado pela Celetus, a seu exclusivo critério.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.5. Informações e Cadastro de Usuário. O Usuário obriga-se a preencher
        adequadamente os dados requisitados para o cadastro na plataforma,
        prestando as informações pedidas com a mais estrita veracidade e boa-fé.
        As informações dadas devem ser suficientes para permitir a identificação
        do titular da conta. Caso contrário, averiguando-se informações falsas
        ou omissas, o cadastro poderá ser suspenso ou cancelado. Para todos os
        efeitos, a Celetus, por meio da aceitação dos Termos de Uso, pode
        recusar qualquer cadastro que carecer de dados verossímeis, bem como
        requisitar, a qualquer momento, dados adicionais ou para confirmar as
        informações prestadas.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.6. Sobre a Autenticidade dos Dados e Responsabilidade do Usuário. O
        Usuário, ao concordar com os Termos de Uso, reconhece ser o único
        responsável pelas informações prestadas no cadastro,
        responsabilizando-se também pelas eventuais consequências advindas de
        informações incorretas, falsas ou inexatas. Portanto, aconselha-se a
        devida atenção no momento do cadastro, e a conferência das informações
        prestadas.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.7. Responsabilidade pelos Dados de Acesso e Atividades da Conta. O
        Usuário é o único responsável pelos dados de acesso e por todas as
        atividades desenvolvidas na conta. Por conseguinte, a Celetus não pode
        ser responsabilizada por danos de qualquer natureza advindos do uso da
        conta pelo Usuário ou por terceiros que venham a utilizá-la. É o Usuário
        responsável por manter o login e a senha de sua conta em sigilo. Como
        esses dados são pessoais e intransferíveis, o Usuário não pode
        compartilhá-los com terceiros. Além disso, nenhum representante ou
        preposto da Celetus possui autorização para exigir a senha ou o login de
        qualquer Usuário. Inclusive, essas informações não podem ser
        compartilhadas com outros Usuários da Plataforma, nem Compradores,
        Produtores ou Afiliados. Caso se verifique furto ou extravio de qualquer
        dado essencial para o acesso e uso da conta, de modo a permitir o uso
        por terceiros, incluindo qualquer um que não seja o titular da conta, o
        Usuário deve notificar a Celetus para que sejam tomadas as medidas de
        segurança cabíveis conforme o caso.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.8. Dados Bancários dos Criadores de Conteúdo e Afiliados. Os Criadores
        de Conteúdo e Afiliados, a fim de receber os valores das transações
        efetuadas por meio da Plataforma da Celetus, devem indicar a conta
        corrente de sua titularidade que conste no mesmo domicílio de seu
        cadastro. A conta informada deve ser da titularidade do respectivo
        Usuário, sendo proibido o cadastro de conta de terceiro. Os valores
        recebidos não podem ser repassados para conta de terceiros ou para conta
        com domicílio diferente da que consta no seu cadastro. Ressalte-se que o
        Afiliado deverá realizar o cadastro como Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        2.9. Processamento dos Dados Bancários e outras informações. O
        processamento dos dados bancários e das demais informações prestadas é
        regido pelo DPA e Política de Privacidade da Celetus.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        3- DA PLATAFORMA CELETUS
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        3.1. Da Plataforma Proprietária. A Celetus atua como marketplace de
        conteúdo educacional/audiovisual com soluções de tecnologia para
        Criadores de Conteúdo interessados em divulgar Materiais e Infoprodutos
        em sua Plataforma. Para tanto, a Celetus cobra percentual e/ou
        remuneração fixa diretamente do Criador de Conteúdo pelo licenciamento
        do Software Celetus, bem como percentual e/ou remuneração pela
        intermediação das soluções de pagamento disponibilizadas pela Celetus do
        Criador de Conteúdo e/ou do Comprador, soluções essas que possibilitam a
        aquisição imediata de Infoprodutos.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        3.2. Não-Responsabilização por Resultados. Os Materiais e Infoprodutos
        disponibilizados na Plataforma são fornecidos exclusivamente pelos
        Criadores de Conteúdo, sendo a Celetus fornecedora do software que
        permite a disponibilização dos Infoprodutos e Materiais aos Compradores.
        Nesse sentido, a Celetus não se responsabiliza por resultados, adequação
        aos fins almejados pelo Comprador e a veracidade das informações
        contidas nos Infoprodutos, tal decisão deve ser realizada pelo Comprador
        no momento de aquisição e ao longo de toda a utilização do Infoproduto,
        os quais deverão sempre respeitar a Política de Conteúdo desta
        Plataforma.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        3.3 Não ingerência sobre Ofertas. A Celetus disponibiliza aos Criadores
        de Conteúdo funcionalidades financeiras e de hospedagem de seus
        infoprodutos. Assim, a plataforma não tem ingerência sobre a forma com
        que os Criadores de Conteúdo ofertam ou sobre a qualidade dos
        Infoprodutos. A Celetus não endossa, garante ou assume qualquer
        responsabilidade em relação às ofertas dos Infoprodutos cadastrados.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        3.4. Inacessibilidade temporária da Plataforma. Em razão das
        particularidades do ambiente em nuvem, a Celetus não poderá garantir que
        a Plataforma fique disponível e acessível ininterruptamente, sendo
        possível que se restrinja a disponibilidade da Plataforma ou de certas
        áreas ou recursos a ela relacionados, se necessário, considerando os
        limites de capacidade, a segurança ou a integridade de seus servidores,
        bem como para realizar medidas de manutenção ou aprimoramento dos seus
        serviços. Em ocorrendo tais situações, excluir-se-á a responsabilidade
        da Celetus, a teor do art. 393, do Código Civil.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        4- DO CONTEÚDO DOS INFOPRODUTOS DISPONIBILIZADOS
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        4.1. Conteúdo Proibido e Política de Conteúdo. Ao utilizar a Plataforma
        Celetus, o Usuário concorda e consente que o conteúdo de Infoprodutos e
        Materiais deverá obedecer estritamente a Política de Conteúdo, sob pena
        de aplicação das sanções previstas nestes Termos, sem prejuízo de
        medidas judiciais cabíveis.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        4.2. Serviços Equiparados ou Equiparáveis a Infoprodutos. Na Política de
        Conteúdo a Celetus prevê espécies de Serviços que podem – ou não – ser
        equiparados a Infoprodutos autorizados para disponibilização na
        Plataforma.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        5- REGRAS APLICÁVEIS AO Criador de Conteúdo
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        5.1. Conhecimento das Funcionalidades da Plataforma. O Criador de
        Conteúdo, ao aderir a estes Termos de Uso, declara e consente que se
        muniu de todas as informações disponíveis a respeito das funcionalidades
        da Plataforma e da Celetus, sendo a tecnologia licenciada pela Celetus
        apenas meio para que o Infoprodutos possam ser diretamente adquiridos
        pelos Compradores, podendo inclusive disponibilizar seu Infoproduto para
        divulgação e promoção.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        5.2. Requisitos para comercialização de Infoprodutos. O Criador de
        Conteúdo deverá:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.1. Registrar-se na Plataforma como um Criador de Conteúdo,
        responsabilizando-se pelo uso apropriado das ferramentas contidas na
        Plataforma, bem como por buscar o esclarecimento necessário através dos
        canais disponíveis na Plataforma, em caso de dúvidas;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.2. Aceitar e respeitar os Termos de Uso da Celetus;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.3. Ser o autor do Infoproduto ou ter autorização do titular de
        direitos autorais sobre o Infoproduto para comercializá-lo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.4. Respeitar todas as indicações da Política de Conteúdo integrantes
        destes Termos de Uso, abstendo-se de tentar inserir na Plataforma
        Conteúdo Proibido, reconhecendo ser o único responsável pelo conteúdo de
        seus Infoprodutos e respectivas transações
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.5. Cadastrar Infoproduto na Plataforma fornecendo todas as
        informações solicitadas a seu respeito, tais como: preço de oferta do
        Infoproduto e das formas de pagamento (inclusive dados bancários),
        regras de aceitação e comissão dos Afiliados, prazo de reembolso (7 a 30
        dias), podendo alterar tais informações a qualquer momento, sem
        necessidade de aviso prévio, mas responsabilizando-se perante Afiliados
        e Compradores do Criador de Conteúdo a respeitar as informações ali
        constantes no ato de cada operação realizada;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.6. Aguardar até 5 (cinco) dias para que o Infoproduto fique
        disponível para divulgação e comercialização na Plataforma, eis que o
        Infoproduto poderá passar por uma análise de conformidade com estes
        Termos de Uso e com a Política de Conteúdo e, se não estiverem
        adequados, não serão divulgados;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.7. Aceitar, segundo seus critérios, os pedidos de afiliação para seu
        Infoprodutos, comprometendo- se à comercialização do Infoproduto e ao
        pagamento da comissão diretamente devida pela divulgação promovida pelos
        Afiliados exclusivamente pelas funcionalidades disponibilizadas na
        Plataforma Celetus;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.8. Cumprir as regras de comissão devidas a Afiliados que tenham
        realizado a promoção e venda do Infoproduto, e comunicar previamente os
        Afiliados, caso decida cancelar o programa de afiliação para o
        Infoproduto;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.9. Responsabilizar-se exclusiva e diretamente perante os Afiliados
        que se sintam prejudicados pelo não recebimento de comissões em razão do
        uso de meios fraudulentos, isentando a Plataforma de tais
        responsabilidades, desde já se autorizando o chamamento do Criador de
        Conteúdo a processos eventualmente movidos contra a Celetus pelo
        Afiliado;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.10. Responsabilizar-se por prestar informações claras e objetivas
        sobre os seus Infoprodutos, mantendo-as atualizadas e compatíveis com
        aquelas divulgadas em seus websites, páginas de vendas e áreas de
        membros externas à Plataforma, bem como responsabilizando-se perante o
        Comprador por eventuais divergências, especialmente no tocante aos
        preços do Infoproduto, sua qualidade, descrição, adequação ao objetivo a
        que se propõe e aos prazos de cancelamento.;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.11. Responsabilizar-se pela entrega do Infoproduto ao Comprador,
        especialmente se realizado por meio de posts e áreas de membros externos
        à Plataforma, bem como por garantir que o Comprador possa utilizar o
        Infoproduto da maneira como foi ofertado; responsabilizando-se pelo
        cumprimento integral de todas as obrigações legais relacionadas às
        vendas realizadas com o uso das ferramentas contidas na Plataforma;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.12. Respeitar o prazo de cancelamento da compra dentro de 07 dias ou
        outro prazo (não inferior a 07 dias) que tenha indicado no cadastro do
        Infoproduto, comprometendo-se a realizar o estorno do valor solicitado
        pelo cliente, observado o disposto na cláusula
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.13. Responsabilizar-se por solucionar quaisquer problemas, em
        especial de origem técnica, que ocorrerem com o Infoproduto após a
        entrega ao Comprador, assumindo a obrigação de fornecer suporte adequado
        ao Comprador sempre que solicitado por este ou pela Celetus, bem como
        responder aos eventuais contatos da equipe de suporte da Plataforma
        Celetus, viabilizando o adequado atendimento aos Compradores;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.14. Responsabilizar-se por alterar, sempre que necessário, as
        informações relativas ao Infoproduto, assim como por suspender ou
        cancelar sua divulgação sempre que deixar de atender a qualquer das
        condições destes Termos de Uso, sem prejuízo de permanecer responsável
        por todas as obrigações decorrentes do período de divulgação;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.15. Quando se tratar de Criadores de Conteúdo explorando em conjunto
        Infoprodutos (coprodutores), todos os participantes se responsabilizam
        pela correta determinação dos critérios para divisão do resultado da
        exploração conjunta de Infoprodutos, determinados no dashboard da
        Celetus, desde já se responsabilizando por indenizar por perdas e danos
        a Celetus por qualquer Perda decorrente dos critérios de rateio
        escolhidos.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.16. Arcar com as Taxas decorrentes do uso das funcionalidades
        contidas na Plataforma, e arcar com suas obrigações legais,
        regulatórias, fiscais e tributárias, se, quando e onde existentes.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        5.2.17. Reconhecer que é inteira e exclusivamente responsável pelo
        Infoproduto, incluindo, mas não se limitando, às informações,
        características, qualidades, quantidade, descrição, adequação do
        conteúdo ao objetivo proposto, composição, preço, garantia, prazos de
        validade e entrega.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        5.3. Responsabilidade Integral do Criador de Conteúdo por Perdas. O
        Criador de Conteúdo desde já se responsabiliza por qualquer Perda, caso
        a Celetus venha a responder, através de seu suporte, administrativa ou
        judicialmente por Infoproduto gerado pelo Criador de Conteúdo e/ou
        qualquer fato derivado da Transação. Os valores despendidos pela Celetus
        poderão, a seu exclusivo critério, ser abatidos das comissões, saldos
        contratuais e demais valores devidos ao Criador de Conteúdo responsável,
        até o limite necessário à recomposição dos custos incorridos pela
        Celetus em razão de ações, acordos extrajudiciais e judiciais firmados
        com terceiros, bem como condenações judiciais. Assim, o Criador de
        Conteúdo concorda e autoriza, desde já, a retenção e/ou cobrança dos
        referidos valores, pela Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        5.4. Direito de Regresso da Celetus. Ressalta-se que a Celetus, ao
        realizar qualquer tipo de acordo e/ou composições judiciais e
        extrajudiciais com terceiros, não estará assumindo, direta ou
        indiretamente, qualquer responsabilidade por Infoprodutos e Materiais
        ofertados pelo Criador de Conteúdo na Plataforma, preservando-se assim
        seu direito de regresso.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        6- LICENÇA DE USO DE SOFTWARE NÃO CUSTOMIZÁVEL
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        6.1. Objeto. A licença do direito de uso do software da Plataforma
        Celetus (“Software Celetus”) tem caráter não customizável, não
        exclusivo, incessível, oneroso e temporário e regulado pela Licença de
        Uso de Software Não- Customizável, que faz parte integrante destes
        Termos. O Software Celetus licenciado disponibiliza ao Criador de
        Conteúdo o acesso à Plataforma Celetus e a veiculação dos Infoprodutos
        pelo Criador de Conteúdo ao Comprador.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        6.2. Disponibilização. O Software Celetus será fornecido mensalmente por
        meio eletrônico, em cloud computing (computação na nuvem), sem mídias,
        para garantir que o Criador de Conteúdo tenha sempre a sua versão mais
        atualizada. Em nenhuma hipótese o Software Celetus será passível de
        download pelo Criador de Conteúdo, uma vez que a disponibilização de uso
        é em cloud computing.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        6.3. Licenciamento não-exclusivo. O Criador de Conteúdo reconhece e
        aceita que durante a vigência destes Termos de Uso e após o seu término,
        a Celetus poderá licenciar o Software Celetus a terceiros, inclusive
        para concorrentes do Criador de Conteúdo, em virtude do caráter não
        exclusivo desta licença.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        6.4. Estabelecimento Licenciador. O licenciamento do Software Celetus é
        disponibilizado e viabilizado ao Criador de Conteúdo pela unidade
        correspondente à sede da Celetus, no Município de Goiânia/GO, com a
        emissão mensal da correspondente documentação fiscal pela Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        6.5. Poderes Outorgados pelo Criador de Conteúdo para a Celetus. Sem
        prejuízo do disposto na cláusula anterior, o Criador de Conteúdo desde
        já nomeia e constitui a Celetus como sua mandatária, nos termos do art.
        653 et. seq. Do Código Civil, e lhe outorga os poderes necessários de
        representação para tomar todas e quaisquer providências relativas à
        gestão de Recebíveis gerados por ele na Plataforma da Celetus (inclusive
        com a gestão de agenda de recebíveis, por meio de cessão e/ou qualquer
        meio de disposição do crédito e/ou sub-rogação convencional, nos termos
        do art. 347, do Código Civil), incluindo, sem limitação, pactuar com os
        Compradores e/ou terceiros as operações previstas para recebimentos
        antecipados outorgando à Celetus, ademais, os poderes específicos para
        substabelecer à Contratada e a quaisquer terceiros os poderes outorgados
        por esta cláusula desde que com esta finalidade, comprometendo-se a
        obter todas as autorizações corporativas necessárias para a sua válida
        representação.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        7- CONDUTAS PERANTE O COMPRADOR DA CELETUS E DO Criador de Conteúdo
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        7.1. Responsabilidade do Criador de Conteúdo. O Criador de Conteúdo é o
        responsável exclusivo por todo o conteúdo dos Infoprodutos e Materiais
        disponibilizados ao Comprador, sendo que a Celetus não se obriga a fazer
        controle prévio (editorial ou de qualquer outra natureza) ou curadoria
        dos Infoprodutos, tão somente exigindo o respeito a regras mínimas
        previstas na Política de Conteúdo. Ao adquirir algum Infoproduto, o
        Comprador deve verificar a adequação do Infoproduto e do Criador de
        Conteúdo aos fins buscados por ocasião da compra, bem como a veracidade
        das demais informações e características prestadas em relação ao
        Infoproduto. O Comprador deve estar ciente que, ao adquirir um
        Infoproduto pela Plataforma, poderá estar sujeito a outras regras e
        termos aplicáveis em virtude de sua relação com o Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        7.2. Diligência sobre as Informações Prestadas. O Comprador é
        integralmente responsável por todas as informações que prestar e pelas
        declarações que fizer aos Criadores de Conteúdo ou a qualquer outra
        pessoa por ocasião do uso da Plataforma, em razão da compra ou em
        decorrência do uso de qualquer Infoproduto.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        7.3. Devolução em 7 (sete) dias. Em razão destes Termos de Uso, a
        Celetus exige que o Criador de Conteúdo garanta ao Comprador a devolução
        dos valores despendidos para a aquisição de Infoproduto, em caso de
        cancelamento da compra no prazo de 7 (sete) dias, contados da data do
        pagamento.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        7.4. Funcionalidade de Gestão Financeira. A Celetus como solução de
        tecnologia é a responsável em viabilizar os pagamentos do Criador de
        Conteúdo ao Comprador. No entanto, em especial para pagamentos
        parcelados, a Celetus poderá buscar junto a soluções de pagamento e
        outros fornecedores financeiros a viabilização de recursos para que o
        Comprador possa adquirir parceladamente e/ou outros meios, sendo essa
        atividade da Celetus passível de cobrança diretamente do Comprador e/ou
        do Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        7.5. Poderes Outorgados pelo Comprador e Mandato da Celetus Associado à
        Gestão de Pagamentos. O Comprador desde já nomeia e constitui a Celetus
        como seu mandatário, nos termos do art. 653 et. Seq. do Código Civil, e
        lhe outorga os poderes necessários para tomar todas e quaisquer
        providências relativas à gestão de pagamentos a serem efetuados pelo
        Comprador (inclusive por meio de pré-pagamento e/ou qualquer meio de
        disposição do crédito e/ou sub-rogação convencional, nos termos do art.
        347, do Código Civil), incluindo, sem limitação, pactuar com o Criador
        de Conteúdo e/ou terceiros, podendo para tanto cobrar comissão pelos
        serviços prestados ao Comprador ao viabilizar os pagamentos pela
        Plataforma.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        8- REGRAS APLICÁVEIS AO AFILIADO
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        8.1. Requisitos para Participação em Programa de Afiliados. Para
        divulgar e promover Infoproduto incluído na Plataforma, o Afiliado
        deverá:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.1. Registrar-se na Plataforma como um Criador de Conteúdo e
        afiliar-se a outros Infoprodutos e/ou se tornar um co-produtor,
        responsabilizando-se pelo uso apropriado das ferramentas contidas na
        Plataforma, bem como por buscar o esclarecimento necessário através dos
        canais disponíveis na Plataforma em caso de dúvidas;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.2. Aceitar e respeitar os Termos de Uso e Políticas da Celetus e
        Documentos Conexos, sem prejuízo de regramentos específicos e
        orientações do Criador de Conteúdo;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.3. Candidatar-se para divulgar e promover determinado Infoproduto
        junto ao respectivo Criador de Conteúdo, utilizando-se do campo correto
        na Plataforma;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.4. Aceitar os valores e regras de divisão das comissões determinados
        pelo Criador de Conteúdo e informados na Plataforma no momento em que se
        cadastrar como Afiliado de um determinado Infoproduto, reconhecendo que
        o Criador de Conteúdo é o único responsável por definir o valor e a
        forma de dividir a comissão entre os diversos Afiliados que atuem na
        promoção do Infoproduto e que tais valores e regras podem ser alterados
        pelo Criador de Conteúdo a seu exclusivo critério e sem necessidade de
        aviso prévio, levando essa condição em conta ao decidir fazer qualquer
        investimento de tempo ou dinheiro na promoção de um Infoproduto;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.5. Utilizar as ferramentas contidas na Plataforma para permitir o
        rastreamento de sua atuação como Afiliado de determinado Infoproduto de
        seu interesse, sempre em conformidade com os Termos de Uso e Políticas
        da Plataforma;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.6. Responsabilizar-se por reproduzir ao público para o qual divulgar
        o Infoproduto as características do Infoproduto e as condições de venda
        inseridas pelo Criador de Conteúdo na Plataforma, assumindo
        solidariamente ao Criador de Conteúdo a responsabilidade pelo
        cumprimento integral de tais condições junto ao Comprador;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.7. Aceitar a regra de que as vendas realizadas com uso das
        ferramentas tecnológicas de rastreamento contidas na Plataforma gerarão
        comissões ao Afiliado, que serão devidas apenas após a finalização da
        venda e o efetivo pagamento pelo Comprador, e apenas poderão ser
        resgatados conforme regras internas da Plataforma, ora consentidas;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.8. Aceitar utilizar, como meio de recebimento de suas comissões,
        somente as plataformas de pagamentos cuja funcionalidade de “checkout”
        esteja integrada à Plataforma, considerando todas as regras e limitações
        daquelas plataformas, que podem ser verificadas em seus respectivos
        sites, responsabilizando-se por realizar o seu registro junto aos
        respectivos meios de pagamento;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        8.1.9. Arcar com as Taxas decorrentes do uso das ferramentas
        tecnológicas contidas na Plataforma, e arcar com suas obrigações legais,
        regulatórias, fiscais e tributárias, se, quando e onde existentes.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        9- TAXAS, GESTÃO DE PAGAMENTOS E CANCELAMENTOS
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.1. Modalidades de Gestão de Pagamentos e Funções. As funcionalidades
        financeiras referentes à gestão de pagamento na Plataforma Celetus
        correspondem às seguintes:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.1.1. O pagamento ou pré-pagamento pelos Compradores pela aquisição de
        Infoprodutos inseridos na Plataforma;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.1.2. O pagamento pelos Criadores de Conteúdo aos Afiliados dos valores
        devidos pelas Transações por estes realizadas; assim como o recebimento
        destes valores pelos Afiliados;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.1.3. O recebimento pelos Criadores de Conteúdo dos valores relativos à
        comercialização de seus Infoprodutos;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.1.4. O pagamento pela funcionalidade de coprodução com a divisão da
        receita de coprodução (“revenue-share”) do resultado das vendas de
        Infoprodutos, cujos critérios são definidos entre Criadores de Conteúdo
        no dashboard da Celetus;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.2. Poderes Outorgados à Celetus. No momento em que se cadastra na
        Plataforma, o Criador de Conteúdo concorda e outorga poderes nos termos
        da cláusula 6.5, autorizando que a Celetus promova:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.2.1. O recebimento do preço pago pelo Comprador;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.2.2. O pagamento, em nome e por conta do Criador de Conteúdo, das
        comissões estabelecidas ao Afiliado pelo Criador de Conteúdo, quando
        houver;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.2.3. A dedução da remuneração devida à Celetus pela utilização de suas
        ferramentas, bem como a dedução de remuneração de terceiros na cadeia de
        meios de pagamento contratados em nome e por conta do Criador de
        Conteúdo, para viabilizar os recebimentos na Plataforma Celetus;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        9.2.4. O pagamento do valor remanescente devido ao Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.3. Natureza dos Serviços Prestados. O Criador de Conteúdo declara-se
        ciente de que as funcionalidades financeiras, em especial de gestão de
        pagamentos contidas na Plataforma, não são e nem se destinam a ser
        comparáveis aos serviços financeiros oferecidos por instituições
        bancárias ou administradoras de cartão de crédito, consistindo apenas em
        uma forma de facilitação e acompanhamento da realização de transações
        para pagamentos e recebimentos entre os Usuários da Plataforma.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.4. Taxas da Celetus. O Criador de Conteúdo pagará as Taxas aplicáveis
        à Licença de Uso da Plataforma Celetus (“Taxas do Criador de Conteúdo”)
        e quaisquer outras taxas aplicáveis, incluindo, sem limitação, as taxas
        aplicáveis relacionadas ao valor das vendas feitas pelo Criador de
        Conteúdo ao usar todos os provedores de pagamento contratados pela
        Celetus em seu benefício (“Taxas de Transação”) e quaisquer taxas
        relacionadas às suas vendas ou ao uso de produtos ou serviços (“Taxas
        Adicionais”), constantes na política de pagamento na página: _____ (___)
        , os quais são parte integrante destes Termos de Uso.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.5. Ingressos e Receitas da Celetus. A receita da Celetus não inclui
        valores devidos ou repassados ao Criador de Conteúdo, ou qualquer outra
        receita de terceiros que por ela transite em razão de recebimento por
        conta e ordem de terceiros e/ou do Usuário. Só compõem a receita da
        Celetus os valores e taxas recebidas em razão das funcionalidades
        efetivamente disponibilizadas por ela aos Usuários.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.6. Valores Repassados a Terceiros. Os valores cobrados pela Celetus e
        dispostos nestes Termos constituem a integralidade dos serviços objeto
        do presente. Custos da operação e dos serviços prestados pela Celetus ou
        terceiros contratados em nome e por conta do Criador de Conteúdo
        integram o Valor Bruto, porém não compõem a receita tributável da
        Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.7. Responsabilidade Tributária do Criador de Conteúdo. O Criador de
        Conteúdo é o único responsável por determinar, recolher, reter, declarar
        e remeter tributos, tributos de importação, taxas, sobretaxas e
        cobranças adicionais aplicáveis que sejam decorrentes ou resultem de
        qualquer venda ou de seu uso dos Serviços. Qualquer contrato de venda
        realizado por intermédio dos Serviços é feito diretamente entre Criador
        de Conteúdo e o Comprador. De acordo com a legislação aplicável, cada
        Criador de Conteúdo é responsável pelo recolhimento dos
        impostos/retenções originados em razão das transações realizadas na
        Plataforma. Assim, o Criador de Conteúdo responsabiliza-se por emitir e
        encaminhar o documento fiscal referente à disponibilização do
        Infoproduto ao Comprador.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.8. Obrigações do Comprador: O Comprador deverá manter uma forma de
        pagamento válida no cadastro para pagar todas as Taxas incorridas e
        recorrentes. A Celetus cobrará as Taxas aplicáveis usando a forma de
        pagamento válida que o Comprador autorizar (“Forma de Pagamento
        Autorizada”), e a Celetus continuará a usar a Forma de Pagamento
        Autorizada para realizar a cobrança das Taxas aplicáveis até que os
        Serviços sejam encerrados e todas as Taxas pendentes tenham sido
        integralmente pagas. Salvo indicação em contrário, todas as Taxas e
        outras cobranças são indicadas em dólar norte-americano, e todos os
        pagamentos devem ser feitos na moeda norte-americana.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.9. Recebíveis. A Celetus poderá cobrar valores decorrentes de
        operações financeiras realizadas por Criadores de Conteúdo, como a
        antecipação de Recebíveis solicitada por Afiliado e, quando aplicável,
        do Criador de Conteúdo, ou o parcelamento ou uso de diferentes meios de
        pagamento por Comprador.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.10. Cancelamento de Compras. Para solicitar cancelamento da aquisição
        de Infoproduto, o Comprador deverá entrar em contato diretamente com o
        Criador de Conteúdo. Caso não tenha recebido retorno do Criador de
        Conteúdo ou não concorde com os termos do Criador de Conteúdo, o
        Comprador deverá ingressar com o seu pedido somente pelo link de
        reembolso automático (<LinkCelestusTerm />
        ), com a solicitação de reembolso automático, por intermédio do e-mail
        da conta. O reembolso poderá ser concluído em até 48h (quarenta e oito
        horas) úteis, desde que a solicitação de cancelamento esteja dentro do
        prazo de 7 (sete) dias após a compra, e que exista saldo disponível na
        conta do Criador de Conteúdo, desde já ressalvado o direito de regresso
        em face do Criador de Conteúdo pela Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        9.11. O Criador de Conteúdo autoriza que a Celetus realize o reembolso,
        mediante dedução do valor dos créditos existentes em sua conta, (I)
        quando o Comprador solicita o reembolso diretamente à Plataforma, dentro
        do prazo de 07 dias da aquisição. Nestas hipóteses, caso o Criador de
        Conteúdo não possua saldo disponível na Plataforma, ele compromete-se a
        proceder com o estorno do valor da compra tão logo solicitado pela
        Plataforma, sob pena de incorrer em violação dos Termos de Uso.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        10- RESPONSABILIDADE DOS USUÁRIOS E DA CELETUS
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        10.1. Responsabilidade das Partes. A responsabilidade dos Usuários e da
        Celetus deverá seguir as seguintes regras:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.1. Os documentos, informações, imagens e gráficos publicados na
        Plataforma podem conter imprecisões técnicas ou erros tipográficos. Em
        nenhuma hipótese a Celetus e/ou seus respectivos fornecedores serão
        responsáveis por qualquer dano direto ou indireto decorrente da
        impossibilidade de uso, perda de dados ou lucros, resultante do acesso e
        desempenho da Plataforma, dos serviços oferecidos ou de informações na
        Plataforma. O acesso aos serviços, materiais, informações e facilidades
        contidas neste website não garantem a sua qualidade.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.2. Os Materiais são fornecidos na Plataforma sem nenhuma garantia
        explícita ou implícita de comercialização ou adequação a qualquer
        objetivo específico. Em nenhum caso a Celetus ou os seus colaboradores
        serão responsabilizados por quaisquer danos, incluindo lucros cessantes,
        interrupção de negócio, ou perda de informação que resultem do uso ou da
        incapacidade de usar os materiais. A Celetus não garante a precisão ou
        integridade das informações, textos, gráficos, links e outros itens dos
        materiais.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.3. A Celetus não se responsabiliza pelo conteúdo dos Infoprodutos,
        dos artigos e informações publicadas na Plataforma, uma vez que são de
        autoria de terceiros e não traduzem, necessariamente, a opinião da
        Celetus.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.4. A Celetus tampouco é responsável pela violação de direitos
        autorais decorrente de informações, documentos e materiais publicados na
        Plataforma, comprometendo-se, no entanto, a retirá-los do ar assim que
        recebida notificação formal da infração e após efetuada a pertinente
        análise da denúncia, podendo inclusive ouvir a parte reclamada, a seu
        exclusivo critério, caso julgar não haver elementos suficientes para a
        suspensão imediata do conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.5. Considerando que a Plataforma não é a criadora do Infoproduto
        produzido e publicado pelo Criador de Conteúdo, e que não há controle
        editorial prévio dos Infoprodutos, a Celetus, nos termos da legislação
        aplicável, não é responsável pelos Infoprodutos divulgados e nem pelos
        danos por eles causados.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.6. Com isso, a Celetus oferece mecanismos eficientes para que
        Criadores de Conteúdo que entendem ter tido algum direito seu violado
        possam fazer denúncias e busquem resolver suas disputas, conforme abaixo
        indicado.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.7. Sem prejuízo do já previsto nestes Termos de Uso, a Celetus é
        responsável pelos dados de cadastro dos Criadores de Conteúdo, bem como
        pelos dados relativos às transações realizadas com as ferramentas
        tecnológicas contidas na Plataforma nos termos da legislação aplicável.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.8. A Celetus não se responsabilizará por erros na divulgação de
        Infoproduto com a utilização das ferramentas contidas na Plataforma, que
        possam dificultar a venda de tal Infoproduto.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.9. As Taxas e os meios de pagamento poderão ser alterados conforme
        estratégia da Celetus, a seu exclusivo critério, sendo cobrados a partir
        da data de atualização de cobrança, sem prejuízo de repasse por Perdas
        referentes a recebimentos pelo Criador de Conteúdo;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.10. A Celetus não é responsável pela qualidade dos Infoprodutos,
        mas recomenda que os Criadores de Conteúdo prezem pela qualidade dos
        Infoprodutos oferecidos na Plataforma.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.11. Os conteúdos incluídos na Plataforma apenas permanecerão
        disponibilizados para divulgação se estiverem de acordo com estes Termos
        de Uso. A Celetus reserva-se o direito de remover qualquer Infoproduto
        que não esteja de acordo com suas normas e políticas.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.12. Os Criadores de Conteúdo e Afiliados concordam que serão
        exclusivamente responsáveis por obter quaisquer autorizações
        regulatórias necessárias (tais como licenças, concessões, permissões
        e/ou qualquer outro título permissivo do poder público e delegatários)
        ou decorrentes da regulamentação de sua atividade profissional, tanto
        para si próprios quanto para os Infoprodutos oferecidos através do uso
        da Plataforma. Tais permissões ou autorizações devem ser obtidas antes
        de qualquer oferta de Infoprodutos ou divulgação de Materiais, sob pena
        de violação destes Termos de Uso.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.13. A Celetus não é responsável por eventuais contestações,
        chargebacks ou bloqueios de compra/pagamento de qualquer natureza por
        parte do Comprador do Criador de Conteúdo, e deduzirá a qualquer tempo
        do saldo do Criador de Conteúdo valores referentes a transações
        reembolsadas ou bloqueadas, ou transações contestadas (chargeback);
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.14. Caso não haja saldo suficiente na conta do Criador de Conteúdo
        para satisfazer o prejuízo causado à Celetus ou a terceiros e a Celetus
        tome as ações legais cabíveis para buscar o ressarcimento, o Criador de
        Conteúdo concorda, desde já, com o deferimento de medida cautelar para o
        bloqueio de tantos bens quanto bastem para satisfação do prejuízo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.15. Na hipótese de o Criador de Conteúdo se caracterizar como
        pessoa jurídica, a responsabilidade por danos e prejuízos causados à
        Celetus será solidária entre o Criador de Conteúdo e as pessoas físicas
        controladoras e administradores deste Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.16. A Celetus tomará todas as medidas possíveis para manter a
        confidencialidade e a segurança das informações dos Criadores de
        Conteúdo, mas não poderá ser considerada responsável por perdas ou danos
        derivados da violação de tais medidas por terceiros que usarem redes
        públicas ou a Internet com o propósito de subverter o sistema de
        segurança e/ou ter acesso a informações confidenciais de Criador de
        Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.17. Os Criadores de Conteúdo e Afiliados concordam que a Celetus
        poderá, a seu exclusivo critério, realizar o bloqueio preventivo da
        conta e respectivos valores nela existentes, sempre que tomar
        conhecimento de qualquer indício de ato ou conduta que possa
        caracterizar prática ilegal, violação aos Termos de Uso, ou que
        represente infração a direitos de Criador de Conteúdo ou terceiros, bem
        como quando for demandada judicialmente em razão de fato relacionado
        direta ou indiretamente a disponibilização e/ou ao conteúdo do
        Infoproduto.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.18. Considerando que a Celetus não é responsável pelo conteúdo
        gerado pelos Criadores de Conteúdo ou Afiliados em sua Plataforma, o
        bloqueio preventivo será mantido, em regra, até que (i) constate-se que
        o ato ou conduta suspeita não caracterizou prática ilegal, com base em
        critérios e avaliação exclusiva da Celetus; (ii) as partes envolvidas
        venham a formalizar adequadamente a resolução amigável da questão; ou
        (iii) por ordem judicial ou determinação de autoridades administrativas
        que contenha identificação clara e específica do conteúdo ou ato
        apontado como infringente, permitindo sua adequada identificação e
        adoção das medidas requeridas com a baixa do respectivo processo
        judicial que acarretou o bloqueio cautelar de valores Caso reste
        confirmado a natureza ilegal da conduta, o bloqueio preventivo será
        convertido em definitivo, sendo que os valores eventualmente existentes
        na conta apenas serão liberados a quem de direito e mediante ordem
        judicial.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.19. Ressalta-se que o bloqueio preventivo citado acima não
        caracteriza aplicação de penalidade aos Criadores de Conteúdo, por se
        tratar de uma medida cautelar adotada com o único objetivo de buscar
        evitar a ocorrência de danos, prejuízos ou infrações a direitos dos
        Criadores de Conteúdo e de terceiros.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        10.1.20. Os Criadores de Conteúdo e Afiliados concordam que a Celetus
        poderá, a seu exclusivo critério, encerrar a relação com Criadores de
        Conteúdo e Afiliados por desinteresse comercial. Na hipótese de
        desinteresse comercial, a Celetus transferirá os valores da conta do
        Criador de Conteúdo a outra conta por ele indicada e de sua
        titularidade, retendo os valores necessários para saldar eventuais
        chargebacks de transações já efetuadas.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        11- PROPRIEDADE INTELECTUAL DA CELETUS
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        11.1. Uso da Propriedade Intelectual da Celetus. Toda a Propriedade
        Intelectual da Celetus, incluindo o Software Celetus, são de
        titularidade exclusiva da Celetus e/ou de seus parceiros ou
        fornecedores, inclusive quaisquer aprimoramentos, correções, traduções,
        alterações, novas versões ou obras derivadas, entre outras que o
        modificarem ou o alterarem.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        11.2. Penalidades. Qualquer violação à Propriedade Intelectual da
        Celetus, com a ocorrência das hipóteses previstas na cláusula anterior
        acarretará ao Criador de Conteúdo a aplicação de multa equivalente a 60
        (sessenta) vezes o valor mensal do produto assinado, no caso de
        Comprador, e dos Infoprodutos comercializados, no caso Criador de
        Conteúdo, desde já se autorizando o seu desconto em Recebíveis e saldos
        do Usuário perante a Plataforma, na ocorrência de tais violações. A
        multa não obstará indenização suplementar a ser apurada judicialmente.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        11.3. Vedação de Uso. Os Usuários comprometem-se a não utilizarem
        indevidamente qualquer Propriedade Intelectual da Celetus, tais como,
        mas não se limitando ao nome empresarial, nomes de domínio, títulos de
        estabelecimento, marcas depositadas ou registradas, bem como quaisquer
        outros sinais distintivos da outra parte, incluindo, mas não se
        limitando a desenhos ou sinais registráveis ou não e aspectos visuais da
        plataforma, sem prévia autorização da parte envolvida, por escrito, que
        poderá ser geral para os atos elencados, sendo vedado a qualquer Usuário
        utilizar-se de Propriedade Intelectual sem autorização.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        11.4. Autorização Expressa. Qualquer autorização emitida para o uso de
        Propriedade Intelectual da Celetus será concedida em caráter precário,
        sempre por escrito e exclusivamente para a finalidade apontada na
        referida autorização.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        12- RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITOS AUTORAIS
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        12.1. Propriedade Intelectual de Terceiros. A Celetus respeita a
        propriedade intelectual de outras pessoas ou empresas, e solicitamos aos
        nossos membros que façam o mesmo. Toda e qualquer violação de direitos
        autorais deverá ser notificada à Celetus e acompanhada dos documentos e
        informações que confirmam a autoria. A notificação poderá ser enviada
        pelos e- mails constantes da Plataforma ou via postal para o seguinte
        endereço: _______
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        12.2. Suspeita de Violação pelo Criador de Conteúdo. Uma vez enviada a
        reclamação, e dentro do prazo de cancelamento do Infoproduto, tendo em
        vista que o Criador de Conteúdo continua sendo responsável pelos
        aspectos técnicos e de qualidade do Infoproduto gerado, a Celetus
        comunicará o Criador de Conteúdo em questão e concederá o prazo de até
        10 (dez) dias corridos para que ele responda a reclamação com uma
        justificativa ou tentativa de sanar o problema, período pelo qual a
        Celetus poderá cautelarmente adotar as medidas restritivas que entenda
        adequadas. Caso o Criador de Conteúdo não entre em contato neste prazo,
        a solicitação de reembolso poderá ser executada de forma automática,
        além de poderem ser fornecidos dados do Criador de Conteúdo ao Comprador
        do Criador de Conteúdo, conforme Política de Privacidade.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        12.3. Requerimento e conclusão. Caso alguém entenda que um Infoproduto
        inserido na Plataforma viola seus direitos de propriedade industrial
        (marca, patente, desenhos industriais) ou seus direitos autorais, poderá
        notificar a Celetus, por meio de requerimento encaminhado ao _______,
        indicando a URL em que o Infoproduto em questão se encontra, anexando ao
        formulário a comprovação de ser o titular do direito violado ou seu
        representante legal, via documentos juridicamente adequados para
        comprovar sua titularidade sobre o Infoproduto. Após averiguação
        interna, a Celetus se pronunciará formalmente indicando sua posição
        sobre a disputa, nos termos da cláusula.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        13- CONFIDENCIALIDADE
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        13.1. Informação confidencial. As Partes, por si, seus funcionários,
        assessores ou prepostos manterão, inclusive pelo período de cinco anos
        após o fim da vigência destes Termos de Uso, já na fase pós-contratual,
        o mais completo e absoluto sigilo sobre as cláusulas aqui contratadas,
        dados, materiais, pormenores, informações, documentos, volume de
        operações mensais, especificações técnicas e comerciais que
        eventualmente venham a ter conhecimento ou acesso, ou que lhe venham a
        ser confiados, em função destes Termos de Uso, de eventual aditivo ou da
        proposta comercial, salvo em atendimento e na extensão exigida por
        eventual ordem judicial. Neste caso, a Parte comunicará a outra
        imediatamente, para que a esta seja dada a oportunidade de se opor à
        revelação.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        13.1.1. Em caso de violação da cláusula 13.1, caberá indenização por
        perdas e danos a ser apurada judicialmente.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        14- MEDIDAS PREVENTIVAS E SANÇÕES
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.1. Medidas. Qualquer conduta contrária ao previsto nestes Termos de
        Uso e Documentos Conexos poderá ser punida: (a) Com a remoção de
        determinado Infoproduto; (b) Com a suspensão imediata do acesso à conta
        do Criador de Conteúdo; (c) Com o bloqueio da conta do Criador de
        Conteúdo na Plataforma;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        14.1.1. Tudo a critério exclusivo da Celetus, sem prejuízo de ações
        judiciais cabíveis em situações de Perda pela própria empresa ou por
        terceiros para apuração de indenização, inclusive suplementar para
        apuração de perdas e danos.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.2. Remoção de Infoprodutos. Nos casos de remoção de Infoproduto, as
        transações relacionadas ao Infoproduto removido realizadas anteriormente
        à remoção poderão ter seus respectivos valores bloqueados e/ou
        reembolsados para os Compradores. A partir da remoção do Infoproduto de
        uma determinada conta, nenhuma nova transação referente a tal
        Infoproduto será processada pelas ferramentas de pagamento da Celetus
        para aquela conta.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.3. Suspensão de Acesso. Nos casos de suspensão do acesso à conta do
        Criador de Conteúdo, as transações relacionadas aos Infoprodutos
        cadastrados por aquela conta poderão continuar a ser realizadas, porém,
        o usuário não terá acesso a qualquer funcionalidade da conta, exemplo:
        nenhuma alteração poderá ser realizada nos dados cadastrais, dados
        bancários ou nos conteúdos cadastrados, tampouco poderá ser feito
        qualquer resgate de valores acumulados na respectiva conta.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.4. Bloqueio por Chargebacks. Caso a taxa de chargeback da conta do
        Criador de Conteúdo seja acima de 1,5% (um vírgula cinco por cento), a
        Celetus reserva o direito de bloquear o saldo da conta total ou
        parcialmente por até 120 (cento e vinte) dias, para cobrir eventuais
        reembolsos e chargebacks.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.5. Cancelamento por Suspeita de Chargeback. Caso existam indícios ou
        suspeita de muitos novos chargebacks iminentes, e a Celetus identifique
        risco de aumento futuro da taxa de chargeback para acima de 3% (três por
        cento), seja por motivo de suspeita de fraude, ou por motivo de baixa
        satisfação dos Compradores, a Celetus reserva o direito de cancelar e
        reembolsar parte ou todas as vendas da conta do Criador de Conteúdo,
        devolvendo o dinheiro aos Compradores, com objetivo de evitar
        chargebacks.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.6. Ferramentas de monitoramento de chargebacks. Para diminuir o
        volume de chargebacks na Plataforma, a Celetus utiliza ferramentas de
        monitoramento de chargebacks que emitem alertas sobre Transações
        suspeitas.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        14.6.1 Após ser alertada sobre uma Transação suspeita pela ferramenta(s)
        mencionada(s) no caput, a Celetus pode, a seu exclusivo critério,
        cancelar a Transação, reembolsando o Comprador, independentemente da
        confirmação – ou não – do chargeback.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        14.6.2 Em relação a transações efetuadas com Compradores que não residam
        no Brasil, será aplicada uma taxa de USD$ 50 para cada alerta de
        chargeback ou chargeback efetivo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        14.6.3 A Celetus reserva-se o direito de alterar, a qualquer momento e a
        seu exclusivo critério, as taxas cobradas por alertas de chargeback ou
        chargeback efetivo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.7. Bloqueio de Contas. Nos casos de bloqueio da conta do cadastro do
        Criador de Conteúdo, todos os anúncios ativos e/ou ofertas realizadas
        serão automaticamente cancelados, não assistindo ao Criador de Conteúdo,
        por essa razão, qualquer direito de indenização ou ressarcimento.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.8. Fraude Financeira. Caso a Celetus suspeite ou tenha indícios de
        que o Criador de Conteúdo está usando mecanismos para fraudar o sistema
        pagamentos, ou o sistema de rastreamento ou utilizando qualquer outro
        mecanismo de fraude, a Celetus reserva-se o direito de suspender
        imediatamente o acesso à conta do Criador de Conteúdo suspeito de fraude
        e, eventualmente, bloquear em definitivo a conta do Criador de Conteúdo,
        sendo certo que o saldo de valores a receber deste Criador de Conteúdo
        poderá ser parcial ou totalmente reembolsado para os Compradores, com
        objetivo de evitar potenciais chargebacks. O saldo também poderá ser
        retido pela Celetus, como ressarcimento parcial dos danos que tiver
        experimentado, ou entregue a terceiros prejudicados, mediante ordem
        judicial.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.9. Ações Legais. A Celetus, os Criadores de Conteúdo ou quaisquer
        terceiros prejudicados poderão tomar as ações legais cabíveis em
        decorrência dos delitos ou contravenções de que sejam vítimas, ou ainda
        buscar o ressarcimento pelos prejuízos civis por descumprimento dos
        Termos de Uso, sem que isso acarrete qualquer direito de indenização por
        parte da Celetus ao Criador de Conteúdo acionado.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.10. Retenção de Saldo. Caso algum Afiliado, Criador de Conteúdo ou
        terceiro impute à Celetus qualquer responsabilidade decorrente de
        Infoproduto (chargeback, produto defeituoso etc.), a Celetus pode,
        cautelarmente, reter saldo suficiente para satisfação do dano alegado
        por terceiro da conta do Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        14.11. Medida Cautelar. Caso não haja saldo suficiente na conta do
        Criador de Conteúdo para satisfazer o prejuízo causado à Celetus ou a
        terceiros e a Celetus tome as ações legais cabíveis para buscar o
        ressarcimento, o Criador de Conteúdo concorda, desde já, com o
        deferimento de medida cautelar para o bloqueio de tantos bens quanto
        bastem para satisfação do prejuízo.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        15- RESCISÃO
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        15.1. Rescisão da Celetus. A Celetus poderá encerrar, a seu exclusivo
        critério, a disponibilização de quaisquer funcionalidades da Plataforma
        Celetus oferecidas e remover quaisquer Infoprodutos nela publicados. A
        Celetus poderá também restringir o acesso de Criador de Conteúdoes a
        qualquer parte ou a todos os notificação prévia, e por qualquer razão ou
        motivo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        15.2. Rescisão do Criador de Conteúdo. O Criador de Conteúdo poderá
        deixar de usar a Plataforma a qualquer tempo, devendo para tanto remover
        todo o seu Infoproduto, bem como responsabilizar-se pela entrega de
        qualquer Infoproduto que tenha sido comprado através de links
        anteriormente divulgados. Além disso, o Criador de Conteúdo deve
        realizar seu descadastramento como Criador de Conteúdo da Celetus. O
        Criador de Conteúdo, entretanto, continuará responsável pelo Infoproduto
        perante os Compradores, nos termos da legislação aplicável.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        15.3. Rescisão do Afiliado. O Afiliado poderá parar de promover qualquer
        Infoproduto em qualquer momento.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        15.4. Direito de Retenção. Em qualquer caso, inclusive de rescisão, a
        Celetus poderá, a seu exclusivo critério, reter total ou parcialmente o
        saldo existente na conta do Criador de Conteúdo, caso entenda que tal
        medida é necessária para garantir o pagamento, ressarcimento e reembolso
        devidos em razão de qualquer responsabilidade, obrigações ou dívida que
        o Criador de Conteúdo possa ter incorrido com a Celetus e/ou terceiros.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        16- OUTRAS DISPOSIÇÕES
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        16.1. Notificações. Qualquer aviso, requerimento, comunicação ou
        interpelação relacionada a estes termos deverá ser efetuada por escrito,
        seja por meio físico ou por eletrônico (e-mail).
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        16.2. Alterações. Só será eficaz contra a Celetus a alteração de
        endereço, físico ou eletrônico (e-mail), feita pelo Criador de Conteúdo
        em seus cadastros na dashboard.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        16.3. Autorização. A Celetus tem autorização para divulgar, no seu site
        e nos seus materiais de divulgação, que mantém relação comercial com o
        Criador de Conteúdo. Essa permissão inclui a possibilidade de uso da
        marca e de outros sinais distintivos do Criador de Conteúdo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        16.4. Validade de Disposições. A inexistência, invalidade ou ineficácia
        de quaisquer disposições destes Termos de Uso não prejudicará as demais.
        Caso qualquer disposição seja invalidada, no todo ou em parte,
        considerar-se-ão os termos como modificados com a exclusão ou a
        modificação, na extensão necessária da disposição inexistente, inválida
        ou ineficaz, de modo a manter a higidez destes Termos de Uso e, na
        medida do possível, preservar a intenção original das Partes.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        16.5. Revisão dos Termos. A Celetus pode revisar e alterar estes Termos
        de Uso periodicamente para refletir mudanças e adequações em seus
        negócios, Software Celetus, na legislação aplicável ou sempre que a
        Celetus entender necessário. Caso sejam feitas alterações, a Celetus
        notificará os Criador de Conteúdoes, Afiliados e Compradores pelo e-mail
        cadastrado na dashboard.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        16.5.1. Cada nova versão dos Termos de Uso entrará em vigor 15 (quinze)
        dias após a comunicação dos Criadores de Conteúdo, Afiliados e
        Compradores via e- mail.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        16.5.2. A Celetus deve manter disponível em seu site todas as versões
        antigas dos Termos de Uso que já estiveram em vigor.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        17- DA LIBERAÇÃO DE SALDO EXISTENTE EM CONTA
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        17.1. O prazo para a liberação de saques de valores acumulados na
        plataforma Celetus passa a ser de 02 (dois) dias úteis, condicionado à
        validação dos dados do usuário e ao cumprimento dos requisitos
        estabelecidos pela plataforma, conforme descrito a seguir:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        17.1.1. Requisitos para Liberação do Saldo: A liberação do saldo
        acumulado está sujeita à análise prévia, que considerará, dentre outros
        fatores:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 10 }}>
        17.1.1.1. Histórico de vendas em outras plataformas: avaliação de
        desempenho e confiabilidade;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 10 }}>
        17.1.1.2. Taxa de reembolso dos produtos em outras plataformas:
        identificação de riscos de chargeback ou reembolsos elevados;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 10 }}>
        17.1.1.3. Tipo de produto que será vendido: análise de risco com base na
        categoria do produto;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 10 }}>
        17.1.1.4. Tempo de mercado: experiência e solidez do produtor;
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 10 }}>
        17.1.1.5. Validação de identidade pelo Veriff: confirmação da identidade
        do usuário.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        17.2. Garantia contra Saldo Negativo: Para assegurar o equilíbrio
        financeiro da plataforma e evitar saldos negativos, o usuário concorda
        com as seguintes disposições:
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        17.2.1. Responsabilidade pelo Saldo Negativo: Caso o usuário gere saldo
        negativo na plataforma, este será considerado inadimplente, e a Celletus
        terá o direito de executar um título executivo extrajudicial no valor do
        débito, acrescido de juros, correção monetária, e eventuais despesas
        legais.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        17.2.2. Irrecorribilidade: O usuário renuncia expressamente ao direito
        de contestar judicial ou extrajudicialmente o referido título executivo.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2, ml: 5 }}>
        17.2.3. Autorização para Ação Contra o Produtor: A Celletus está
        autorizada a adotar as medidas cabíveis para recuperação do valor
        devido, inclusive acionando o produtor judicialmente ou mediante outros
        meios legais.
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        fontWeight={600}
        sx={{ mb: 2 }}
      >
        18 - LEI APLICÁVEL E CLÁUSULA DE ELEIÇÃO DE FORO
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        As partes elegem o Foro da Comarca de Goiânia/GO para dirimir quaisquer
        dúvidas ou controvérsias oriundas destes Termos de Uso e Documentos.
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 2 }}>
        © {new Date().getFullYear()} Todos os direitos reservados
      </Typography>
    </Box>
  )
}

export default Terms

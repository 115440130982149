import * as React from 'react'
import { Box, Typography } from '@mui/material'
import InputSearch from '../../../components/Inputs/InputSearch'
import UserSetting from './UserSetting'
import { TTenantSettings } from '../../../core/types/Tenant'
import { useTenantSettings } from '../../../core/hooks/useTenantSettings'
import { TenantController } from '../../../core/controllers/TenantController'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import DataTable from '../../../components/Tables/DataTable'
import AlertDefault from '../../../components/Alerts'
import { tenantSettingsDefault } from './UserSetting/UserSettingConsts'
import { userSettingsColumns } from './UserSettingsColumns'
import { useAdminUserContext } from '../../../core/hooks/useAdminUserContext'

export default function UserSettingsPage() {
  const { tenantSettings, setTenantSettings } = useAdminUserContext()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')
  const [message, setMessage] = React.useState('')

  const { loading, data, error, execute, clear } = useTenantSettings({
    page,
    rows: rowsPerPage,
    search,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeUserSetting = (tenantSettings?: TTenantSettings) => {
    tenantSettings ??= tenantSettingsDefault
    setTenantSettings({ ...tenantSettings })
    setIsOpen(true)
  }

  const handleCloseUserSetting = () => {
    setIsOpen(false)
    setTenantSettings({ ...tenantSettingsDefault })
  }

  const handleSaveUserSetting = async () => {
    if (tenantSettings) {
      setSending(true)
      try {
        var response
        if (tenantSettings.id === '') {
          response = await TenantController.insertSettings({
            data: tenantSettings,
          })
        } else {
          response = await TenantController.updateSettings({
            data: tenantSettings,
          })
        }
        if (!response.success) {
          setErrorSending(response.error)
          return
        }
        setSuccess(true)
        handleCloseUserSetting()
        execute()
      } finally {
        setSending(false)
      }
    }
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Configurações do usuário
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <InputSearch
        name="Filtrar por nome"
        setSearch={setSearch}
        execute={execute}
      />

      <DataTable
        columns={userSettingsColumns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onItemClick={handleChangeUserSetting}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {tenantSettings?.id !== '' && (
        <UserSetting
          open={isOpen}
          sending={false}
          setOpen={setIsOpen}
          handleClose={handleCloseUserSetting}
          handleSave={handleSaveUserSetting}
          setSending={setSending}
          setSuccess={setSuccess}
          setError={setErrorSending}
          setMessage={setMessage}
          executeUserSettings={execute}
        />
      )}

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={message !== ''} onClose={() => setMessage('')}>
        {message}
      </SuccessSnackbar>

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        {(tenantSettings?.id ?? '') !== '' ? 'Alterado ' : 'Inserido '} com
        sucesso
      </SuccessSnackbar>
    </Box>
  )
}

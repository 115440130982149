import { IconButton, Stack, Tooltip } from '@mui/material'
import { IDataTableColumn } from '../../../components/Tables/DataTable'
import { TMemberService } from '../../../core/types/MemberService'
import { IconDelete, IconEdit } from '../../../core/constants/IconConsts'

export const appCademiColumns = ({
  handleEdit,
  handleDelete,
}: {
  handleEdit: (value: TMemberService) => void
  handleDelete: (value?: TMemberService) => void
}): readonly IDataTableColumn<TMemberService>[] => [
  {
    id: 'identifier',
    label: 'Identificação',
    minWidth: 80,
  },
  {
    id: 'domain',
    label: 'Domínio',
    minWidth: 80,
  },
  {
    id: 'actons',
    label: 'Ações',
    width: 90,
    render(id, value, row) {
      return (
        <Stack direction="row">
          <Tooltip title="Editar Cademí">
            <IconButton size="small" onClick={() => handleEdit(row)}>
              <img src={IconEdit} alt="edit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir Cademí">
            <IconButton size="small" onClick={() => handleDelete(row)}>
              <img src={IconDelete} alt="delete" />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    },
  },
]

import {
  Box,
  Fade,
  FormControlLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import SwitchRoundSmall from '../../../../../components/Switch/SwitchRoundSmall'
import { maskPercentage } from '../../../../../masks/masks'
import InfoInputAdornment from '../../../../../components/Inputs/InfoInputAdornment'
import { UserSettingAffiliatedsProps } from '../UserSettingTypes'
import { red } from '@mui/material/colors'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { useAdminUserContext } from '../../../../../core/hooks/useAdminUserContext'

const UserSettingAffiliateds = ({
  errorAffiliateFee,
  setErrorAffiliateFee,
}: UserSettingAffiliatedsProps) => {
  const { tenantSettings, setTenantSettings } = useAdminUserContext()

  const handleChangeAffiliateFee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!tenantSettings) return

    setErrorAffiliateFee('')
    const affiliateFees = NumberFunctions.maskPercentageToNumber(e.target.value)
    setTenantSettings({ ...tenantSettings, affiliateFees })
  }

  return (
    <Stack direction="column" spacing={0} sx={{ py: 1 }}>
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings?.enableAffiliateFees ?? false}
                onChange={(e) => {
                  if (!tenantSettings) return

                  let anticipationD15Fee =
                    tenantSettings.anticipationD15Fee ?? 0
                  if (anticipationD15Fee <= 0) {
                    anticipationD15Fee = 2
                  }
                  setTenantSettings({
                    ...tenantSettings,
                    enableAffiliateFees: e.target.checked,
                    anticipationD15Fee,
                  })
                }}
              />
            }
            label={
              <Typography variant="subtitle2">Taxa de afiliados (%)</Typography>
            }
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Fade
            in={tenantSettings?.enableAffiliateFees ?? false}
            mountOnEnter
            unmountOnExit
          >
            <Box width="100%">
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                value={maskPercentage(tenantSettings?.affiliateFees ?? 0)}
                onChange={handleChangeAffiliateFee}
                endAdornment={
                  <InfoInputAdornment title="Ao habilitar essa função as taxas dos afiliados serão cobradas com base nessa nova taxa e não mais em nível de vendedor e de suas próprias contas." />
                }
                error={errorAffiliateFee !== ''}
              />
              {errorAffiliateFee !== '' && (
                <Typography variant="caption" color={red[700]}>
                  {errorAffiliateFee}
                </Typography>
              )}
            </Box>
          </Fade>
        }
      />
    </Stack>
  )
}

export default UserSettingAffiliateds

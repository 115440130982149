import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
  TItemTypeSaleEnum,
  TSubscription,
  TSubscriptionDetails,
} from '../types/CheckoutOrder'
import { TLostSale } from '../types/LostSale'
import { SubscriptionStatusEnum } from '../types/Order'
import { diffDays } from './dateTime'

type TCheckout = TCheckoutOrder | undefined | null

type TCheckoutDetails = TCheckoutOrderDetails | undefined | null

const CHECKOUT_STATUS_PAID = 'paid'
const CHECKOUT_STATUS_CANCELED = 'canceled'
const CHECKOUT_STATUS_REFUND_CLAIMED = 'refund_claimed'

const CHECKOUT_STATUS_PENDINGS = ['pending', 'proccessing']

const STATUS_FILTER = [
  { label: 'Todas', value: 'all' },
  { label: 'Únicas', value: 'order' },
  { label: 'Assinaturas', value: 'subscription' },
]

const isPaid = (checkout: TCheckout) => {
  return checkout?.status === CHECKOUT_STATUS_PAID
}

const isRefundClaim = (checkout: TCheckout) => {
  return checkout?.status === CHECKOUT_STATUS_REFUND_CLAIMED
}

const isCanceled = (checkout: TCheckout) => {
  return checkout?.status === CHECKOUT_STATUS_CANCELED
}

const isSubscriptionActive = (subscription?: TSubscription) => {
  return subscription?.status === SubscriptionStatusEnum.Active
}

const isPendings = (checkout: TCheckout) => {
  return CHECKOUT_STATUS_PENDINGS.includes(checkout?.status ?? '')
}

const isSubscription = (checkout: TCheckout) => {
  return checkout?.itemTypeSale === TItemTypeSaleEnum.Subscription
}

const isSubscriptionPaymentCancel = (details?: TCheckoutDetails) => {
  return details?.isSubscriptionPaymentCancel
}

const isSubscriptionDetailsPaymentCancel = (details?: TSubscriptionDetails) => {
  return details?.orderDetails.isSubscriptionPaymentCancel
}

const isSubscriptionAndNotPaymentCancel = (
  checkout: TCheckout,
  details: TCheckoutDetails
) => {
  return isSubscription(checkout) && !isSubscriptionPaymentCancel(details)
}

const getDiffDaysDescription = (dateTime: any) => {
  const days = diffDays(new Date(), new Date(dateTime))
  const labelDays = days >= 7 ? '+ 7 dias' : `${days} dia${days > 1 ? 's' : ''}`
  return labelDays
}

const getUrlCheckoutAbandoned = (row: TLostSale) => {
  const query = `name=${encodeURIComponent(row.name)}&email=${encodeURIComponent(
    row.email || ''
  )}&phone=${encodeURIComponent(row.phone || '')}&zipcode=${encodeURIComponent(
    row.cep || ''
  )}`
  const afid = row.affiliatedCode ? `afid=${row.affiliatedCode}&` : ''
  const urlBase = `${process.env.REACT_APP_PAY_HOST}/${row.productPriceCodeId}?${afid}`
  const url = `${urlBase}${query}`
  return url
}

const getTextWhatsAppAbandoned = (row: TLostSale) => {
  if (!row.phone || row.phone === '') {
    return ''
  }

  const query = `name=${encodeURIComponent(row.name)}&email=${encodeURIComponent(
    row.email || ''
  )}&phone=${encodeURIComponent(row.phone || '')}&zipcode=${encodeURIComponent(
    row.cep || ''
  )}`
  const afid = row.affiliatedCode ? `afid=${row.affiliatedCode}&` : ''
  const urlBase = `${process.env.REACT_APP_PAY_HOST}/${row.productPriceCodeId}?${afid}`
  const url = `${urlBase}${query}`

  const text = `Olá ${row.name},\nNão perca a oportunidade para adquirir o "${row.productName}" acesse agora o link ${url} e não perca a oportunidade.`

  return `https://wa.me/55${row.phone}?text=${encodeURIComponent(text)}`
}

const OrderFunctions = {
  STATUS_FILTER,
  isPaid,
  isCanceled,
  isPendings,
  isSubscription,
  isSubscriptionPaymentCancel,
  isSubscriptionDetailsPaymentCancel,
  isSubscriptionAndNotPaymentCancel,
  getDiffDaysDescription,
  getUrlCheckoutAbandoned,
  getTextWhatsAppAbandoned,
  isSubscriptionActive,
  isRefundClaim,
}

export default OrderFunctions

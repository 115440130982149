import { createContext, ReactNode, useState } from 'react'
import { TAdminUserContext } from '../types/User'
import { TTenantSettings } from '../types/Tenant'

type Props = {
  children?: ReactNode
}

const initialValue = {
  tenantSettings: null,
  setTenantSettings: (_: TTenantSettings | null) => {},
}

const AdminUserContext = createContext<TAdminUserContext>(initialValue)

const AdminUserProvider = ({ children }: Props) => {
  const [tenantSettings, setTenantSettings] = useState<TTenantSettings | null>(
    null
  )

  return (
    <AdminUserContext.Provider value={{ tenantSettings, setTenantSettings }}>
      {children}
    </AdminUserContext.Provider>
  )
}

export { AdminUserContext, AdminUserProvider }

import ImagePlaceholder from '../../../../../components/Images/ImagePlaceholder'
import { ProductGeneralImageProps } from '../../../ProductTypes'

const ProductGeneralImage = ({
  imageProductUrl,
  isSmall,
  setValue,
  setError,
}: ProductGeneralImageProps) => {
  return (
    <ImagePlaceholder
      imageUrl={imageProductUrl}
      alt="img-top"
      style={{
        borderRadius: 10,
        objectFit: 'cover',
        objectPosition: 'center',
        width: isSmall ? '90px' : '200px',
        height: isSmall ? '90px' : '200px',
        backgroundColor: 'white',
      }}
      optionsStyle={{
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }}
      onChange={(file) => {
        setValue('imageProduct', file)
        if (!file) {
          setValue('imageProductUrl', '')
        }
      }}
      onError={(error) => setError(error)}
    />
  )
}

export default ProductGeneralImage

import { Chip, Stack, Typography } from '@mui/material'
import { IDataTableColumn } from '../../../components/Tables/DataTable'
import { dateTimeString } from '../../../core/functions/dateTime'
import { TSubscription } from '../../../core/types/CheckoutOrder'
import DataTableCellTypography from '../../../components/Typographys/DataTableCellTypography'
import DataTableSubcellTypography from '../../../components/Typographys/DataTableSubcellTypography'
import Convertions from '../../../core/functions/convertions'
import MethodTypeSmallIcon from '../../../components/Checkout/Icons/MethodTypeSmallIcon'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import SubscriptionFunctions from '../../../core/functions/SubscriptionFunctions'

export const checkoutOrderSubscriptionStatusColor: Record<
  string,
  { backgroundColor: string; color: string }
> = {
  pending: {
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  failed: {
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  paid: {
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  default: {
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  warning: {
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

export const subscriptionsColumns: readonly IDataTableColumn<TSubscription>[] =
  [
    {
      id: 'createdDate',
      label: 'Criado em',
      minWidth: 80,
      format: (value) => dateTimeString(new Date(value)),
    },
    {
      id: 'productName',
      label: 'Produto',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableCellTypography>{row.productName}</DataTableCellTypography>
            <DataTableSubcellTypography>
              {row.offerName}
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    {
      id: 'name',
      label: 'Cliente',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableCellTypography>{row.name}</DataTableCellTypography>
            <DataTableSubcellTypography>{row.email}</DataTableSubcellTypography>
          </Stack>
        )
      },
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      render(id, value, row) {
        const statusMap = SubscriptionFunctions.getStatusMap(value)
        const { label, ...style } = statusMap

        return (
          <Stack direction="row" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              {/*(row as TCheckoutOrder).inAssurance === true && (
              <LockIcon fontSize="small" color="warning" />
            )*/}
              <Chip label={label} size="small" sx={{ ...style }} />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <MethodTypeSmallIcon methodType={row.paymentyMethod} />
              <Typography
                fontSize="14px"
                lineHeight="18px"
                color="#1C52BD"
                fontWeight={500}
              >
                {Convertions.methodTypeToString(row.paymentyMethod)}
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    {
      id: 'value',
      label: 'Comissão',
      minWidth: 60,
      align: 'right',
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
    {
      id: 'frequency',
      label: 'Frequência',
      minWidth: 100,
      format: (value) => Convertions.productFrequencyToString(value),
    },
    {
      id: 'paymentNumbers',
      label: 'Ciclos',
      minWidth: 60,
      align: 'right',
      format: (value) => (value || 0).toString(),
    },
  ]

import {
  TCheckoutOrderDetailsError,
  TCheckoutValueType,
  TValueProductTypeEnum,
} from '../types/CheckoutOrder'
import { TCommissionRuleType } from '../types/CommissionRule'
import { TCoproducerStatus } from '../types/Coproducer'
import { TCustomerProductStatus } from '../types/Customer'
import { TMemberServiceType } from '../types/MemberService'
import {
  TPaymentMethodEnum,
  TProductAssurance,
  TProductFrequency,
  TProductLinkCategory,
  TProductLinkDuration,
  TProductStatus,
  TProductTypePayment,
} from '../types/Product'
import { Buffer } from 'buffer'
import { TTenantAnticipationType } from '../types/Tenant'

const base64Decode = (str: string): string =>
  Buffer.from(str, 'base64').toString('utf8')

const base64Encode = (str: string): string =>
  Buffer.from(str, 'utf8').toString('base64')

const paymentMethodToString = (value?: string): string => {
  switch (value) {
    case 'credit_card':
    case '1':
      return 'Crédito'
    case 'debit_card':
    case '2':
      return 'Débito'
    case 'pix':
    case '3':
      return 'PIX'
    case 'boleto':
    case '4':
      return 'Boleto'
    case 'two_credit_card':
    case '6':
      return '2 Créditos'
    default:
      return value ?? ''
  }
}

const checkoutOrderStatusToString = (value?: string): string => {
  switch (value) {
    case 'pending':
      return 'Pendente'
    case 'paid':
      return 'Pago'
    case 'canceled':
      return 'Reembolso'
    case 'processing':
      return 'Processando'
    case 'failed':
      return 'Falha'
    case 'overpaid':
      return 'Pago a mais'
    case 'underpaid':
      return 'Pago a menos'
    case 'chargedback':
      return 'Estorno'
    case 'refund_claimed':
      return 'Reclamada'
    default:
      return value ?? ''
  }
}

const coproducerStatusToString = (value?: TCoproducerStatus) => {
  return value === TCoproducerStatus.Pending
    ? 'Pendente'
    : value === TCoproducerStatus.Confirmed
      ? 'Confirmado'
      : value === TCoproducerStatus.Canceled
        ? 'Cancelado'
        : ''
}

const customerProductStatusToString = (value?: TCustomerProductStatus) => {
  return value === TCustomerProductStatus.Aprovado
    ? 'Aprovado'
    : value === TCustomerProductStatus.Cancelado
      ? 'Cancelado'
      : value === TCustomerProductStatus.Disputa
        ? 'Disputa'
        : ''
}

const methodTypeToString = (value?: TPaymentMethodEnum) => {
  switch (value) {
    case TPaymentMethodEnum.CreditCard:
      return 'Crédito'
    case TPaymentMethodEnum.DebitCard:
      return 'Débito'
    case TPaymentMethodEnum.Pix:
      return 'PIX'
    case TPaymentMethodEnum.Boleto:
      return 'Boleto'
    case TPaymentMethodEnum.TwoCreditCard:
      return '2 Cartões'
    case TPaymentMethodEnum.CreditCardAndPix:
      return 'Crétido e Pix'
    default:
      return ''
  }
}

const methodTypeTo2Chars = (value?: TPaymentMethodEnum) => {
  switch (value) {
    case TPaymentMethodEnum.CreditCard:
      return 'Cr'
    case TPaymentMethodEnum.DebitCard:
      return 'De'
    case TPaymentMethodEnum.Pix:
      return 'Pi'
    case TPaymentMethodEnum.Boleto:
      return 'Bo'
    case TPaymentMethodEnum.TwoCreditCard:
      return '2C'
    case TPaymentMethodEnum.CreditCardAndPix:
      return 'CP'
    default:
      return ''
  }
}

const productFrequencyToString = (value?: TProductFrequency) => {
  switch (value) {
    case TProductFrequency.Weekly:
      return 'Semanal'
    case TProductFrequency.Monthly:
      return 'Mensal'
    case TProductFrequency.Bimonthly:
      return 'Bimestral'
    case TProductFrequency.Quarterly:
      return 'Trimestral'
    case TProductFrequency.Semester:
      return 'Semestral'
    case TProductFrequency.Yearly:
      return 'Anual'
    default:
      return ''
  }
}

const productFrequencyToType = (value?: TProductFrequency) => {
  switch (value) {
    case TProductFrequency.Weekly:
      return 'Semana'
    case TProductFrequency.Monthly:
      return 'Mês'
    case TProductFrequency.Bimonthly:
      return 'Bimestre'
    case TProductFrequency.Quarterly:
      return 'Trimestre'
    case TProductFrequency.Semester:
      return 'Semestre'
    case TProductFrequency.Yearly:
      return 'Ano'
    default:
      return ''
  }
}

const valueTypeToString = (value?: TCheckoutValueType) => {
  switch (value) {
    case TCheckoutValueType.Plataform:
      return 'Plataforma'
    case TCheckoutValueType.Producer:
      return 'Produtor'
    case TCheckoutValueType.CoProducer:
      return 'Coprodutor'
    case TCheckoutValueType.Affiliated:
      return 'Afiliado'
    case TCheckoutValueType.GlobalAffiliated:
      return 'Afiliado Global'
    case TCheckoutValueType.Unknown:
      return 'Não Classificado'
    default:
      return ''
  }
}

const productAssuranceToString = (value?: TProductAssurance) => {
  switch (value) {
    case TProductAssurance.Seven:
      return '7 dias'
    case TProductAssurance.Fifteen:
      return '15 dias'
    case TProductAssurance.TwentyOne:
      return '21 dias'
    case TProductAssurance.Thirty:
      return '30 dias'
    default:
      return 'Nenhum'
  }
}

const productStatusToString = (value?: TProductStatus) => {
  switch (value) {
    case TProductStatus.Active:
      return 'Ativo'
    case TProductStatus.Inactive:
      return 'Inativo'
    default:
      return ''
  }
}

const commissionRuleTypeToString = (value?: TCommissionRuleType) => {
  switch (value) {
    case TCommissionRuleType.Sales:
      return 'Vendas'
    case TCommissionRuleType.Commissions:
      return 'Comissões'
    default:
      return ''
  }
}

const productLinkDurationToString = (value?: TProductLinkDuration | null) => {
  switch (value) {
    case TProductLinkDuration.Unknown:
      return '24 horas'
    case TProductLinkDuration.SevenDays:
      return '7 dias'
    case TProductLinkDuration.ThirtyDays:
      return '30 dias'
    case TProductLinkDuration.SixtyDays:
      return '60 dias'
    case TProductLinkDuration.NinetyDays:
      return '30 dias'
    case TProductLinkDuration.OneHundredAndEightyDays:
      return '180 dias'
    case TProductLinkDuration.ThreeHundredSixtyFiveDays:
      return '365 dias'
    case TProductLinkDuration.Eternal:
      return 'Eterno'
    default:
      return ''
  }
}

const productLinkCategoryToString = (value?: TProductLinkCategory) => {
  switch (value) {
    case TProductLinkCategory.SalesPage:
      return 'Página de Venda'
    case TProductLinkCategory.Leads:
      return 'Leads'
    case TProductLinkCategory.SocialMedia:
      return 'Rede Social'
    case TProductLinkCategory.Others:
      return 'Outros'
    default:
      return ''
  }
}

const tenantAnticipationTypeToString = (value?: TTenantAnticipationType) => {
  switch (value) {
    case TTenantAnticipationType.None:
      return 'Nenhum'
    case TTenantAnticipationType.D2:
      return 'D + 2'
    case TTenantAnticipationType.D15:
      return 'D + 15'
    case TTenantAnticipationType.D30:
      return 'D + 30'
  }
}

const memberServiceTypeToString = (value?: TMemberServiceType) => {
  switch (value) {
    case TMemberServiceType.Cademi:
      return 'Cademí'
    case TMemberServiceType.TheMembers:
      return 'The Members'
    case TMemberServiceType.AstronMembers:
      return 'Astron Members'
    case TMemberServiceType.CeletusClub:
      return 'Celetus Club'
    case TMemberServiceType.MemberKit:
      return 'MemberKit'
    default:
      return ''
  }
}

const productTypePaymentToString = (value?: TProductTypePayment) => {
  switch (value) {
    case TProductTypePayment.OneTimePayment:
      return 'Único'
    case TProductTypePayment.RecurringSubscription:
      return 'Assinatura'
    default:
      return ''
  }
}

const valueProductTypeToString = (value?: TValueProductTypeEnum) => {
  switch (value) {
    case TValueProductTypeEnum.Orderbump:
      return 'Orderbump'
    case TValueProductTypeEnum.Upsell:
      return 'Upsell'
    default:
      return 'Principal'
  }
}

const ckeckoutOrderDetailsError = (value?: TCheckoutOrderDetailsError) => {
  switch (value) {
    case TCheckoutOrderDetailsError.Paid:
      return 'Pago'
    case TCheckoutOrderDetailsError.InsufficientBalance:
      return 'Saldo insuficiente'
    case TCheckoutOrderDetailsError.SuspiciusTransaction:
      return 'Transação suspeita'
    case TCheckoutOrderDetailsError.RefusedByBank:
      return 'Recusado pelo banco'
    case TCheckoutOrderDetailsError.RefusedByBank2:
      return 'Recusado pelo banco'
    case TCheckoutOrderDetailsError.RefusedByBank3:
      return 'Recusado pelo banco'
    case TCheckoutOrderDetailsError.RefusedByBank4:
      return 'Recusado pelo banco'
    case TCheckoutOrderDetailsError.TransactionDeclinedDueToSecurity:
      return 'Transação recusada por violação de segurança'
    case TCheckoutOrderDetailsError.IncorrectCardDetails:
      return 'Dados do cartão incorretos'
    case TCheckoutOrderDetailsError.ExpiredCardOrIncorrectDate:
      return 'Cartão vencido ou data de vencimento incorreto'
    case TCheckoutOrderDetailsError.TransactionDeclined:
      return 'Transação recusada'
    case TCheckoutOrderDetailsError.IncorrectCardDetails2:
      return 'Dados do cartão incorretos'
    case TCheckoutOrderDetailsError.CardBlockedCanceledOrInactive:
      return 'Cartão bloqueado, cancelado ou inativo'
    case TCheckoutOrderDetailsError.RefusedByBank5:
      return 'Cartão recusado pelo banco'
    case TCheckoutOrderDetailsError.RefusedByBank6:
      return 'Cartão recusado pelo banco'
    case TCheckoutOrderDetailsError.TransactionDeclined2:
      return 'Transação recusada'
    case TCheckoutOrderDetailsError.BankErrorOrCardoperatorSystem:
      return 'Erro no sistema do banco ou operadora do cartão'
    case TCheckoutOrderDetailsError.RejectedDueToManyAttempts:
      return 'Recusa por excesso de tentativas'
    case TCheckoutOrderDetailsError.TransactionDeclined3:
      return 'Transação recusada'
    case TCheckoutOrderDetailsError.TransactionDeclined4:
      return 'Transação recusada'
    case TCheckoutOrderDetailsError.TransactionDeclined5:
      return 'Transação recusada'
    case TCheckoutOrderDetailsError.TransactionDeclined6:
      return 'Transação recusada'
    case TCheckoutOrderDetailsError.TransactionDeclined7:
      return 'Transação recusada'
    default:
      return ''
  }
}

const ckeckoutOrderDetailsDescriptionError = (
  value?: TCheckoutOrderDetailsError
) => {
  switch (value) {
    case TCheckoutOrderDetailsError.Paid:
      return ''
    case TCheckoutOrderDetailsError.InsufficientBalance:
      return 'Oriente ao cliente utilizar outro cartão.'
    case TCheckoutOrderDetailsError.SuspiciusTransaction:
      return 'Oriente ao cliente tentar com outro cartão ou alterar os dados do comprador.'
    case TCheckoutOrderDetailsError.RefusedByBank:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.RefusedByBank2:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.RefusedByBank3:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.RefusedByBank4:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.TransactionDeclinedDueToSecurity:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.IncorrectCardDetails:
      return 'Oriente ao cliente tentar novamente e se atentar aos dados do cartão.'
    case TCheckoutOrderDetailsError.ExpiredCardOrIncorrectDate:
      return 'Oriente ao cliente tentar novamente com outros cartão em caso de vencimento, ou, tentar novamente com o mesmo cartão de forma mais atenta aos dados.'
    case TCheckoutOrderDetailsError.TransactionDeclined:
      return 'Oriente ao usuário usar outro cartão'
    case TCheckoutOrderDetailsError.IncorrectCardDetails2:
      return 'Oriente ao cliente tentar novamente e se atentar aos dados do cartão.'
    case TCheckoutOrderDetailsError.CardBlockedCanceledOrInactive:
      return ''
    case TCheckoutOrderDetailsError.RefusedByBank5:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.RefusedByBank6:
      return 'Oriente ao aluno entrar em contato com a central do seu cartão e tentar novamente em seguida.'
    case TCheckoutOrderDetailsError.TransactionDeclined2:
      return ''
    case TCheckoutOrderDetailsError.BankErrorOrCardoperatorSystem:
      return 'Solicite ao aluno tentar novamente ou aguardar o banco/operadora do cartão retornar as atividades.'
    case TCheckoutOrderDetailsError.RejectedDueToManyAttempts:
      return 'Oriente ao cliente tentar com outro cartão ou alterar os dados do comprador.'
    case TCheckoutOrderDetailsError.TransactionDeclined3:
      return 'Oriente ao usuário usar outro cartão'
    case TCheckoutOrderDetailsError.TransactionDeclined4:
      return 'Oriente ao usuário usar outro cartão'
    case TCheckoutOrderDetailsError.TransactionDeclined5:
      return 'Oriente ao usuário usar outro cartão'
    case TCheckoutOrderDetailsError.TransactionDeclined6:
      return 'Oriente ao usuário usar outro cartão'
    case TCheckoutOrderDetailsError.TransactionDeclined7:
      return 'Oriente ao usuário usar outro cartão'
    default:
      return ''
  }
}

const replaceSpacesAndNewlines = (input: string | null | undefined): string => {
  let result = ''
  if (input) {
    for (const char of input) {
      if (char === ' ' || char === '\n') {
        result += '-'
      } else {
        result += char
      }
    }
  }
  return result
}

const Convertions = {
  base64Decode,
  base64Encode,
  paymentMethodToString,
  checkoutOrderStatusToString,
  coproducerStatusToString,
  customerProductStatusToString,
  methodTypeToString,
  methodTypeTo2Chars,
  productFrequencyToString,
  productFrequencyToType,
  valueTypeToString,
  productAssuranceToString,
  productStatusToString,
  commissionRuleTypeToString,
  productLinkDurationToString,
  productLinkCategoryToString,
  tenantAnticipationTypeToString,
  memberServiceTypeToString,
  productTypePaymentToString,
  valueProductTypeToString,
  ckeckoutOrderDetailsError,
  ckeckoutOrderDetailsDescriptionError,
  replaceSpacesAndNewlines,
}

export default Convertions

import * as React from 'react'
import {
  Box,
  Typography,
  Stack,
  Button,
  InputLabel,
  Paper,
} from '@mui/material'
import { useMemberServices } from '../../../../core/hooks/useMemberServices'
import InputSearch from '../../../../components/Inputs/InputSearch'
import CademiRegister from '../CademiRegister'
import {
  TMemberService,
  TMemberServiceType,
} from '../../../../core/types/MemberService'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import MemberServiceController from '../../../../core/controllers/MemberServiceController'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import AlertDefault from '../../../../components/Alerts'
import { cademiDefault } from '../../AppsConsts'
import DataTable from '../../../../components/Tables/DataTable'
import { appCademiColumns } from '../AppCademiConsts'
import TitlePageTypography from '../../../../components/Typographys/TitlePageTypography'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import { ItensOptions } from '../../../../components/Tables/constans'
import SelectInput from '../../../../components/SelectInput/SelectInput'
import CademiMobileItem from './CademiMobileItem'

export default function CademiPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [current, setCurrent] = React.useState<TMemberService>({
    ...cademiDefault,
  })
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useMemberServices({
    page,
    rows: rowsPerPage,
    search: search,
    type: TMemberServiceType.Cademi,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleOpen = (cademi?: TMemberService) => {
    cademi ??= { ...cademiDefault }
    if (cademi.id === '') {
      setCurrent({ ...cademi })
      setOpen(true)
    } else {
      getMemberService(cademi)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setCurrent({ ...cademiDefault })
  }

  const handleDelete = (cademi?: TMemberService) => {
    if (!cademi) {
      return
    }
    setCurrent({ ...cademi })
    setOpenDelete(true)
  }

  const handleDeleteClose = () => {
    setOpenDelete(false)
    setCurrent({ ...cademiDefault })
  }

  const deleteCademi = async () => {
    if (!current || current.id === '') {
      return
    }

    setSending(true)
    setDeleted(false)
    setErrorSending('')

    try {
      const response = await MemberServiceController.remove({ id: current.id })

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setDeleted(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  const getMemberService = React.useCallback(
    async (memberService: TMemberService) => {
      setSending(true)
      try {
        const response = await MemberServiceController.get({
          id: memberService.id,
        })
        const responseError = response as IErrorResponse
        if (responseError.code) {
          setErrorSending(responseError.error)
          return
        }

        const responseData = response as TMemberService
        setCurrent({ ...responseData })
        setOpen(true)
      } finally {
        setSending(false)
      }
    },
    []
  )

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Paper
            elevation={0}
            sx={{
              borderRadius: '8px',
              p: '7px',
              fontSize: '0rem',
              backgroundColor: '#F2F2FF',
              border: '1px solid #D8D9FF',
              width: '40px',
              height: '40px',
            }}
          >
            <img
              src="/assets/apps/cademi.svg"
              alt="webhook"
              style={{ width: '24px', height: '24px', borderRadius: '10px' }}
            />
          </Paper>
          <TitlePageTypography>Cademí</TitlePageTypography>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
          sx={{
            borderRadius: '4px',
            padding: '8px 16px',
            gap: '10px',
            textTransform: 'none',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: 700,
            }}
          >
            Novo Cademí
          </Typography>
        </Button>
      </Stack>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', sm: 'center' }}
          justifyContent="space-between"
          spacing={1}
          sx={{ p: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <InputLabel id="itens-total">
              {data.total} registros encontradas
            </InputLabel>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <SelectInput
              value={rowsPerPage}
              onChange={(event) =>
                handleChangeRowsPerPage(
                  NumberFunctions.toNumber(event.target.value)
                )
              }
              options={ItensOptions}
              minWidth={107}
              maxWidth="50%"
            />

            <InputSearch
              id="cademi-search"
              key="cademi-search"
              name="Pesquisar cademi"
              size="small"
              setSearch={setSearch}
              execute={execute}
              adornmentToStart
              removeLabel
              sx={{ flexGrow: 1 }}
            />
          </Stack>
        </Stack>

        <DataTable
          hasNewCustomHeader
          columns={appCademiColumns({ handleEdit: handleOpen, handleDelete })}
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          renderItemMobile={(value) => (
            <CademiMobileItem item={value} handleChangeItem={handleOpen} />
          )}
        />
      </Stack>

      <CademiRegister
        open={open}
        setOpen={setOpen}
        memberService={current}
        execute={execute}
      />

      <ConfirmDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="Deseja realmente deletar a configuração da Cademí ?"
        onYes={deleteCademi}
      />

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={deleted} onClose={() => setDeleted(false)}>
        Deletado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

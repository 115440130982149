import { CircularProgress, Stack } from '@mui/material'

const ProgressIndicatorPageCenter = () => {
  return (
    <Stack
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Stack>
  )
}

export default ProgressIndicatorPageCenter

import { TMemberKitClassrom } from '../types/MemberService'

const sortMemberKitClassrooms = (classrooms: TMemberKitClassrom[]) =>
  classrooms.sort((a, b) =>
    `${a.name ?? ''} - ${a.courseName ?? ''}` >
    `${b.name ?? ''} - ${b.courseName ?? ''}`
      ? 1
      : -1
  )

const MemberServiceFunctions = {
  sortMemberKitClassrooms,
}

export default MemberServiceFunctions

import {
  TTenantAnticipationType,
  TTenantSettings,
} from '../../../../core/types/Tenant'

export const USER_SETTING_TAB_GENERAL = 0
export const USER_SETTING_TAB_VALUES = 1
export const USER_SETTING_TAB_INDICATEDS = 2
export const USER_SETTING_TAB_PRODUCTS = 3
export const USER_SETTING_TAB_AFFILIATEDS = 4
export const USER_SETTING_TAB_EXTRACT = 5

export const userSettingsTrueOrFalse: Record<
  string,
  { label: string; backgroundColor: string; color: string }
> = {
  ['true']: {
    label: 'Permite',
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  ['false']: {
    label: 'Bloqueado',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

export const userSettingsAnticipationType: Record<
  TTenantAnticipationType,
  string
> = {
  [TTenantAnticipationType.None]: 'D + 30',
  [TTenantAnticipationType.D30]: 'D + 30',
  [TTenantAnticipationType.D15]: 'D + 15',
  [TTenantAnticipationType.D2]: 'D + 2',
}

export const tenantSettingsDefault: TTenantSettings = {
  id: '',
  tenantId: '',
  immediateWithdrawal: 0.0,
  plataformFee: 0.0,
  enableAnticipationD15: false,
  anticipationD15Fee: 0,
  enableAnticipationD2: false,
  anticipationD2Fee: 0,
}

import { useTheme } from '@mui/material/styles'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'

export const BalanceDarkCard = ({
  balance,
  title = 'Saldo disponível',
}: {
  balance: number
  title?: string | React.ReactNode
}) => {
  const theme = useTheme()

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return (
    <Box
      display="flex"
      width={{ xs: '100%', sm: '50%' }}
      justifyContent="center"
      alignItems="center"
      sx={{ p: 2 }}
    >
      <List sx={{ py: 0 }}>
        <ListItem disableGutters sx={{ py: 0 }}>
          <ListItemText
            sx={{
              py: 0,
              mt: 0.45,
              mb: 0.45,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
            primary={
              <Typography
                variant="subtitle2"
                sx={{
                  size: '12px',
                  lineHeight: '16px',
                  alignContent: 'center',
                  color: theme.palette.grey[900],
                  mt: 0.25,
                }}
              >
                {title}
              </Typography>
            }
            secondary={
              <Typography variant="h5" sx={{ color: theme.palette.grey[900] }}>
                {formatter.format(balance)}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  )
}

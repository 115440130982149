import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { RecipientStatus } from '../../../../settings/TenantBalanceWithdraw/RecipientStatus'
import SwitchRoundSmall from '../../../../../components/Switch/SwitchRoundSmall'
import { TTenantSettings } from '../../../../../core/types/Tenant'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  IconCopy,
  IconWhatsApp,
} from '../../../../../core/constants/IconConsts'
import { maskPhoneNumber } from '../../../../../masks/masks'
import { useAdminUserContext } from '../../../../../core/hooks/useAdminUserContext'

export type IUserSettingGeneralChangeEvent = (
  e: React.ChangeEvent<HTMLInputElement>
) => Promise<void>

export interface IUserSettingGeneralProps {
  loadingSettings: boolean
  settings: TTenantSettings | null
  handleChangeCopyRecipientId: () => void
  handleOpenRecipient: () => void
  handleChangeSalesEnabled: IUserSettingGeneralChangeEvent
  handleChangeWithdrawlsEnabled: IUserSettingGeneralChangeEvent
  handleChangeEnableRegisterProducts: IUserSettingGeneralChangeEvent
}

const UserSettingGeneral = ({
  loadingSettings,
  settings,
  handleChangeCopyRecipientId,
  handleOpenRecipient,
  handleChangeSalesEnabled,
  handleChangeWithdrawlsEnabled,
  handleChangeEnableRegisterProducts,
}: IUserSettingGeneralProps) => {
  const { tenantSettings } = useAdminUserContext()

  function handleChangeOpenWhatsApp(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    const phoneNumber = tenantSettings?.tenantPhone
    if (phoneNumber) {
      const formattedPhoneNumber = phoneNumber.replace(/\D/g, '') // Remove non-numeric characters
      const whatsappUrl = `https://wa.me/${formattedPhoneNumber}`
      window.open(whatsappUrl, '_blank')
    }
  }

  return (
    <Stack direction="column" spacing={0} sx={{ py: 1 }}>
      <ListItemDefault
        title="Código do Recebedor"
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Box>
            {(tenantSettings?.recipientId ?? '') !== '' && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <ListItemButton onClick={handleChangeCopyRecipientId}>
                  <Typography noWrap variant="subtitle2">
                    {tenantSettings?.recipientId}
                  </Typography>
                </ListItemButton>
                <HtmlTooltip title="Copiar código do recebedor">
                  <IconButton
                    size="small"
                    onClick={handleChangeCopyRecipientId}
                  >
                    <img src={IconCopy} alt="copy" />
                  </IconButton>
                </HtmlTooltip>
                <HtmlTooltip title="Abrir recebedor na Pagar.Me">
                  <IconButton size="small" onClick={handleOpenRecipient}>
                    <OpenInNewIcon color="primary" />
                  </IconButton>
                </HtmlTooltip>
              </Stack>
            )}
          </Box>
        }
      />
      {(tenantSettings?.recipientId ?? '') !== '' && (
        <ListItemDefault
          title="Status do Recebedor"
          widthTitlePercentage={40}
          variant="compact"
          value={
            !loadingSettings ? (
              <RecipientStatus status={settings?.recipientStatus ?? ''} />
            ) : (
              <CircularProgress size={27} />
            )
          }
        />
      )}
      <ListItemDefault
        title="Telefone de contato"
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Box>
            {(tenantSettings?.recipientId ?? '') !== '' && (
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography noWrap variant="subtitle2">
                  {maskPhoneNumber(tenantSettings?.tenantPhone)}
                </Typography>
                <HtmlTooltip title="Abrir conversa no WhatsApp">
                  <IconButton size="small" onClick={handleChangeOpenWhatsApp}>
                    <img src={IconWhatsApp} alt="whatsapp" />
                  </IconButton>
                </HtmlTooltip>
              </Stack>
            )}
          </Box>
        }
      />
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings?.salesEnabled ?? false}
                onChange={handleChangeSalesEnabled}
              />
            }
            label={<Typography variant="subtitle2">Permite vender</Typography>}
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={50}
        variant="compact"
        value={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings?.withdrawlsEnabled ?? false}
                onChange={handleChangeWithdrawlsEnabled}
              />
            }
            label={
              <Typography variant="subtitle2">
                Permite realizar saques
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
      />
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings?.enableRegisterProducts ?? false}
                onChange={handleChangeEnableRegisterProducts}
              />
            }
            label={
              <Typography variant="subtitle2">
                Permite cadastrar produtos
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={100}
        variant="compact"
        value=""
      />
    </Stack>
  )
}

export default UserSettingGeneral

import { Link } from '@mui/material'

const LinkCelestusTerm = () => {
  return (
    <Link
      component={Link}
      href="https://celetus.com"
      target="_blank"
      fontWeight={500}
      sx={{
        textDecoration: 'none',
        color: '#1C52BD',
        fontWeight: 'bold',
      }}
    >
      https://celetus.com/
    </Link>
  )
}

export default LinkCelestusTerm

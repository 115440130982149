import { Box, Grid, Typography } from '@mui/material'
import { TAffiliatedStatus } from '../../../../../../core/types/Affiliated'
import { IMyAffiliationsLinksHeaderProps } from '../../MyAffiliationsDetailsTypes'

const MyAffiliatesLinksHeader = ({
  title,
  affiliated,
}: IMyAffiliationsLinksHeaderProps) => {
  return (
    <>
      <Grid item xs={18}>
        <Box height="8px"></Box>
      </Grid>
      <Grid
        item
        xs={13}
        sx={{
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          backgroundColor: 'divider',
        }}
      >
        {affiliated?.status === TAffiliatedStatus.Accepted && (
          <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
            {title}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={5}
        sx={{
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          backgroundColor: 'divider',
        }}
      >
        <Typography variant="subtitle2" textAlign="end" sx={{ px: 2, py: 1 }}>
          Preço
        </Typography>
      </Grid>
    </>
  )
}

export default MyAffiliatesLinksHeader

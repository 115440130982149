import * as React from 'react'
import {
  Box,
  Typography,
  InputAdornment,
  Button,
  Stack,
  AlertTitle,
  Avatar,
  TextField,
  Paper,
  Tabs,
  Tab,
  Select,
  MenuItem,
  useMediaQuery,
  Chip,
} from '@mui/material'
import {
  addDays,
  dateDescription,
  diffDays,
} from '../../../core/functions/dateTime'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import {
  ISubscriptionsFilter,
  TSubscription,
} from '../../../core/types/CheckoutOrder'
import { ExportToFile } from '../ExportToFile'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../core/hooks/useDownloadFile'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import { AuthContext } from '../../../core/context/AuthContext'
import { useCurrentTenantBank } from '../../../core/hooks/useCurrentTenantBank'
import { useProductsSimple } from '../../../core/hooks/useProductsSimple'
import { useAffiliatedsSimple } from '../../../core/hooks/useAffiliatedsSimple'
import SimpleCard from '../../../components/Cards/SimpleCard'
import TitlePageTypography from '../../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../../components/Typographys/SubtitlePageTypography'
import DataTable from '../../../components/Tables/DataTable'
import {
  SubscriptionDateFilterType,
  SubscriptionStatusEnum,
} from '../../../core/types/Order'
import { subscriptionsColumns } from './SubscriptionConsts'
import { SubscriptionAdvancedFilters } from './SubscriptionAdvancedFilters'
import { useSubscriptions } from '../../../core/hooks/useSubscriptions'
import SubscriptionMobileItem from './SubscriptionMobileItem'
import SubscriptionDetails from './SubscriptionDetails'
import { a11yProps } from '../../../components/Tabs'
import AlertDefault from '../../../components/Alerts'

export default function CheckoutSubscriptionPage() {
  const { user } = React.useContext(AuthContext)

  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ])

  const [tabIndex, setTabIndex] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [copied, setCopied] = React.useState(false)
  const [type, setType] = React.useState<SubscriptionStatusEnum>(
    SubscriptionStatusEnum.Active
  )
  const [current, setCurrent] = React.useState<TSubscription | undefined>(
    undefined
  )
  const [open, setOpen] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')

  const [openFilter, setOpenFilter] = React.useState(false)

  const [filters, setFilters] = React.useState<ISubscriptionsFilter>({
    dateType: SubscriptionDateFilterType.None,
    startDate: addDays(-29),
    endDate: new Date(),
    sellerType: 0,
    affiliateds: [],
    payments: [],
    products: [],
    status: [],
  })

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const { data: tenantBank } = useCurrentTenantBank({
    tenantId: user?.TenantId ?? '',
  })
  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })
  const { data: affiliateds, loading: loadingAffiliateds } =
    useAffiliatedsSimple({ initialExecute: true })

  const { loading, data, error, execute, clear } = useSubscriptions({
    page,
    rows: rowsPerPage,
    search,
    type,
    filters,
  })

  const columns = subscriptionsColumns

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage && data.rowsPerPage > 0) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.innerWidth, window.innerHeight])
    })
  }, [])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (page > 0) {
      setPage(0)
    }

    switch (newValue) {
      case 0:
        setType(SubscriptionStatusEnum.Active)
        break
      case 1:
        setType(SubscriptionStatusEnum.Canceled)
        break
      case 2:
        setType(SubscriptionStatusEnum.Delayed)
        break
      case 3:
        setType(SubscriptionStatusEnum.Blocked)
        break
    }

    setTabIndex(newValue)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeItem = (subscription: TSubscription) => {
    setCurrent(subscription)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.getSubscriptions({
      ...filters,
      fileType: typeFile,
      search,
      type,
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.CSV)
  }

  const isValidDownloadReport = (): boolean => {
    if (filters.startDate === null || filters.startDate === undefined) {
      setErrorSending('Necessário informar o período inicial.')
      return false
    }

    if (filters.endDate === null || filters.endDate === undefined) {
      setErrorSending('Necessário informar um período final.')
      return false
    }

    if (diffDays(filters.endDate, filters.startDate) > 30) {
      setErrorSending('Não informar um período maior que 30 dias')
      return false
    }

    return true
  }

  const isTenantBank = () => {
    return (
      tenantBank &&
      tenantBank !== null &&
      tenantBank.accountNumber &&
      tenantBank.accountNumber !== null &&
      tenantBank.bankCode &&
      tenantBank.bankCode !== null
    )
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleCloseFilter = () => setOpenFilter(false)

  return (
    <Box>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        alignContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
        width="100%"
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 1 }}>
            <TitlePageTypography>Assinaturas</TitlePageTypography>
            <Chip label="Beta" size="small" color="primary" />
          </Stack>
          <SubtitlePageTypography>
            {filters.dateType === SubscriptionDateFilterType.None
              ? 'Todo o período'
              : `Filtro do período de ${dateDescription(filters.startDate)} à ${dateDescription(filters.endDate)}`}
          </SubtitlePageTypography>
        </Box>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Typography
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight={500}
            lineHeight="18px"
            color="rgba(152, 156, 155, 1)"
          >
            Registros por página
          </Typography>
          <Select
            variant="outlined"
            value={rowsPerPage}
            onChange={(event) =>
              handleChangeRowsPerPage(
                NumberFunctions.toNumber(event.target.value)
              )
            }
            sx={{ height: 40, width: 85 }}
          >
            {[10, 20, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                execute()
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="Pesquisar" src="/assets/icons/search/search.svg" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                ml: 4.5,
                color: 'black',
              },
              shrink: search !== '',
              required: false,
            }}
            sx={(theme) => ({
              backgroundColor: 'white',
              '& .Mui-focused .MuiInputAdornment-root': {
                color: theme.palette.primary.main,
              },
              '& .Mui-error .MuiInputAdornment-root': {
                color: theme.palette.error.main,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                px: 5.5,
              },
            })}
            label="Pesquisar"
          />
          <Button
            sx={{
              p: 0,
              m: 0,
              minWidth: 0,
              borderRadius: '8px',
            }}
            onClick={handleOpenFilter}
          >
            <Paper
              elevation={0}
              sx={{
                border: '1px solid #C5C6C9',
                borderRadius: '8px',
                p: '8px',
                fontSize: '0rem',
              }}
            >
              <img src="/assets/icons/menu/funnel/funnel.svg" alt="Filtro" />
            </Paper>
          </Button>
        </Stack>
      </Stack>

      {isTenantBank() && error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      {!isTenantBank() && error !== '' && (
        <AlertDefault severity="warning" variant="standard" sx={{ mb: 2 }}>
          <AlertTitle>Dados bancário não fornecido</AlertTitle>
          Para vender é necessário informar uma conta bancária em{' '}
          <i>Menu - Configurações - Dados Bancário</i>
        </AlertDefault>
      )}

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ mb: 2 }}>
        <SimpleCard sx={{ px: 4 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ width: 40, height: 40, bgcolor: '#F2F2FF' }}>
              <img alt="Vendas" src="/assets/icons/payments/pay.svg" />
            </Avatar>
            <Stack direction="column">
              <Typography
                variant="body2"
                color="#707275"
                fontWeight={500}
                fontSize="14px"
                lineHeight="14px"
              >
                Assinaturas encontradas
              </Typography>
              <Typography
                variant="h4"
                color="#38393B"
                fontWeight={600}
                fontSize="24px"
                lineHeight="32px"
              >
                {data.total}
              </Typography>
            </Stack>
          </Stack>
        </SimpleCard>
      </Stack>

      <Stack
        direction="row"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        alignItems="center"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            flexGrow: isSmallScreen ? 0 : 1,
            width: isSmallScreen ? windowSize[0] : '100%',
          }}
        >
          <Tab label="Ativas" {...a11yProps(0)} />
          <Tab label="Canceladas" {...a11yProps(1)} />
          <Tab label="Atrasadas" {...a11yProps(2)} />
          <Tab label="Bloqueadas" {...a11yProps(3)} />
          {isSmallScreen && (
            <Stack height="46px" alignItems="center" justifyContent="center">
              <ExportToFile
                convertToCSV={handleConvertToCSV}
                convertToXLS={handleConvertToXLS}
              />
            </Stack>
          )}
        </Tabs>
        {!isSmallScreen && (
          <ExportToFile
            convertToCSV={handleConvertToCSV}
            convertToXLS={handleConvertToXLS}
          />
        )}
      </Stack>

      <DataTable
        hasNewCustomHeader
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onItemClick={handleChangeItem}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        renderItemMobile={(value) => (
          <SubscriptionMobileItem
            orders={value}
            handleChangeItem={handleChangeItem}
          />
        )}
      />

      <Box sx={{ height: 80 }} />

      <SubscriptionDetails
        subscription={current}
        open={open}
        loading={sending}
        handleClose={handleClose}
        setOpen={setOpen}
        setSending={setSending}
        setSuccess={setSuccess}
        setError={setErrorSending}
        execute={execute}
        setCopied={setCopied}
      />

      <SubscriptionAdvancedFilters
        open={openFilter}
        setOpen={setOpenFilter}
        affiliateds={affiliateds}
        products={products}
        handleCloseFilter={handleCloseFilter}
        startDateDefault={addDays(-29)}
        endDateDefault={new Date()}
        onlyRangeDate={false}
        filters={filters}
        onChangeFilters={setFilters}
        hasTypeFilter
      />

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}

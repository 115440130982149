import {
  TMemberService,
  TMemberServiceType,
} from '../../core/types/MemberService'

export const cademiDefault: TMemberService = {
  id: '',
  identifier: '',
  domain: '',
  apiKey: '',
  token: '',
  webhookCode: '',
  type: TMemberServiceType.Cademi,
}

export const memberKitDefault: TMemberService = {
  id: '',
  identifier: '',
  domain: '',
  apiKey: '',
  token: '',
  webhookCode: '',
  type: TMemberServiceType.MemberKit,
}

export const astronMembersDefault: TMemberService = {
  id: '',
  identifier: '',
  domain: '',
  apiKey: '',
  token: '',
  webhookCode: '',
  type: TMemberServiceType.AstronMembers,
}

export const theMembersDefault: TMemberService = {
  id: '',
  identifier: '',
  domain: '',
  apiKey: '',
  token: '',
  webhookCode: '',
  type: TMemberServiceType.TheMembers,
}

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Convertions from '../../../../../core/functions/convertions'
import { TProductAssurance } from '../../../../../core/types/Product'
import { ProductGeneralAssuranceProps } from '../../../ProductTypes'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'

const ProductGeneralAssurance = ({
  control,
  errors,
  watch,
}: ProductGeneralAssuranceProps) => {
  const { isUserSysAdmin } = useContext(AuthContext)
  const { register } = control

  const assuranceValue = watch('assurance')
  const assuranceNumber = assuranceValue
    ? Number(assuranceValue)
    : Number(TProductAssurance.Seven)

  const isAdmin = isUserSysAdmin()

  return (
    <FormControl
      fullWidth
      size="small"
      error={errors.assurance?.type === 'required'}
    >
      <InputLabel id="assurance-select-label">Garantia</InputLabel>
      <Select
        variant="outlined"
        disabled={isAdmin}
        labelId="assurance-select-label"
        label="Garantia"
        value={assuranceNumber}
        {...register('assurance')}
        renderValue={(value?: number) =>
          Convertions.productAssuranceToString(value)
        }
      >
        <MenuItem value={Number(TProductAssurance.Seven)}>
          {Convertions.productAssuranceToString(TProductAssurance.Seven)}
        </MenuItem>
        <MenuItem value={Number(TProductAssurance.Fifteen)}>
          {Convertions.productAssuranceToString(TProductAssurance.Fifteen)}
        </MenuItem>
        <MenuItem value={Number(TProductAssurance.TwentyOne)}>
          {Convertions.productAssuranceToString(TProductAssurance.TwentyOne)}
        </MenuItem>
        <MenuItem value={Number(TProductAssurance.Thirty)}>
          {Convertions.productAssuranceToString(TProductAssurance.Thirty)}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default ProductGeneralAssurance

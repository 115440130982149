import { useState } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<Blob | IErrorResponse>
  readonly onError: (e: string) => void
  readonly getFileName: () => string
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>
  readonly name: string | undefined
  readonly url: string | undefined
  readonly downloading: boolean
}

export const useDownloadPDF = ({
  apiDefinition,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const [url, setFileUrl] = useState<string>()
  const [name, setFileName] = useState<string>()
  const [downloading, setDownloading] = useState(false)

  const download = async () => {
    try {
      setDownloading(true)
      const response = await apiDefinition()
      const responseError = response as IErrorResponse
      const responseData = response as any

      if (responseError.code) {
        onError(responseError.error)
      } else {
        const data = new Blob([responseData], {
          type: 'application/pdf',
        })
        const url = window.URL.createObjectURL(data)

        setFileUrl(url)

        const fileName = getFileName()
        setFileName(fileName)

        const tempLink = document.createElement('a')
        tempLink.href = url
        tempLink.setAttribute('download', fileName)
        tempLink.click()

        URL.revokeObjectURL(url)
      }
    } catch (e) {
      var message = 'Erro não identificado'
      if (typeof e === 'string') {
        message = e.toUpperCase()
      } else if (e instanceof Error) {
        const err = e as Error
        message = err.message
      }
      onError(message)
    } finally {
      setDownloading(false)
    }
  }

  return { download, url, name, downloading }
}

import { Chip } from '@mui/material'
import { IDataTableColumn } from '../../../components/Tables/DataTable'
import { TTenantSettings } from '../../../core/types/Tenant'
import { userSettingsTrueOrFalse } from './UserSetting/UserSettingConsts'

export const userSettingsColumns: readonly IDataTableColumn<TTenantSettings>[] =
  [
    { id: 'tenantCode', label: 'Código', minWidth: 100 },
    { id: 'recipientId', label: 'Código do Recebedor', minWidth: 100 },
    { id: 'tenantName', label: 'Nome', minWidth: 120 },
    { id: 'tenantEmail', label: 'E-mail', minWidth: 120 },
    {
      id: 'immediateWithdrawal',
      label: 'Saque imediato',
      minWidth: 80,
      align: 'right',
      format: (value) => `${value}%`,
    },
    {
      id: 'plataformFee',
      label: 'Taxa específica',
      minWidth: 80,
      align: 'right',
      format: (value) => `${value}%`,
    },
    {
      id: 'salesEnabled',
      label: 'Vendas',
      minWidth: 80,
      align: 'center',
      render(id, value, row) {
        const { label, ...sx } = userSettingsTrueOrFalse[value.toString()]
        return <Chip label={label} size="small" sx={sx} />
      },
    },
    {
      id: 'withdrawlsEnabled',
      label: 'Saques',
      minWidth: 80,
      align: 'center',
      render(id, value, row) {
        const { label, ...sx } = userSettingsTrueOrFalse[value.toString()]
        return <Chip label={label} size="small" sx={sx} />
      },
    },
  ]
